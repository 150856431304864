import React from 'react'
interface HideOnTabletProps {
  children: React.ReactNode
}

const WIDTHS = {
  landscapeIpadAir: 1200,
}

export default function HideOnTablet({ children }: HideOnTabletProps) {
  if (window.innerWidth < WIDTHS.landscapeIpadAir) {
    return null
  }

  return <>{children}</>
}
