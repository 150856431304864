import React from 'react'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { GeneratedForms } from '../../../types/Templates'
import firebase from 'firebase'
import DateTimeView from '../../atoms/DateTimeView'

type CompositeFinalRatingItemProps = {
  form: GeneratedForms
  ratingDate?: string
}

export const downloadFolder = (ref: string) => {
  const doc = firebase.storage().ref(ref)
  doc.getDownloadURL().then((url) => {
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = document.title || ''
    anchor.click()
  })
}

const CompositeFinalRatingItem: React.FC<CompositeFinalRatingItemProps> = ({
  form,
  ratingDate,
}) => {
  return (
    <tr>
      <td colSpan={6}>
        <div>
          <h4 style={{ marginBlockEnd: '0.5em' }}>Composite Forms Details</h4>
          <p>Name: {form.name}</p>
          <p style={{ marginBlock: '0.5em' }}>
            Forms Generated:{' '}
            <DateTimeView
              date={form.createdAt ? new Date(form.createdAt) : new Date()}
            />
          </p>
          <div style={{ width: 300, marginBlockEnd: '2em' }}>
            <RoundedButton
              title="Download Forms"
              onPress={() => downloadFolder(form.zipPath)}
            />
          </div>
        </div>
      </td>
    </tr>
  )
}
export default CompositeFinalRatingItem
