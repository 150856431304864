import images from '@app/images'
import theme from '@app/ui/theme'
import React from 'react'

import * as S from './styled'
import { ViewStyle } from 'react-native'

const GoBackButton: React.FC<{
  onPress: () => void
  text: string
  portraitMode: boolean
  style?: React.CSSProperties
}> = ({ onPress, text, portraitMode, style }) => {
  return (
    <S.GoBackContainer
      style={style}
      onClick={onPress}
      portraitMode={portraitMode}
    >
      <S.BackArrowContainer>
        <images.svg.BackArrow fillColor={theme.colors.black} height="16" />
      </S.BackArrowContainer>
      <S.BackText>{text}</S.BackText>
    </S.GoBackContainer>
  )
}

export default GoBackButton
