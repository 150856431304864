import styled from 'styled-components'
import { media } from '@app/ui'
import theme from '@app/ui/theme'

export const SlopeWrapper = styled.div`
  display: flex;
  flex: 1;
  font-family: 'Roboto', sans-serif;
`
export const ContentMain = styled.div`
  width: 100%;
  ${media.desktop} {
    width: 100%;
  }
`
export const ContentCard = styled.div`
  display: flex;
  flex: 1;
  margin: 20px;

  box-shadow: 0 0px 4px 0 ${theme.colors.dark1};
  background-color: ${theme.colors.white};
  padding: 30px;
  border-radius: 6px;
  overflow: hidden;
`
export const ContentColorCard = styled.div`
  display: flex;
  width: 224px;
  height: 147px;
  box-shadow: 0 0px 4px 0 ${theme.colors.dark1};
  background-color: ${theme.colors.white};
  border-radius: 6px;
  overflow: hidden;
  flex-direction: column;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  &:hover {
    cursor: pointer;
  }
`
export const Container = styled.div`
  width: 100%;
  margin-top: 5px;
`
export const Head = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
`
export const SlopeTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 460;
  letter-spacing: 0.4px;
  color: ${theme.colors.black};
  display: block;
  line-height: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
`
export const TeeContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 50px;
`

export const SlopeButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`
export const TopContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row;

  @media (orientation: portrait) {
    & > * {
      flex: 60%;
    }
  }
`
export const GenderContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const Label = styled.span`
  font-size: 16px;
  font-weight: 400;
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const CourseContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const Input = styled.input`
  padding: 0;
  border: 1px solid ${theme.colors.color13};
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  width: 300px;
  height: 40px;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  align-self: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  text-indent: 16px;
  justify-content: space-around;

  & ::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #a5a5a5;
  }
`

export const TeeTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #282828;
`
export const Line = styled.div`
  border-bottom: 2px solid rgba(196, 196, 196, 0.21);
  width: 100%;
`

export const TeeRating = styled.div`
  white-space: break-spaces;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  color: ${theme.colors.black};
  & > span {
    font-weight: 500;
  }
`

export const CourseInfo = styled.div`
  padding: 24px;
  height: 100%;
`

export const TitleContainer = styled.div`
  height: 43px;
  width: 100%;
  padding: 15px 24px 15px 24px;
  box-sizing: border-box;
`
