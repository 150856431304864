import { ALL_FEATURES } from '../config'
import { FeaturesAction, FeaturesState } from '../types'

export const SET_FEATURES = 'SET_FEATURES'
export const SET_FEATURE = 'SET_FEATURE'

export const FEATURES_INITIAL_STATE: FeaturesState = ALL_FEATURES

export default function features(
  state: FeaturesState = FEATURES_INITIAL_STATE,
  action: FeaturesAction
) {
  switch (action.type) {
    case SET_FEATURES:
      return action.value
    case SET_FEATURE:
      return {
        ...state,
        features: {
          ...state.features,
          [action.featureKey as string]: { enabled: action.value },
        },
      }
      return action.value
    default:
      return state
  }
}
