import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import { GolferShortName } from '@app/evaluation-core/types'
import React from 'react'
import { getAdjustmentValue } from '@app/evaluation-core'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

export enum Contour {
  RELATIVELY_FLAT_OR_GENTLY_SLOPED = 1,
  MODERATELY_CONTOURED_OR_MODERATELY_SLOPED = 2,
  HIGHLY_CONTOURED_OR_STEEPLY_SLOPED = 3,
}

export const GREEN_SURFACE_FACTORS: AdmFactorGroup[] = [
  {
    factors: [
      {
        label: 'U',
        itemKey: 'surf_unpleasant',
        format: 'hole',

        items: [
          {
            value: 1,
            text: `If UNPLEASANT turf conditions on the putting green cause well struck putts to routinely miss the hole (such conditions must prevail throughout the midseason) \n\n ----- or ----- \n\n If the circle concept has been applied to determine the effective green diameter because a player cannot putt from one part of the green to another.`,
          },
        ],
      },
      {
        label: 'T',
        itemKey: 'tiered',
        format: 'hole',
        golfers: [GolferShortName.BogeyMen, GolferShortName.BogeyWomen], // bogey
        items: [
          {
            value: 1,
            text: `If the green is tiered (see definition on page 8).`,
          },
        ],
      },
    ],
  },
]

export const GreenSurface = () => {
  const adjustments = useAdjustment()
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  const isTiered = getAdjustmentValue('hole', view, 'tiered', adjustments)

  const contourValues = isTiered
    ? [
        Contour.MODERATELY_CONTOURED_OR_MODERATELY_SLOPED,
        Contour.HIGHLY_CONTOURED_OR_STEEPLY_SLOPED,
      ]
    : [
        Contour.RELATIVELY_FLAT_OR_GENTLY_SLOPED,
        Contour.MODERATELY_CONTOURED_OR_MODERATELY_SLOPED,
        Contour.HIGHLY_CONTOURED_OR_STEEPLY_SLOPED,
      ]

  const contourLabels = isTiered
    ? [
        'Moderately Contoured or Moderately Sloped',
        'Highly Contoured or Steeply Sloped',
      ]
    : [
        'Relatively Flat or Gently Sloped',
        'Moderately Contoured or Moderately Sloped',
        'Highly Contoured or Steeply Sloped',
      ]

  if (!evaluation) return null

  return (
    <Adjustment
      useLz={false}
      factors={GREEN_SURFACE_FACTORS}
      parameters={[
        {
          type: 'numeric-input',
          label: 'Green Speed',
          secondaryLabel: 'Feet',
          inputDisabled: true,
          adjustmentKey: 'stimp_feet',
          format: 'course',
          notExpandible: true,
        },
        {
          type: 'numeric-input',
          label: 'Green Speed',
          secondaryLabel: 'Inches',
          adjustmentKey: 'stimp_inches',
          inputDisabled: true,
          format: 'course',
          notExpandible: true,
        },
        {
          type: 'select',
          label: 'Contour of Green Surface',
          inputDisabled: true,
          adjustmentKey: 'surf_contour',
          format: 'hole',
          selectorValues: contourValues,
          selectorLabels: contourLabels,
          notExpandible: true,
        },
        {
          type: 'table-value',
          label: 'Green Surface Rating',
          inputDisabled: true,
          adjustmentKey: 'surf_rating',
          format: 'golfer',
        },
      ]}
    />
  )
}
