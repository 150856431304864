import React from 'react'
import { TouchableOpacity, View } from 'react-native'

import { AdmGroupFactor } from '../types'
import {
  AdmFactorLabel,
  AdmFactorLabelBackground,
  AdmFactorLabelContainer,
  AdmFactorLabelIcon,
  AdmFactorLabelItem,
  AdmFactorLabelItemText,
} from './AdjustmentFactors.styles'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface AdjustmentLabelProps {
  factor: AdmGroupFactor
  openFactors: string[]
  factorIndex: number
  index: number
  setOpenFactors: React.Dispatch<React.SetStateAction<string[]>>
}

const ExpandableAdjustmentLabel: React.FC<AdjustmentLabelProps> = ({
  factor,
  openFactors,
  factorIndex,
  index,
  setOpenFactors,
}: AdjustmentLabelProps) => {
  return (
    <AdmFactorLabel>
      <TouchableOpacity
        onPress={() => {
          if (!factor.expandable) {
            return
          }
          const f = openFactors.indexOf(index + '_' + factorIndex)

          if (f === -1) {
            setOpenFactors(openFactors.concat(index + '_' + factorIndex))
          } else {
            setOpenFactors(
              openFactors.filter((item) => item !== index + '_' + factorIndex)
            )
          }
        }}
      >
        {!factor ? (
          <Skeleton width="80px" height="40px" />
        ) : (
          <AdmFactorLabelContainer>
            {factor.label && (
              <>
                <AdmFactorLabelBackground
                  expandable={factor.expandable}
                  collapsed={
                    openFactors.indexOf(index + '_' + factorIndex) === -1
                  }
                >
                  {factor.expandable && <AdmFactorLabelIcon />}
                </AdmFactorLabelBackground>
                <AdmFactorLabelItem>
                  <AdmFactorLabelItemText expandable={factor.expandable}>
                    {factor.label}
                  </AdmFactorLabelItemText>
                </AdmFactorLabelItem>
              </>
            )}
          </AdmFactorLabelContainer>
        )}
      </TouchableOpacity>
    </AdmFactorLabel>
  )
}

export default ExpandableAdjustmentLabel
