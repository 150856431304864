import React from 'react'

import { Statuses } from '@app/courses/domain/ratingStatus'
import { getClubStatusColor } from '@app/courses/domain/ratingStatus2'
import { shadeColor } from '@app/evaluation-core/evaluation'
import { MiProjectStatus } from '@app/evaluation-core/types'
import * as S from './styled'

const STATUS_MAPPER: { [key in MiProjectStatus]: Statuses } = {
  '0': Statuses.NOT_AVAILABLE,
  '1': Statuses.PLANNED,
  '2': Statuses.MEASURED,
  '3': Statuses.REVIEW_BEFORE_RATING,
  '4': Statuses.READY_FOR_RATING,
  '5': Statuses.IN_PROGRESS,
  '6': Statuses.RATED,
}
const STATUS_TO_TEXT: { [key in MiProjectStatus]: string } = {
  '0': 'Not Set',
  '1': 'Planned',
  '2': 'Measured',
  '3': 'Review before rating',
  '4': 'Ready for rating',
  '5': 'Rating in progress',
  '6': 'Rating completed',
}

const ClubStatus: React.FC<{
  status: MiProjectStatus
  showLabel?: boolean
  size?: number
}> = ({ status, showLabel = true, size = 32 }) => {
  const currentClubStatus = STATUS_MAPPER[status]
  const statusColor = getClubStatusColor(currentClubStatus)
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className="statusColor"
        style={{
          backgroundColor: statusColor,
          border: `1px solid ${shadeColor(statusColor, -24)}`,
          height: size,
          width: size,
          borderRadius: 50,
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {showLabel && (
          <S.EvaluationStatusText>
            {STATUS_TO_TEXT[currentClubStatus]}
          </S.EvaluationStatusText>
        )}
      </div>
    </div>
  )
}

export default ClubStatus
