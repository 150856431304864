import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { MITee } from '@app/evaluation-core/types'
import { useEvaluation } from '@common/hooks'
import fb from '@app/firebase'
import { MIHole } from '@app/evaluation-core/types'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { useDispatch } from 'react-redux'
import { refreshTee } from '@app/evaluation-core'

export type TeeState = {
  [key: string]: number | undefined
}

export type CopiedTeeContextType = [
  TeeState,
  (teeIndex: number) => MITee[],
  (currentTee: number, requestedTee: number | null) => void,
  (currentTee: number) => boolean,
  (teeToRemove: number) => void
]

const useCurrentHole = (): MIHole => {
  const { view } = useEvaluationUser()
  const { evaluation } = useEvaluation()
  const currentHole = evaluation.holes[view?.hole]
  return currentHole
}

const createTeeState = (tees: MITee[]): TeeState => {
  return tees.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.teeIndex.toString()]: curr?.copiedTee ?? undefined,
    }
  }, {})
}

const useCopiedTeeState = (): CopiedTeeContextType => {
  const { currentId, evaluation } = useEvaluation()
  const { view, userId } = useEvaluationUser()
  const currentHole = useCurrentHole()
  const dispatch = useDispatch<any>()

  const addTeeToCopy = async (teeIndex: number, teeToCopy: number) => {
    refreshTee(view?.hole, teeIndex, currentId as string)

    fb.ref(
      `evaluations/${currentId}/holes/${view?.hole}/tees/${teeIndex}/copiedTee/`
    ).set(teeToCopy)
  }

  const removeTeeToCopy = (teeIndex: number) => {
    refreshTee(view?.hole, teeIndex, currentId as string)
    fb.ref(
      `evaluations/${currentId}/holes/${view?.hole}/tees/${teeIndex}/copiedTee/`
    ).remove()
  }

  const getCopyableTees = useCallback(
    (teeIndex: number): MITee[] => {
      const teesCopied = currentHole.tees.map((t) => t?.copiedTee)
      if (teesCopied.includes(teeIndex)) {
        return []
      }
      return currentHole.tees.filter((t) => t?.copiedTee === undefined)
    },
    [currentHole.tees]
  )

  const getIsCopied = useCallback(
    (teeIndex: number) => {
      return currentHole.tees[teeIndex]?.copiedTee !== undefined
    },
    [currentHole.tees]
  )

  const handleSelect = (teeIndex: number, teeToCopy: number | null) => {
    if (teeToCopy) {
      addTeeToCopy(teeIndex, teeToCopy)
    }
  }

  return [
    createTeeState(currentHole.tees),
    getCopyableTees,
    handleSelect,
    getIsCopied,
    removeTeeToCopy,
  ]
}

const CopiedTeeContext = createContext<CopiedTeeContextType>([
  {},
  () => [],
  () => null,
  () => false,
  () => null,
])

interface Props {
  children: React.ReactNode
}

const CopiedTeeProvider: React.FC<Props> = ({ children }: Props) => {
  const copiedTeeState = useCopiedTeeState()
  return (
    <CopiedTeeContext.Provider value={copiedTeeState}>
      {children}
    </CopiedTeeContext.Provider>
  )
}

const useCopiedTee = (): CopiedTeeContextType => {
  const context = useContext(CopiedTeeContext)
  if (!context) {
    throw new Error('useCopiedTee must be used within a CopiedTeeProvider')
  }
  return context
}

export { CopiedTeeProvider, useCopiedTee }
