import React, { FunctionComponent } from 'react'
import * as S from './styled'

interface Props {
  genderKey: string
  style?: React.CSSProperties
}

const GenderIcon: FunctionComponent<Props> = ({ genderKey, style }) => {
  return (
    <>
      {genderKey === '2' && <S.MaleLetter style={style}>M</S.MaleLetter>}
      {genderKey === '3' && <S.FemaleLetter style={style}>W</S.FemaleLetter>}
      {genderKey === '1' && (
        <>
          <S.MaleAndFemaleLetter style={style}>M/W</S.MaleAndFemaleLetter>
        </>
      )}
    </>
  )
}

export default GenderIcon
