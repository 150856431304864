import { useState, useEffect } from 'react'

const usePortraitMode = () => {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia('(orientation: portrait)').matches
  )

  useEffect(() => {
    const handleOrientationChange = (event) => {
      setIsPortrait(event.matches)
    }

    const mediaQueryList = window.matchMedia('(orientation: portrait)')
    mediaQueryList.addEventListener('change', handleOrientationChange)

    return () => {
      mediaQueryList.removeEventListener('change', handleOrientationChange)
    }
  }, [])
  return isPortrait
}
export default usePortraitMode
