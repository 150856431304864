import { UserState } from '@app/auth/types'
import Spacer from '@app/components/atoms/Spacer'
import useUser from '@common/hooks/useUser'
import firebase from 'firebase'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import * as S from './styled'
type OwnProps = {}

type Props = OwnProps

type Message = {
  sender: string
  senderImage: string
  message: string
  timestamp: number
  userId: string
  clubname: string
}

const Support: FunctionComponent<Props> = () => {
  const [messages, setMessages] = useState<Message[]>([])
  const [message, setMessage] = useState('')
  const user = useUser() as UserState
  const scrollRef = useRef<HTMLDivElement>(null)

  const clearMessages = () => {
    firebase.database().ref('messages/').remove()
  }

  const sendMessage = () => {
    const timestamp = Date.now()
    firebase
      .database()
      .ref('messages/')
      .push({
        message: message,
        timestamp: timestamp,
        sender: `${user.forename} ${user.lastname}`,
        senderImage:
          +user?.profilePictureFileID !== 0
            ? `https://www.livegis.com/getfile.php?file=${user?.profilePictureFileID}`
            : '',
        userId: user.id.toString(),
        clubname: user?.clubname || '',
      })
    setMessage('')
  }

  const retrieveMessages = () => {
    firebase
      .database()
      .ref('messages')
      .on('value', (snapshot) => {
        const messages: Message[] = []
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val()
          messages.push({
            message: childData.message,
            timestamp: childData.timestamp,
            sender: childData.sender,
            senderImage: childData.senderImage,
            userId: childData.userId,
            clubname: childData.clubname,
          })
        })
        setMessages(messages)
      })
  }

  useEffect(() => {
    retrieveMessages()
  }, [])

  useEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const sortedMessages = messages.sort((a, b) => a.timestamp - b.timestamp)

  return (
    <S.Wrapper>
      <S.ContentCard></S.ContentCard>
    </S.Wrapper>
  )
  /*  return (
    <S.Wrapper>
      <S.ContentCard>
        <S.ChatWindow>
          {sortedMessages.map((msg, index) => (
            <S.Message
              key={index + msg.message}
              isUser={user.id === +msg.userId}
              ref={scrollRef}
            >
              <S.MessageContainer isUser={user.id === +msg.userId}>
                {msg.senderImage ? (
                  <S.UserImage src={msg.senderImage} />
                ) : (
                  <S.UserImageBackupContainer>
                    <S.Initials>
                      {msg.sender.split(' ')[0].substring(0, 1)}
                      {msg.sender.split(' ')[1].substring(0, 1)}
                    </S.Initials>
                  </S.UserImageBackupContainer>
                )}
                <div style={{ height: 4, width: 4, flexBasis: 4 }}></div>
                <S.ChatText>{msg.message}</S.ChatText>
                <S.NameText>
                  {msg.sender} - {msg.clubname}
                </S.NameText>
              </S.MessageContainer>
            </S.Message>
          ))}
          <S.InputsContainer>
            <S.InputField
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <S.SendButton onClick={sendMessage}>Send</S.SendButton>
            <Spacer height={16} />
            {user.isAdmin && (
              <S.ClearButton onClick={clearMessages}>Clear</S.ClearButton>
            )}
          </S.InputsContainer>
        </S.ChatWindow>
      </S.ContentCard>
    </S.Wrapper>
  )*/
}

export default Support
