export const FLAGS_BASE_URL = 'https://flagicons.lipis.dev/flags'

const COUNTRY_FLAGS = {
  Sweden: 'se',
}

export function getCountryFlagCode(country: string) {
  return COUNTRY_FLAGS[country] ?? 'us'
}

export function getCountryFlagUrl(countryCode: string) {
  return `${FLAGS_BASE_URL}/4x3/${countryCode}.svg`
}
