import type * as Axios from 'axios'

import { useDispatch, useSelector } from 'react-redux'

import firebase from 'firebase'
import { RESET } from '@app/evaluation-core/actions/actionTypes'
import { State } from '@app/state/types'
import fb from '@app/firebase'
import { useAxios } from '@common/hooks/useAxios'
import { useState } from 'react'

export const handleGolfID = (string: string) => {
  const isGolfID = /^(\d|\.|-)+$/.test(string)
  if (isGolfID) {
    if (string.length === 6) {
      return string + '-'
    }
    if (string.length >= 10) {
      return string.slice(0, 10)
    }
    return string
  }
  return string
}

export const useLogin = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>()
  const { login } = useSelector((state: State) => ({
    login: state.login,
  }))

  const axios = useAxios('/authentication')
  const dispatch = useDispatch<any>()

  const signIn = async () => {
    setError(null)
    try {
      dispatch({ type: RESET })
      setLoading(true)
      const { data } = await axios.post('/', { username: email, password })
      const { miUser, refreshToken, token } = data

      //TODO: This is just a temporary solution to check if the user is logged in on another device. This should be done in a more secure way. /VS
      await firebase
        .database()
        .ref(`sessions/${miUser.id}`)
        .set(miUser.sessionID)
      dispatch({ type: 'SET_USER', value: { ...miUser, refreshToken, token } })
      setLoading(false)
    } catch (err: any) {
      const axiosError: Axios.AxiosError = err

      if (axiosError.response) {
        setError(axiosError.response.data.error || axiosError.response.data)
      } else {
        setError('An error occurred.')
      }
      throw err
    } finally {
      setLoading(false)
    }
  }

  const signUp = async ({ email, password, userData }) => {
    setError(null)
    if (password !== repeatPassword) {
      return setError('Passwords do not match.')
    }

    if (!userData.forename || !userData.lastname) {
      return setError('Both a first name and last name are required.')
    }
    try {
      dispatch({ type: RESET })
      const { data } = await axios.post('/register', {
        email,
        password,
        userData,
      })
      const { miUser, refreshToken, token } = data

      dispatch({ type: 'SET_USER', value: { ...miUser, refreshToken, token } })
    } catch (err: any) {
      const axiosError: Axios.AxiosError = err
      setLoading(false)
      if (axiosError.response) {
        return setError(
          axiosError.response.data.error || axiosError.response.data
        )
      }
      setError('An error occurred.')
    }
  }

  const handleEmail = (string: string) => {
    const golfId = handleGolfID(string)
    return setEmail(golfId)
  }

  const inputs = {
    email,
    password,
    repeatPassword,
    setEmail: handleEmail,
    setPassword,
    setRepeatPassword,
  }

  return { signIn, inputs, signUp, loading, setLoading, login, error }
}
