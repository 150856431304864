import { MICourse } from '../../types/Clubs'
import { CourseSettingsCamelCase, RatingRevision } from '../../types/Templates'

export type EvaluationAction = {
  type: string
  value?: EvaluationState
  hole?: number
  tee?: number
  adjustment?: number
  shot?: number
  group?: number
  golfer?: GolferShortName
  userId?: string
  color?: string
  view?: EvaluationUserView
  tees?: number[]
}

export type AdjustmentsAction = {
  type: string
  value: AdjustmentsState
}

export interface MIShot {
  shotIndex: number
  name: string
}

export type MiShotObj = { [key in GolferShortName]?: MIShot[] }

export interface MITee {
  teeIndex: number
  teeNumber: number
  name: string
  shots: MiShotObj
  golfers: GolferShortName[]
  courseId: string
  courseName: string
  id: string
  copiedTee?: number
  color?: string
  includedInSlopeTable?: {
    men: boolean
    women: boolean
  }
}

export type WomenMenStatusOptions = 'all' | 'men' | 'women'

export enum LockStatus {
  UNLOCKED = 0,
  LOCKED = 1,
  READ_ONLY = 2,
}

export interface MIHole {
  holeIndex: number
  name: string
  locked: LockStatus
  tees: MITee[]
  courseId: string
  courseName: string
  globalHoleNumber: string
  courseHoleNumber: string
  holeId: string
  par: string
}

export interface CourseSettings {
  bunk_depth_default: number
  grass_type: GrassType
  intermediate_cut: number
  measurement: Measurement
  rough_height: number
  stimp_feet: number
  stimp_inches: number
  'wind_1-9': number
  'wind_10-18': number
}

export interface InitialEvaluation {
  id: string | null
  userId: string
  loading: {
    loading: number
    message: string
    progress?: number
  }
  finalizingStatus?: {
    error: boolean
    inProgress: boolean
    text: string
  }
  clubId: number
  status: number
  isMixed: boolean
  holes: MIHole[]
  users: EvaluationUsers
  adjustments: AdjustmentItem[]
  version?: string
  comment?: string
  revision?: string
  description?: string
  branchId?: string
  holeIds?: string[]
  settingsChecked: boolean
  defaultCourseSettings?: CourseSettingsCamelCase | CourseSettings
  ratingRevision: RatingRevision | null
  manualVersion?: '2020' | '2024'
  savedAt?: string
}

export interface Eval extends InitialEvaluation {
  courseName: string | null
  courseId: string | null
  clubName?: string | null
  evaluationDate?: string
  saveName?: string | null
}
export interface EvaluationState {
  evaluation: Eval
  courseId?: number
  CID?: number
  currentId?: string | null
  listener?: any | null
  clubName?: string | null
  courseName?: string | null
  clubId?: string | null
}

export type AdjustmentsState = any

export interface AdjustmentValueObj {
  row?: number
  column?: number
  value: number
  option?: number
  modificationMode?: 'normal' | 'silent'
}

export interface Adjustment {
  value: AdjustmentValueObj
  adjusted: AdjustmentValueObj
  initial?: AdjustmentValueObj
  updated?: AdjustmentValueObj
  modification: UserModification | null
  unit: MeasurementUnit
  groupId?: string
}

interface BunkerAdjustment {
  fractionValue?: Adjustment
  bunkerDepth?: Adjustment
  bunkerDepthRating?: Adjustment
  squeeze?: Adjustment
  no?: Adjustment
  two?: Adjustment
  extreme?: Adjustment
  extreme2?: Adjustment
  carry?: Adjustment
}

interface FairwayAdjustment {
  fairway?: Adjustment
  fairwayWidth?: Adjustment
}

interface GreenSurfaceAdjustment {}

interface GreenTargetAdjustment {
  greentarget_obstructed?: Adjustment
  visible?: Adjustment
  effectiveDiameter?: Adjustment
}

interface LateralObstacleAdjustment {
  bounce?: Adjustment
  stroke?: Adjustment
  squeeze?: Adjustment
  two?: Adjustment
}
interface CrossingObstacleAdjustment {
  two?: Adjustment
  crossingPercentage?: Adjustment
  stroke?: Adjustment
  crossingValue?: Adjustment
  crossingCarry?: Adjustment
  safelyCrossing?: Adjustment
  shotLength?: Adjustment
}

interface RecoveryRoughAdjustment {
  layup?: Adjustment
  soft?: Adjustment
  mounds?: Adjustment
  borderedMounds?: Adjustment
  severeMounds?: Adjustment
  par3?: Adjustment
  surronded?: Adjustment
  twoRough?: Adjustment
}
interface TreesAdjustment {
  chuteRating?: Adjustment
  chuteWidth?: Adjustment
  chuteLength?: Adjustment
}

interface CommonAdjustmentNames {
  obstructed?: Adjustment
  firm?: Adjustment
  tiered?: Adjustment
  unpleasant?: Adjustment
}

interface TopographyAdjustments {
  stanceOrLie?: Adjustment
}
export interface RollAdjustment {
  F?: Adjustment
  lessRoll?: Adjustment
  tableValue?: Adjustment
  groupId?: any
}

export interface AdjustmentValue {
  [key: string]: Adjustment
}

export type AdjustmentGroupValue = {}

export type AdjustmentItem = {
  adjustmentIndex: number
  landingZones: boolean
  name: string
  key: string
  shortName: string
  valueKey: string
  shots: boolean
}

export interface Adjustments {
  adjustments: AdjustmentItem[]
}

export interface AdjustmentValueGroup {
  [golferTypeShortName: string]: AdjustmentGroupValue
}

export interface AdjustmentCollection {
  values: AdjustmentValueGroup[]
}

export interface MeasurementUnit {
  name: string
}

export interface FairwayLength {
  value: number
  modification: UserModification | null
  unit: MeasurementUnit
}

export interface Fairway {
  length: FairwayLength
}

export enum GolferShortName {
  BogeyMen = 'BM',
  ScratchMen = 'SM',
  ScratchWomen = 'SW',
  BogeyWomen = 'BW',
}

export interface GolferType {
  typeIndex: number
  name: string
  shortName: GolferShortName
  playerType: Golfer
}

export interface Shot {
  name: string
  shotIndex: number
}

export interface Tee {
  teeIndex: number
  teeNumber: number
  shots: Shot[]
  name: string
  golfers: GolferShortName[]
}

export interface Hole {
  holeIndex: number
  name: string
  params: {
    [parameterKey: string]: CourseParameter
  }
}

export interface Course {
  name: string
  holeCount: string
  holes: Hole[]
  tees: Tee[][]
  evaluations: Evaluation[]
}

type FormObject = {
  ratingForms: RatingForm[]
  tee: number
  teeName: string
}

export type RatingForm = {
  url: string
  key: string
}

export type Form = {
  name: string
  forms: FormObject[]
}

export interface Evaluation {
  id: string
  name: string
  evaluation: any
  adjustment: any
  courseId: string
  createdAt: number
  apiVersion?: string
  teamLeader?: string
  revision?: number
  evaluationId?: string
  forms?: Form[]
  description?: string
}
export interface CourseParameter {
  paramIndex: number
  name: string
  value: CourseParameterValue
  adjusted?: CourseParameterValue
  initial?: CourseParameterValue
}

export interface CourseParameterValue {
  adjusted?: number | string
  initialValue?: number | string
  value: number | string
  modification: UserModification | null
  unit: MeasurementUnit
}

export interface CourseEvaluation {
  status: number
  users: EvaluationUser[]
  courseId: string
  id: string
  course: Course
  groups: EvaluationGroup[]
  golferTypes: GolferType[]
  adjustment?: AdjustmentCollection
  loading: EvaluationLoading
  version?: string
}

export interface EvaluationGroup {
  groupIndex: number
  name: string
  adjustment: string
  type: 'ORF' | 'EPL'
  lzAdjustment: boolean
  saveMode: 'normal' | 'player' | 'gender' | 'hole' | 'tee'
  shotAdjustment: boolean
  shortName: string
}

export interface EvaluationUserView {
  adjustment: any
  hole: number
  color: string
  email: string
  golfer: GolferShortName
  shot: number
  tee: number
  collapsedTees?: number[]
  prevAdj?: number
}
export enum TeamRole {
  SYSTEM_ADMIN = 0,
  LEADER = 1,
  EVALUATOR = 2,
  READ_ONLY = 3,
}
export interface EvaluationUser {
  userId: string
  view: EvaluationUserView
  role: TeamRole
}

export interface EvaluationUsers {
  [userId: string]: EvaluationUser
}

export interface UserModification {
  userId: string
  modified: number
}

export enum Gender {
  MEN = 'M',
  WOMEN = 'W',
}
export enum Golfer {
  SCRATCH = 'Scratch',
  BOGEY = 'Bogey',
}

export interface EvaluationLoading {
  progress?: number
  loading: number
  message: string
}

export enum Measurement {
  'METRIC' = 'METRIC',
  'IMPERIAL' = 'IMPERIAL',
}

export type ClubSite = {
  siteNumber: number
  holeCount: number
}

export type BranchStatus = {
  isBranched: string
  isBranchedDate: string
  isBranchedLiveCR: string
  isBranchedLiveCRDate: string
  hasTPD: string
  hasTPDDate: string
  firstTimeSyncedDate: string
  latestTimeSyncedDate: string
  liveCRRevision: string
  liveCRIsCheckedOut: string
  liveCRCheckedOutBy: string
  isGreenMarked: string
}

export type MiClub = {
  id: string
  gitID: string
  name: string
  shortName: string
  website: string
  districtID: string
  geoDistrictID: string
  logoUploadFilePath: string
  logoFileID: string
  city: string
  longitude: string
  latitude: string
  gisJsonId: string
  crJsonId: string
  districtName: string
  districtCountry: string
  clubLogoUrl?: string
  mapLatitude?: string
  mapLongitude?: string
  sites?: ClubSite[]
  regionName?: string
  branchStatus: BranchStatus
  projectStatus: MiProjectStatus
  courses?: MICourse[]
}

export enum MiProjectStatus {
  NOT_SET = '0',
  PLANNED = '1',
  MEASURED = '2',
  REVIEW_BEFORE_RATING = '3',
  READY_FOR_RATING = '4',
  RATING_IN_PROGRESS = '5',
  RATED = '6',
}

export type MiClubWithCourses = MiClub & {
  courses: MICourse[]
}

export type CRUpdateData = {
  adjustment: Adjustments
  newValue: string | number
  oldValue: string | number
  context: { key: string; value: number | string }[]
  key: string
  timestamp: string
}

export type CRUpdate = {
  key?: string
  createdAt: number
  data: CRUpdateData
  status: MiNotificationStatus
  type: MiNotificationTypes
  userId: string
}

export enum MiNotificationStatus {
  UNHANDLED = 0,
  RESOLVED = 1,
  REJECTED = 2,
}

export enum MiNotificationTypes {
  CR = 'livecr_update',
}

export type MiNotificationObject = {
  [key: string]: CRUpdate
}

export enum GrassType {
  COOL = 'cool',
  WARM = 'warm',
}

export enum WHSEvalStatus {
  NOT_STARTED = '0',
  PREPARING = '1',
  IN_PROGRESS = '2',
  FINALIZED = '3',
}
