import React, { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import { useDispatch } from 'react-redux'

import { ColorPickerHeader } from './ColorPickerOverlay.styles'

type OwnProps = {
  handleChange: (color: any, userId: string) => void
  defaultColor: string
  userId: string
}

type Props = OwnProps

export const ColorPickerOverlay = ({
  handleChange,
  defaultColor,
  userId,
}: Props) => {
  const [color, setColor] = useState(defaultColor)
  const dispatch = useDispatch<any>()
  const changeColor = (result) => {
    handleChange(result, userId)
    setColor(result.hex)
  }
  return (
    <div>
      <ColorPickerHeader
        onClick={() => dispatch({ type: 'SET_MODAL', params: null })}
      >
        Press here to close
      </ColorPickerHeader>
      <ChromePicker color={color} onChangeComplete={changeColor} />
    </div>
  )
}
