import RNFirebase from '@app/firebase/index.web'
import { connect, Provider } from 'react-redux'
import {
  firebaseReducer,
  ReactReduxFirebaseProvider,
} from 'react-redux-firebase'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { effectsMiddleware } from 'redux-effex'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'
import { Persistor, persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import reducers from './reducers'

const rootReducer = combineReducers({
  ...reducers,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
})

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [
    'modal',
    'evaluation',
    'adjustments',
    'features',
    'firestore',
    'firebase',
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
    })) ||
  compose

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
)

const onRehydrateComplete = () => {
  const state = store.getState()
  console.log('Rehydrated state:', state)

  // Set the user after rehydration is complete
  const { user } = state

  store.dispatch({ type: 'SET_USER', value: user })
}

const persistor: Persistor = persistStore(store, null, console.log)

const rrfConfig = {
  userProfile: 'users',
}

console.log(RNFirebase.app())
const rrfProps = {
  firebase: RNFirebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

export {
  Provider,
  connect,
  createStore,
  combineReducers,
  applyMiddleware,
  effectsMiddleware,
  persistor,
  PersistGate,
  ReactReduxFirebaseProvider,
  rrfProps,
}

export default store
