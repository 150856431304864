import React, { useState } from 'react'
import theme from '@app/ui/theme'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import sv from 'date-fns/locale/sv'
import { ButtonProps } from 'react-native'
registerLocale('sv', sv)

type Props = {
  stateHook: [Date, React.Dispatch<React.SetStateAction<Date>>]
  disabled?: boolean
}

const DateInput: React.FC<Props> = ({ stateHook, disabled = false }) => {
  const [startDate, setStartDate] = stateHook

  return (
    <DatePicker
      selected={startDate}
      onChange={(date: Date) => setStartDate(date)}
      dateFormat="yyyy-MM-dd"
      locale="sv"
      popperPlacement="bottom-start"
      disabled={disabled}
    />
  )
}

export default DateInput
