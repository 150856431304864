import styled from 'styled-components'

export const Title = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 500;
  position: sticky;
  top: 0;
`

export const RightComponentCard = styled.div`
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  height: fit-content;
`

export const ComponentContainer = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`
