import * as React from 'react'
import InformationBox from './InformationBox/InformationBox'
import * as S from './Profile.Styles'

const Profile: React.FC = () => (
  <S.ProfileWrapper>
    <S.Header />
    <S.ContentContainer>
      <InformationBox />
    </S.ContentContainer>
  </S.ProfileWrapper>
)

export default Profile
