import ModalWrapper from '@app/modal'
import Main from '@app/navigation'
import { SnackbarProvider } from '@app/snackbar'
import store, {
  PersistGate,
  persistor,
  Provider,
  ReactReduxFirebaseProvider,
  rrfProps,
} from '@app/state'
import { State } from '@app/state/types'
import theme from '@app/ui/theme'
import React from 'react'
import { View } from 'react-native'
import { Switch } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

const Features = () => {
  const features = useSelector((state: State) => state.features)
  const dispatch = useDispatch<any>()

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '600px',
        maxHeight: '500px',
        padding: '20px',
        margin: 'auto',
      }}
    >
      <h2
        style={{
          fontFamily: 'Proxima Nova',
          letterSpacing: '0.6px',
          textTransform: 'uppercase',
          fontWeight: 500,
          color: '#333',
          paddingLeft: '30px',
          fontSize: '18px',
        }}
      >
        Mapping Internal / Features
      </h2>
      <table
        style={{
          width: '100%',
          padding: '15px',
          boxShadow: '0 0px 40px 0px #ccc',
          backgroundColor: 'white',
        }}
        cellPadding="15"
      >
        <tr>
          <td>
            <b>ID</b>
          </td>
          <td>
            <b>Status</b>
          </td>
          <td></td>
        </tr>
        {Object.keys(features.features).map((featureKey, index) => (
          <tr key={index}>
            <td>{featureKey}</td>
            <td>
              {features.features[featureKey].enabled ? 'Enabled' : 'Disabled'}
            </td>
            <td>
              <Switch
                trackColor={{ false: '#999', true: '#000' }}
                onValueChange={(value) => {
                  dispatch({ type: 'SET_FEATURE', featureKey, value })
                }}
                value={features.features[featureKey].enabled}
              />
            </td>
          </tr>
        ))}
      </table>
    </View>
  )
}

export default Features
