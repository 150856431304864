import theme from '@app/ui/theme'
import styled from 'styled-components/native'

export const OuterContainer = styled.View<{
  width: number
  flex?: boolean
}>`
  text-align: center;
  width: ${({ width }) => width}px;
  margin-right: 15px;
  ${({ flex }) => flex && 'flex: 1'};
`
export const FieldContainer = styled.View<{
  backgroundColor: string
  borderWidth: number
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-type: solid;
  border-radius: 5px;
  margin-bottom: 5px;
  border-width: ${({ borderWidth }) => borderWidth}px;
  border-color: ${theme.colors.light4};
`
