import theme from '@app/ui/theme'
import styled from 'styled-components'

const indicatorSize = 8

export const UserColorIndicator = styled.div<{
  userColor?: string
  zIndex: number
  size: number
}>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  background-color: ${({ userColor }) => userColor};
  border: 2px solid ${theme.colors.white};
  border-radius: 500px;
  top: 0;
  right: -2px;
  position: absolute;
  z-index: ${({ zIndex }) => zIndex + 1};
`

export const AvatarContainer = styled.div<{
  userColor?: string
  isLastIndex?: boolean
  avatarSize: number
}>`
  height: ${({ avatarSize }) => avatarSize}px;
  width: ${({ avatarSize }) => avatarSize}px;
  background-color: ${({ userColor }) => userColor};
  border: 3px solid white;
  border-radius: 500px;
  box-shadow: 2px 1px 4px rgb(0 0 0 / 30%);
  opacity: ${({ isLastIndex }) => (isLastIndex ? 1 : 0.7)};
  margin-left: ${({ isLastIndex }) => (!isLastIndex ? -8 : 0)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImgContainer = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 500px;
`

export const AvatarText = styled.span<{
  size: number
}>`
  font-size: ${({ size }) => size - 4}px;
  font-weight: 800;
  color: white;
`

export const OuterContainer = styled.div<{
  zIndex: number
}>`
  position: relative;
  zindex: ${({ zIndex }) => zIndex}px;
`
