import CourseMap from '@app/course-map'
import { useClubData } from '@app/courses/hooks'
import * as React from 'react'

const Map: React.FC = () => {
  const { courses, clubs } = useClubData()
  const coursesWithClubLocationAndStatus = courses?.map((course) => {
    const club = clubs?.find((club) => club.id === course.golfClubID)
    if (club) {
      return {
        ...course,
        branchStatus: club.branchStatus,
      }
    }
    return course
  })

  return (
    <CourseMap
      courses={
        coursesWithClubLocationAndStatus && coursesWithClubLocationAndStatus
      }
    />
  )
}

export default Map
