import _styled from 'styled-components/native'
import { media } from '@app/ui'
import styled, { css, keyframes } from 'styled-components'
import theme from '@app/ui/theme'

export const Divider = styled.hr`
  max-width: 100%;
  background-color: ${theme.colors.light4};
  height: 2px;
  opacity: 0.5;
  border: none;
  padding: 0px;
  margin: 0px;
`

export const Wrapper = styled.div`
  margin: 0px;
  height: 100%;
  background-color: ${theme.colors.background};
`

export const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
`

export const EvaluationCard = styled.div<{
  modalShowing: boolean
  inAdjustment: boolean
}>`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 20px);
  overflow-x: hidden;
  border-radius: 14px;
  background-color: #fbfdff;
  margin: 10px;
  margin-top: 0px;
  ${({ modalShowing }) => modalShowing && 'pointer-events: none'}
  height: ${({ inAdjustment }) =>
    inAdjustment ? '100%' : 'calc(100% - 20px)'};
`

export const HoleIsLockedContainer = _styled.View`
 justify-content: center;
  align-items: center;
  margin: auto;
`

export const AdjustmentCard = styled.div`
  margin: 20px;
  transition: 0.3s;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 60px;

  ${media.tablet} {
    width: 100%;
    max-width: 100%;
  }
`

interface EvalSideBarProps {
  animLeft?: number
  collapsed?: boolean
}

const collapsedSize = '130px'

export const EvalSideBar = styled.div<EvalSideBarProps>`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.colors.light};
  width: ${(props) => (props.collapsed ? collapsedSize : '')};
  min-width: ${(props) => (props.collapsed ? collapsedSize : '260px')};
  min-height: 100%;
  background-color: white;
  transition: all 0.3s;
  animation: sidebar-in 0.3s ease;
  @keyframes sidebar-in {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  box-shadow: 0 0px 12px 0 ${theme.colors.dark1};
  overflow: hidden;
`

export const EvalSideBarTop = styled.div`
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.colors.light};
  background-color: ${theme.colors.white};
  padding-bottom: 18px;
  overflow-wrap: never;
  & h2 {
    letter-spacing: 0.6;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    display: block;
    width: 100%;
    font-weight: 460;
  }
  & span {
    font-size: 15px;
  }
`

export const EvalSideBarMain = styled.div``

interface EvalSideBarTabItemProps {
  sideBarCollapsed?: boolean
}

export const EvalSideBarTabItem = styled.div<EvalSideBarTabItemProps>`
  height: 39px;
  border-bottom: 1px solid ${theme.colors.light};
  background-color: ${theme.colors.white};

  & a {
    cursor: pointer;
    white-space: nowrap;
    background-color: ${theme.colors.white};
    color: #333;
    display: block;
    line-height: 40px;
    padding-left: 20px;
    padding-right: 10px;
    height: 100%;
    font-size: 16px;
    font-weight: 460;
    text-wrap: no-wrap;
    letter-spacing: 0px;
  }

  &:hover a {
  }

  &.active a {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  }
`

export const EvalSideBarGroupRow = styled.div`
  height: 40px;
  background-color: ${theme.colors.primary};

  & a {
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.8);
    display: block;
    line-height: 40px;
    padding-left: 20px;
    padding-right: 10px;
    height: 100%;
    font-size: 14px;
    font-weight: 400;
    text-wrap: no-wrap;
    text-transform: uppercase;
  }

  &.active a {
    background-color: #666;
    color: ${theme.colors.white};
  }
`

export const ContentCard = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${theme.colors.white};
  color: ${theme.colors.secondary2};
  border-radius: 4px;
`

export const ContentHeading = styled.div`
  font-size: 22px;
  font-weight: 400;
  color: ${theme.colors.secondary2};
  padding: 10px;
  line-height: 50px;
`

export const ContentContainer = styled.div`
  margin-top: 30px;
  padding-bottom: 60px;
`

export const EvalSideBarMenus = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 0px;
  margin-top: 20px !important;
`

export const EvalSideBarTopCont = styled.div`
  width: 100%;
  padding-right: ${theme.size[3]}px;
  box-sizing: border-box;
`

export const CollapsedSideBarContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const SideBarToggleButtonContainer = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CourseOptionItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  height: 30px !important;
`

export const CourseOptionItemLabel = _styled.Text`
  margin-right: 4px;
  font-weight: 500;
  opacity: 0.7;
`

export const CourseOptionItemInput = styled.input`
  -webkit-appearance: none;
  height: 26px !important;
  line-height: 28px !important;
  overflow: hidden;
  font-size: 15px;
  padding: 0px !important;
  &:disabled {
    opacity: 1;
  }
`

export const CollapsedInputLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
`

export const CollapsedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const LiveCRVersionContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.baseline}px;
  padding-bottom: 16px;
`
export const LiveCRVersionDivider = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${({ theme }) => theme.colors.light};
`

export const LiveCRVersionText = styled.span`
  white-space: nowrap;
  color: #333;
  font-size: 16px;
  font-weight: 460;
  text-wrap: no-wrap;
  letter-spacing: 0px;
`

export const TopBarContainer = styled.div`
  display: flex;
  background-color: ${theme.colors.background};
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  top: 0;
`

export const AvatarContainer = _styled.View<{ width: number; padding: number }>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: ${({ padding }) => padding}px;
  width: ${({ width, padding }) => width - padding}px;
  height: 42px;
`

export const ConnectionButton = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.colors.warning};
  opacity: 0.8;
  width: 80%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  z-index: 100;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
  &:hover {
    opacity: 1;
  }
`

export const ConnectionText = styled.span`
  font-size: 22px;
  font-weight: 400;
  color: ${theme.colors.white};
  text-align: center;
  word-wrap: break-word;
  white-space: break-spaces;
`
