import { media } from '@app/ui'
import theme from '@app/ui/theme'
import styled from 'styled-components'

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  border-collapse: collapse;
  overflow-x: auto;
`

export const Table = styled.table`
  width: 99%;
  margin-top: 5px;
  border-collapse: collapse;
`

export const TableRowHead = styled.tr`
  border-bottom: 1px solid ${theme.colors.light};
`

export const TableHead = styled.thead``

export const TableHeadCol = styled.th`
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 3px;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
`

export const TableHeadColName = styled(TableHeadCol)`
  width: 30%;
`

export const TableHeadColRegion = styled(TableHeadCol)`
  width: 10%;
`

export const TableHeadColLastRated = styled(TableHeadCol)`
  width: 10%;
`

export const TableHeadColErLr = styled(TableHeadCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableHeadColHoles = styled(TableHeadCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableHeadColMap = styled(TableHeadCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableHeadColStatus = styled(TableHeadCol)`
  border-right: none !important;
`

export const TableBody = styled.tbody``

export const TableRow = styled.tr`
  &:last-child > * {
    border-bottom: none !important;
  }
`

export const TableCol = styled.td`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 30px;
  text-align: center;
  padding: 5px;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;

  &:first-child {
    text-align: left;
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
`

export const TableColCenter = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const TableColLogo = styled(TableCol)`
  width: 75px;
`

export const TableColLast = styled(TableCol)`
  border-right: none !important;
  width: 100px;
`

export const TableColStatus = styled.div`
  display: flex;
  flex-direction: row;
  .statusColor {
    width: 32px;
    height: 32px;
    border-radius: 18px;
  }

  .course-status-label {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #333;
    width: 80%;
  }
`

export const TableColErLr = styled(TableCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableColHoles = styled(TableCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableColMap = styled(TableCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const ClubHoleCount = styled.div`
  text-align: left;
  margin: 0px;
  border: 1px solid #ccc;
  width: 32px;
  height: 32px;
  border-radius: ${theme.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.primary};
  font-weight: 600;
  font-size: 15px;
`

export const EvaluationStatusText = styled.span`
  padding-left: 10px;
`

export const EvaluationStatusDescription = styled.span`
  font-size: 0.8em;
  font-weight: 400;
  padding-left: 10px;
`

export const ItemStatusColor = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border: 1px solid rgb(32, 120, 38);
  border-radius: 999px;
  background-color: ${(props) => props.color};
  margin-right: 4px;

  @media (max-width: 1080px) {
    width: 15px;
    height: 15px;
  }
`

export const ItemStatusMainText = styled.span`
  font-weight: bold;
  white-space: nowrap;
  text-align: start;
`

export const ItemStatusSubText = styled.span`
  font-size: 0.9em;
  font-weight: 400;
  color: grey;
`
