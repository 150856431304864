import { Eval } from '@app/evaluation-core/types'
import { useState } from 'react'

type SortColumn = 'club' | 'course' | 'date' | 'courseId' | 'clubId'
type SortOrder = 'asc' | 'desc'
type EvalWithKey = Eval & { key: string }

const useEvaluationSorting = (initialColumn: SortColumn = 'club') => {
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
  const [sortedColumn, setSortedColumn] = useState<SortColumn>(initialColumn)

  const sortEvaluations = (evaluations: EvalWithKey[]) => {
    const sortedEvaluations = [...evaluations]

    sortedEvaluations.sort((a, b) => {
      if (sortedColumn === 'club') {
        const clubNameA = a.clubName || ''
        const clubNameB = b.clubName || ''

        if (clubNameA === clubNameB) return 0
        if (!clubNameA) return sortOrder === 'asc' ? 1 : -1
        if (!clubNameB) return sortOrder === 'asc' ? -1 : 1

        return sortOrder === 'asc'
          ? clubNameA.localeCompare(clubNameB)
          : clubNameB.localeCompare(clubNameA)
      }
      if (sortedColumn === 'course') {
        const courseNameA = a.courseName || ''
        const courseNameB = b.courseName || ''

        if (courseNameA === courseNameB) return 0
        if (!courseNameA) return sortOrder === 'asc' ? 1 : -1
        if (!courseNameB) return sortOrder === 'asc' ? -1 : 1

        return sortOrder === 'asc'
          ? courseNameA.localeCompare(courseNameB)
          : courseNameB.localeCompare(courseNameA)
      }
      if (sortedColumn === 'date') {
        const evaluationDateA = a.evaluationDate
        const evaluationDateB = b.evaluationDate

        if (evaluationDateA === undefined && evaluationDateB === undefined)
          return 0
        if (evaluationDateA === undefined) return 1
        if (evaluationDateB === undefined) return -1

        const dateA = new Date(evaluationDateA).getTime()
        const dateB = new Date(evaluationDateB).getTime()

        if (isNaN(dateA) && isNaN(dateB)) return 0
        if (isNaN(dateA)) return sortOrder === 'asc' ? 1 : -1
        if (isNaN(dateB)) return sortOrder === 'asc' ? -1 : 1

        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA
      }

      return 0
    })

    return sortedEvaluations
  }

  const handleSort = (column: SortColumn) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortedColumn(column)
      setSortOrder('asc')
    }
  }

  return { sortOrder, sortedColumn, sortEvaluations, handleSort }
}

export default useEvaluationSorting
