import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import {
  AdmFactorLabel,
  AdmFactorLabelContainer,
  AdmFactorLabelItem,
} from './AdjustmentFactors.styles'

type Props = { style?: StyleProp<ViewStyle> }

const OrLabel: React.FC<Props> = ({ style }: Props) => (
  <AdmFactorLabel style={style}>
    <AdmFactorLabelContainer>
      <AdmFactorLabelItem style={{ margin: 0 }}>or</AdmFactorLabelItem>
    </AdmFactorLabelContainer>
  </AdmFactorLabel>
)

export default OrLabel
