import styled from 'styled-components'
import theme from '@app/ui/theme'

export const TeeWrapper = styled.div`
  width: 450px;
  height: 450px;
  background-color: white;

  border-radius: 12px;
`
export const TeeHeader = styled.div`
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const CloseButton = styled.div`
  cursor: pointer;
  background-color: #a5a5a5;
  width: 36px;
  height: 36px;
  border-radius: ${theme.borderRadius}px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  margin-right: 10px;
  float: right;
`
export const TeeTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #282828;
  margin-left: 173px;
`
export const TeeLine = styled.div`
  border-bottom: 1px solid #cecece;
`
export const TeeContent = styled.div`
  margin: 30px 26px 0px 26px;
`
export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`
export const Input = styled.input`
  background: #f9f9f9;
  border: 1px solid #cecece;
  box-sizing: border-box;
  border-radius: 5px;
  width: 394px;
  height: 40px;
  margin-top: 12px;
  text-indent: 16px;

  & :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }
  & ::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #a5a5a5;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > .button {
    flex: 1;
  }
`
