import { getPlayerShots } from '@common/hooks/getPlayerShots'
import React, { useState } from 'react'
import { View } from 'react-native'

import Headers from './headers'

type Props = {
  adjustment: string
}

// eslint-disable-next-line react/prop-types
const Header: React.FC<Props> = ({ adjustment }) => {
  const shots = getPlayerShots()
  const getHeaderComponent = (): JSX.Element => {
    if (adjustment === 'topo') {
      return <Headers.Topography amount={shots} />
    }
    if (adjustment === 'fwy' || adjustment === 'dl') {
      return <Headers.LandingZones amount={shots} />
    }
    if (adjustment === 'cross' || adjustment === 'lat') {
      return <Headers.Shots amount={shots} />
    }
    return <></>
  }
  return getHeaderComponent()
}

export default Header
