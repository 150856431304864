import theme from '@app/ui/theme'
import styled from 'styled-components'

export const ProfileWrapper = styled.div`
  flex: 1;
  background-color: ${theme.colors.background};
  padding: 24px;
  font-family: 'Roboto';
`
export const Header = styled.div``
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`
