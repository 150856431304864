import {
  AdjustmentTypes,
  setAdjustment,
  setExcludedKeys,
} from '@app/evaluation-core'
import {
  EvaluationUserView,
  LockStatus,
  TeamRole,
} from '@app/evaluation-core/types'

import useEvaluationLockedState from './useEvaluationLockedState'
import { useSnackbar } from '../../app/snackbar'
import { submitPreparation } from '@app/evaluation-core/evaluation'
import {
  adjustAdjustment,
  saveAdjustment,
} from '@app/evaluation-core/modifications'
import useEvaluationUser from './useEvaluationUser'

interface DefaultAdjustmentChangeParams {
  type: AdjustmentTypes
  view: EvaluationUserView
  key: string
  evaluationId: string
  value: number | string
  excludedKeys: string[]
}

type SetAdjustment = (
  type: AdjustmentTypes,
  view: EvaluationUserView,
  key: string,
  evaluationId: string,
  value: number | string
) => any

type SetAdjustmentWithExcludedKeys = (
  type: AdjustmentTypes,
  view: EvaluationUserView,
  key: string,
  evaluationId: string,
  value: number | string,
  excludedKeys: string[]
) => any

export default (): [SetAdjustment, SetAdjustmentWithExcludedKeys] => {
  const lockStatus = useEvaluationLockedState()
  const [openSnackbar] = useSnackbar()
  const user = useEvaluationUser()

  const isLocked = lockStatus === LockStatus.LOCKED
  const isReadOnly = lockStatus === LockStatus.READ_ONLY

  const lockedText = 'This hole is locked, please contact your team leader.'
  const readOnlyText =
    'This rating has been finalized and is in read only-mode.'

  const setAdjustmentWithSnackbar = (
    type: AdjustmentTypes,
    view: EvaluationUserView,
    key: string,
    evaluationId: string,
    value: number | string
  ) => {
    if (user?.role === TeamRole.READ_ONLY) {
      return openSnackbar('You do not have permission to make changes')
    }

    if (isReadOnly) {
      return openSnackbar(readOnlyText)
    }
    // if (type !== 'course' && isLocked) {
    //   return openSnackbar(lockedText)
    // }
    submitPreparation()
    setAdjustment(type, view, key, evaluationId, value)
    return adjustAdjustment({
      evaluationId,
      adjustmentIndex: view.adjustment as number,
      hole: view?.hole,
      tee: view?.tee,
      golfer: view?.golfer,
      shot: view?.shot,
    })
  }

  const setExcludedKeysWithSnackbar = (
    type: AdjustmentTypes,
    view: EvaluationUserView,
    key: string,
    evaluationId: string,
    value: number | string,
    excludedKeys: string[]
  ) => {
    // if (isReadOnly) {
    //   return openSnackbar(readOnlyText)
    // }
    // if (type !== 'course' && isLocked) {
    //   return openSnackbar(lockedText)
    // }
    setExcludedKeys(type, view, key, evaluationId, value, excludedKeys)
  }

  return [setAdjustmentWithSnackbar, setExcludedKeysWithSnackbar]
}
