import theme from '@app/ui/theme'
import { EvalStatus, Statuses } from './ratingStatus'
import { MiProjectStatus } from '@app/evaluation-core/types'
import { BranchStatus } from '@app/evaluation-core/types'

enum MiBool {
  TRUE = '1',
  FALSE = '0',
}

const convertToDate = (dateString: string) =>
  new Date(dateString).toLocaleDateString('sv-se')

export const getEvaluationStatusColor = (status: number) => {
  switch (status) {
    case EvalStatus.PREPARING:
      return '#092f1a'
    case EvalStatus.IN_PROGRESS:
      return '#2B9E33'
    default:
      return theme.colors.primary
  }
}

export const getClubStatusText = (
  clubStatus: Statuses,
  branchStatus: BranchStatus
) => {
  return CLUB_STATUS_DESCRIPTIONS(branchStatus)[clubStatus]
}

export const CLUB_STATUS_DESCRIPTIONS = (branchStatus: BranchStatus) => ({
  [Statuses.UNKNOWN]: 'Unknown',
  [Statuses.NOT_AVAILABLE]: 'No map info, contact Mapping Industries',
  [Statuses.PLANNED]: 'Waiting for map information',
  [Statuses.MEASURED]: `Mapped: ${convertToDate(
    branchStatus?.hasTPDDate
  )} but not uploaded to LiveCR.`,
  [Statuses.REVIEW_BEFORE_RATING]: `Review in LiveCR and upload - ${convertToDate(
    branchStatus?.isBranchedLiveCRDate
  )}`,
  [Statuses.READY_FOR_RATING]: `${convertToDate(
    branchStatus?.firstTimeSyncedDate
  )}. Prepare and begin a rating.`,
})

export const EVALUATION_STATUS = [
  'Not Available',
  'Planned',
  'Measured',
  'Review before rating',
  'Ready for rating',
  'Unknown',
]

export const getClubStatusColor = (status: Statuses) => {
  switch (status) {
    case Statuses.NOT_AVAILABLE:
      return '#EE0000'
    case Statuses.PLANNED:
      return '#FF7A00'
    case Statuses.MEASURED:
      return '#FFC107'
    case Statuses.REVIEW_BEFORE_RATING:
      return '#1E90FF'
    case Statuses.READY_FOR_RATING:
      return '#2B9E33'
    default:
      return theme.colors.primary
  }
}

export const getClubStatusName = (status: Statuses) => {
  if (status === Statuses.NOT_AVAILABLE) {
    return 'not_available'
  }
  if (status === Statuses.PLANNED) {
    return 'planned'
  }
  if (status === Statuses.MEASURED) {
    return 'measured'
  }
  if (status === Statuses.READY_FOR_RATING) {
    return 'ready_for_evaluation'
  }
  if (status === Statuses.REVIEW_BEFORE_RATING) {
    return 'in_progress'
  }
}

export const getClubStatus = (branchStatus: BranchStatus): Statuses => {
  return Statuses.READY_FOR_RATING
  const notAvailable =
    branchStatus?.isBranchedLiveCR === MiBool.FALSE &&
    branchStatus?.isBranched === MiBool.FALSE &&
    branchStatus?.hasTPD === MiBool.FALSE
  if (notAvailable) {
    return Statuses.NOT_AVAILABLE
  }

  const planned =
    branchStatus?.isBranchedLiveCR === MiBool.FALSE &&
    branchStatus?.isBranched === MiBool.TRUE &&
    branchStatus?.hasTPD === MiBool.FALSE
  if (planned) {
    return Statuses.PLANNED
  }

  const measured =
    branchStatus?.isBranchedLiveCR === MiBool.FALSE &&
    branchStatus?.isBranched === MiBool.TRUE &&
    branchStatus?.hasTPD === MiBool.TRUE
  if (measured) {
    return Statuses.MEASURED
  }

  const reviewBeforeRating =
    branchStatus?.isBranchedLiveCR === MiBool.TRUE &&
    branchStatus?.isBranched === MiBool.TRUE &&
    branchStatus?.hasTPD === MiBool.TRUE &&
    branchStatus?.isGreenMarked === MiBool.FALSE
  if (reviewBeforeRating) {
    return Statuses.REVIEW_BEFORE_RATING
  }

  const readyForRating =
    branchStatus?.isBranchedLiveCR === MiBool.TRUE &&
    branchStatus?.isBranched === MiBool.TRUE &&
    branchStatus?.hasTPD === MiBool.TRUE &&
    branchStatus?.isGreenMarked === MiBool.TRUE
  if (readyForRating) {
    return Statuses.READY_FOR_RATING
  }
  return Statuses.UNKNOWN
}
