import React from 'react'
import * as S from './styled'

interface SpacerProps {
  width?: number
  height?: number
  marginTop?: number
}

const Spacer = ({ width = 0, height = 0, marginTop = 0 }: SpacerProps) => {
  return <S.Container width={width} height={height} marginTop={marginTop} />
}

export default Spacer
