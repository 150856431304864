import styled from 'styled-components/native'
import theme from '@app/ui/theme'

export const ModalContainer = styled.ScrollView<{
  visible: boolean
}>`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 10;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

export const ModalContent = styled.View<{
  portraitMode: boolean
  isTablet: boolean
  collapsedMode: boolean
}>`
  border-radius: 12px;
  border-color: transparent;
  background-color: ${theme.colors.white};
  height: 523px;
  margin: auto;
  margin-top: 10%;
  width: ${({ portraitMode }) => (portraitMode ? '700px' : '902px')};
  padding: 16px;
  overflow: scroll;
  transform: ${(props) =>
    !props.isTablet
      ? props.collapsedMode
        ? 'translateX(-35px)'
        : 'translateX(-128px)'
      : ''};
  transition: 1s;
`

export const ModalTitle = styled.Text`
  font-weight: bold;
  font-size: 16;
`
