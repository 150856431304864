import theme from '@app/ui/theme'
import styled from 'styled-components'

export const Container = styled.div<{ selected: boolean }>`
  cursor: pointer;
  min-width: 100px;
  width: 100px;
  height: 56px;
  background-color: ${theme.colors.primary};
  border-radius: 5px;
  text-align: center;
  flex-direction: column;
  display: flex;
  border: ${({ selected }) =>
    selected
      ? `2px solid ${theme.colors.secondary2}`
      : `2px solid transparent`};
`

export const HoleText = styled.span<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected ? theme.colors.secondary2 : theme.colors.white};
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  white-space: nowrap;
`

export const HoleValueText = styled(HoleText)`
  font-weight: 600;
`

export const GHNText = styled.span`
  color: ${theme.colors.light4};
  font-weight: 400;
  font-size: 14px;
  flex: 1;
`

export const GHNValueText = styled(GHNText)`
  font-weight: 600;
`
