import styled from 'styled-components'

export default styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  font-weight: 460;
  letter-spacing: 0.4px;
  color: #242c2b;
`
