import { ContentCard } from '@app/evaluation/components/Preparation.styles'
import { media } from '@app/ui'
import theme from '@app/ui/theme'
import styled from 'styled-components'

export const ContentMain = styled.div`
  width: 70%;
  ${media.desktop} {
    width: 100%;
  }
`

export const ContentRight = styled.div`
  width: 30%;
  ${media.desktop} {
    display: none;
  }
`

export const ContentCardMap = styled(ContentCard)`
  padding: 0;
  position: sticky;
  height: calc(100vh - 160px);
`

export const MapWrapper = styled.div`
  display: flex;
  flex: 1;
`

export const LeftPane = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`

export const RightPane = styled.div`
  width: 40%;
  height: 100%;
  background-color: ${theme.colors.light};
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 70px;
  ${media.tablet} {
    display: none;
  }
`

export const TableContainer = styled.table`
  width: 100%;
  margin-top: 5px;
`

export const TableHead = styled.thead``

export const TableRowHead = styled.tr`
  border-bottom: 1px solid ${theme.colors.light};
`

export const TableHeadCol = styled.th`
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 3px;
  text-align: center;
  &:nth-child(3) {
    text-align: left;
    padding-left: 40px;
  }
  &:nth-child(4) {
    border-bottom: 0px solid;
  }
`

export const TableHeadColName = styled(TableHeadCol)`
  width: 30%;
`

export const TableHeadColRegion = styled(TableHeadCol)`
  width: 10%;
`

export const TableHeadColLastRated = styled(TableHeadCol)`
  width: 10%;
`

export const TableHeadColErLr = styled(TableHeadCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableHeadColHoles = styled(TableHeadCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableHeadColMap = styled(TableHeadCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableHeadColStatus = styled(TableHeadCol)`
  border-right: none !important;
`

export const TableBody = styled.tbody``

export const TableRow = styled.tr`
  &:last-child > * {
    border-bottom: none !important;
  }
`

export const TableCol = styled.td`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 30px;
  text-align: center;
  padding: 5px;
  padding-left: 0;

  &:first-child {
    text-align: left;
  }
  &:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
`

export const TableColCenter = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const TableColLogo = styled(TableCol)`
  width: 75px;
`

export const TableColLast = styled(TableCol)`
  border-right: none !important;
  width: 100px;
`

export const TableColStatus = styled.div`
  display: flex;
  flex-direction: row;
  .statusColor {
    width: 32px;
    height: 32px;
    border-radius: 18px;
    border: 1px solid black;
  }

  .course-status-label {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #333;
    width: 80%;
  }
`

export const TableColErLr = styled(TableCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableColHoles = styled(TableCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const TableColMap = styled(TableCol)`
  width: 10%;
  ${media.desktop} {
    display: none;
  }
`

export const HeadingContainer = styled.div`
  background-color: ${theme.colors.white};
  width: calc(60% - 30px);
  ${media.tablet} {
    width: calc(100% - 40px);
  }
  padding: 20px;
  padding-top: 50px;
  padding-left: 40px;
  padding-bottom: 0;
  display: flex;
  flex: 1;
  justify-content: flex-start;
`

export const Heading = styled.h2`
  font-size: 26px;
  font-weight: 300;
`

export const ClubContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-top: 10px;
`

export const ClubHoleCount = styled.div`
  margin: 0 auto;
  border: 1px solid black;
  width: 32px;
  height: 32px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.black};
  font-weight: 600;
  font-size: 15px;
`

export const ClubHeader = styled.div``

export const ClubSubHeader = styled.div`
  margin-left: 50px;
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: flex-row;

  & > img {
  }

  & > span,
  a {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-left: 15px;
    margin-right: 15px;
    line-height: 22px;
  }

  & > a {
    color: ${theme.colors.primary};
    font-weight: 460;
  }
`

export const ClubSubHeaderCountryFlag = styled.div`
  width: 40px;
`

export const ClubSubHeaderSeparator = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  width: 1px;
  height: 22px;
  background-color: rgba(0, 0, 0, 0.3);
`

export const ClubLogo = styled.div`
  height: 70px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ClubTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 460;
  letter-spacing: 0.4px;
  color: #242c2b;
  display: block;
  line-height: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
`

export const ClubSideCol = styled.div`
  width: 116px;
`

export const ClubMainCol = styled.div`
  width: 100%;
`

export const ClubsNoResults = styled.div`
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  opacity: 0.7;
  display: flex;
  flex-direction: row;

  & > span {
    margin-left: 10px;
    line-height: 36px;
    height: 36px;
    display: block;
  }
`
