import { CourseOptionView } from '@app/evaluation-settings-menu/HeaderArea.styles'
import React, { useState, useEffect, FunctionComponent, Ref } from 'react'
import * as S from './styled'

type Props = {
  title: string
  holeInfo?: string
  getMeasurementText: (
    usName: string,
    metricName: string,
    meas: 'imperial' | 'metric'
  ) => string
  measurementTextArgs: string[]
  inputRef?: React.MutableRefObject<HTMLInputElement>
  defaultValue: any
  onChangeSetValue: any
  onBlurSetValue?: any
  meas?: any
  disabled?: boolean
  interactive?: boolean
}

const CourseOption: FunctionComponent<Props> = ({
  title,
  holeInfo,
  getMeasurementText,
  measurementTextArgs,
  inputRef,
  defaultValue,
  onChangeSetValue,
  onBlurSetValue,
  meas,
  disabled,
  interactive,
}) => {
  const arg1 = measurementTextArgs[0]
  const arg2 = measurementTextArgs[1]

  const [defValue, setDefValue] = useState(0)

  useEffect(() => {
    if (meas == 'metric' && title.includes('Rough')) {
      const metricDefVal = Math.round(defaultValue)
      setDefValue(metricDefVal)
    } else {
      setDefValue(defaultValue)
    }
  }, [defaultValue, meas])

  const [measurementText, setMeasurementText] = useState<string>(
    getMeasurementText(arg1, arg2, meas)
  )

  useEffect(() => {
    setMeasurementText(getMeasurementText(arg1, arg2, meas))
  }, [meas])

  const handleDoubleClick = () => {
    if (inputRef) {
      if (inputRef.current) {
        inputRef?.current.select()
      }
    }
  }

  return (
    <CourseOptionView>
      <div>
        {measurementText && (
          <span>
            {title} {holeInfo} ({measurementText})
          </span>
        )}
        {!measurementText && <span>{title}</span>}
        {interactive ? (
          <input
            onDoubleClick={handleDoubleClick}
            ref={inputRef as any}
            type="number"
            value={defValue}
            onChange={(event) => onChangeSetValue(+event.target?.value)}
            onBlur={onBlurSetValue}
            onFocus={(e) => e.target.select()}
            disabled={disabled}
          />
        ) : (
          <S.SettingValueText>
            {defValue} {measurementText}
          </S.SettingValueText>
        )}
      </div>
    </CourseOptionView>
  )
}

export default CourseOption
