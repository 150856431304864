import { CourseEvaluation, TeamRole } from '@app/evaluation-core/types'
import {
  addTeamMember,
  getCurrentUser,
  startEvalFromInvite,
  submitPreparation,
} from '@app/evaluation-core/evaluation'
import { useEffect, useState } from 'react'

import fb from '@app/firebase'
import { navigate } from '@app/navigation'
import { useSnackbar } from 'react-simple-snackbar'
import useUser from './useUser'
import { sendInvitation } from '@app/evaluation-core/actions/evaluationActions'

export type Invitation = {
  evaluationId: string
  userId: string
  email: string
  courseId: string
}

const invitationLocalStorageKey = 'invitation'

const validateEmail = (value: string) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  )
const validateGolfId = (value: string) => /^\d{6}-\d{3}$/.test(value)

export default (): {
  setInvite: (invite: Invitation) => void
  acceptInvitation: (invite: Invitation) => Promise<void>
  getInvite: () => null | Invitation
  removeInvite: () => void
  sendInvite: (
    email: string,
    evaluationId: string | null | undefined,
    callBack: any
  ) => Promise<void>
} => {
  const getInvite = () => {
    const inv = localStorage.getItem(invitationLocalStorageKey)
    if (!inv) {
      return null
    }
    return JSON.parse(inv)
  }

  const [invitation, setInvitation] = useState<Invitation>(getInvite())
  const currentUser = useUser()
  const [openSnackbar] = useSnackbar()
  const setInvite = ({ evaluationId, userId, email, courseId }: Invitation) => {
    localStorage.setItem(
      invitationLocalStorageKey,
      JSON.stringify({
        evaluationId,
        userId,
        email,
        courseId,
      })
    )
    setInvitation({
      evaluationId,
      userId,
      email,
      courseId,
    })
  }

  const acceptInvitation = async (invitation: Invitation) => {
    localStorage.removeItem('invitation')
    navigate('Courses')
    await startEvalFromInvite(invitation.courseId)
  }

  const removeInvite = () => localStorage.removeItem(invitationLocalStorageKey)

  const sendInvite = async (
    username: string,
    evaluationId: string | null | undefined,
    callBack: () => void
  ) => {
    if (!evaluationId) return

    if (!validateEmail(username) && !validateGolfId(username)) {
      return openSnackbar('Please enter a valid email address or golf id.')
    }

    await sendInvitation(evaluationId, currentUser.id, username)
    openSnackbar(`An invitation email was sent to ${username.toLowerCase()}.`)
    submitPreparation()
    if (callBack) {
      callBack()
    }
  }

  useEffect(() => {
    if (!invitation || !currentUser.id) return
    setTimeout(() => {
      acceptInvitation(invitation)
    }, 500)
  }, [currentUser, invitation])

  return { setInvite, acceptInvitation, getInvite, removeInvite, sendInvite }
}
