import { NotificationsAction, NotificationsState } from '../types'

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'

export const NOTIFICATIONS_INITIAL_STATE: NotificationsState = []

export default function notifications(
  state: NotificationsState = NOTIFICATIONS_INITIAL_STATE,
  action: NotificationsAction
) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return action.value
    default:
      return state
  }
}
