import { ClubManagementStackParamList } from '@app/navigation/CoursesStack'
import { useAxios } from '@common/hooks/useAxios'
import type { StackScreenProps } from '@react-navigation/stack'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useDispatch } from 'react-redux'
import * as S from './styled'

import ContentCard from '@app/components/atoms/ContentCard'
import ScreenWrapper from '@app/components/atoms/ScreenWrapper'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { useApi } from '@common/hooks/useApi'
import Spacer from 'react-styled-spacer'
import {
  GeneratedForms,
  RatingRevision,
  RatingTemplate,
} from '../../types/Templates'

import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import ContentClubHeader from '@app/components/atoms/ContentClubHeader'
import ContentHeading from '@app/components/atoms/ContentHeading'
import GoBackButton from '@app/components/atoms/GoBackButton'
import FinalRatingItem from '@app/components/molecules/EvaluationsList/FinalRatingItem'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import { goBack, navigate } from '@app/navigation'
import {
  MiProjectBranch,
  ProjectCourse,
  ProjectHole,
} from '../../types/Project'
import TwoButtonModal from '@app/components/organisms/TwoButtonModal'
import { Input } from '@app/components/organisms/MixedEvaluationModal/styled'
import CompositeFinalRatingItem from '@app/components/molecules/EvaluationsList/CompositeFinalRatingItem'

export interface EvaluationDoc {
  branchId: number
  clubId: number
  holeIds: string[]
  saveName?: string
  id: string
  status: EvalStatus
  users: {
    role: number
    userId: string
    email: string
  }[]
  version: string
  createdAt: Date
  updatedAt: Date
  savedAt: string | null
  finalizedAt: string | null
  ratingRevision: RatingRevision
}

export interface EvaluationDocWithForms extends EvaluationDoc {
  finalizedForms: GeneratedForms[] | undefined
}

export interface SourceCourse {
  course: ProjectCourse
  source: number
  evaluations: EvaluationDoc[]
}

type OwnProps = {}

type Props = OwnProps &
  StackScreenProps<ClubManagementStackParamList, 'CreateRating'>

function findCourseIds({
  clubHoles,
  holeIds,
}: {
  clubHoles: ProjectHole[]
  holeIds: string[]
}): string[] {
  const uniqueTgfCourseIds = new Set<string>()

  clubHoles.forEach((hole) => {
    const physicalID = hole.physicalID
    if (holeIds.includes(physicalID)) {
      uniqueTgfCourseIds.add(hole.tgfCourseID)
    }
  })

  return [...uniqueTgfCourseIds]
}

const CompositeCoursePage: FunctionComponent<Props> = ({
  route,
  navigation,
}) => {
  const axios = useAxios('/ratings/templates')
  const evalAxios = useAxios('/evaluations/import')
  const dispatch = useDispatch<any>()
  const [coursesWithHoles, setCoursesWithHoles] =
    React.useState<SourceCourse[]>()

  const { holeIds, templateId, club, branchId, isRateable } =
    route?.params ?? {}

  const { data, isLoading: branchLoading } = useApi<MiProjectBranch>(
    `/courses/projects/${club.id}`
  )

  const { data: rateableEvaluations, isLoading } = useApi<EvaluationDoc[]>(
    `/evaluations/import?holeId=${holeIds}&clubId=${club.id}`,
    undefined,
    {
      refreshInterval: 5000,
    }
  )

  const { data: template, isLoading: isTemplateLoading } =
    useApi<RatingTemplate>(
      `/ratings/templates?templateId=${templateId}`,
      undefined,
      {
        refreshInterval: 3000,
      }
    )

  const [templateName, setTemplateName] = React.useState<string>('')

  const clubHoles = data?.courses.flatMap((course) => course.holes) ?? []
  const ratingCourses = findCourseIds({ clubHoles, holeIds })
  const courses = data?.courses.filter((course) =>
    ratingCourses.includes(course.id)
  )

  useEffect(() => {
    const courseHoleIds = courses?.map((course, index) => ({
      course,
      source: index + 1,
    }))

    const getRateableEvaluations = async (holeIds: string[]) => {
      try {
        const { data } = await evalAxios.get<EvaluationDoc[]>(
          `?holeId=${holeIds}&clubId=${club.id}`
        )
        return data
      } catch (error) {
        console.error('Error fetching evaluations:', error)
        return []
      }
    }

    const fetchAllEvaluations = async () => {
      if (!courseHoleIds) {
        return []
      }

      try {
        const evaluationsPromises = courseHoleIds.map((course) =>
          getRateableEvaluations(
            course.course.holes.map((hole) => hole.physicalID)
          ).then((evaluations) => ({
            ...course,
            evaluations: evaluations.filter(
              (evalu) => evalu.status === EvalStatus.FINALIZED
            ),
          }))
        )

        const evaluations = await Promise.all(evaluationsPromises)
        if (evaluations && evaluations.length > 0) {
          setCoursesWithHoles(evaluations)
        }
      } catch (error) {
        console.error('Error in fetchAllEvaluations:', error)
      }
    }

    fetchAllEvaluations()
  }, [data?.courses, club.id])

  const getFinalizedEvaluation = (evaluationId: string) =>
    template?.finalizedForms?.filter(
      (evalu) => evalu.evaluationId === evaluationId
    )

  const getFinalizedForms = useCallback(
    (evaluationId: string) => {
      return getFinalizedEvaluation(evaluationId)
    },
    [template]
  )

  const docTitle = useMemo(() => {
    if (!!templateName && templateName !== template?.name) {
      return `${club?.name} - ${templateName}`
    }
    return `${club?.name} - ${template?.name}`
  }, [template, templateName])

  useEffect(() => {
    if (template?.name) {
      setTemplateName(template.name)
    }
  }, [template])

  if (isLoading || isTemplateLoading) {
    return <div>Loading...</div>
  }

  if (template?.name && club.name) {
    document.title = docTitle
  }

  const getDateTimeString = (utcString: string) => {
    const date = new Date(utcString)
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }

  const updateRatingTemplate = async () => {
    await axios.patch('/', {
      name: templateName.trim(),
      id: templateId,
    })
  }

  const deleteRatingTemplate = async (id?: string) => {
    if (!id) {
      return
    }
    await axios.delete(`/${id}`)
    navigate('ClubManagement')
  }

  return (
    <ScreenWrapper>
      <Spacer h={24} />
      <GoBackButton
        text="Go back"
        onPress={goBack}
        portraitMode={screen.width < screen.height}
        style={{ marginLeft: 24 }}
      />
      <ContentCard style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ContentClubHeader
          title={docTitle}
          optionView={
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <S.ComposedCourseHeaderContainer
                style={{ alignItems: 'flex-start', marginTop: 2 }}
              >
                <span>Composed courses</span>
                <S.ComposedCourseHeaderItemContainer>
                  {coursesWithHoles?.map((course) => (
                    <S.ComposedCourseHeaderItem key={course.course.id}>
                      <span
                        style={{
                          lineHeight: '18px',
                        }}
                      >
                        {course.course.name}
                      </span>
                      <span
                        style={{
                          fontSize: '12px',
                          lineHeight: '18px',
                        }}
                      >
                        Source: {course.source}
                      </span>
                    </S.ComposedCourseHeaderItem>
                  ))}
                </S.ComposedCourseHeaderItemContainer>
              </S.ComposedCourseHeaderContainer>
            </div>
          }
          club={club}
        />
        <CompositeTemplateActions
          setTemplateName={(n: string) => setTemplateName(n)}
          template={template}
          updateRatingTemplate={updateRatingTemplate}
          deleteRatingTemplate={deleteRatingTemplate}
          templateName={templateName}
        />
      </ContentCard>

      <ContentCard>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ContentHeading>Generate Composite Course Forms</ContentHeading>

          <RoundedButton
            style={{ width: 300 }}
            title="Generate Forms"
            disabled={
              (!isRateable && isRateable !== undefined) ||
              branchLoading ||
              data?.courses.length === 0
            }
            loading={branchLoading}
            onPress={() =>
              dispatch({
                type: 'SET_MODAL',
                params: {
                  mode: 'createMixedEvaluation',
                  props: {
                    courses: coursesWithHoles,
                    template,
                    club: club,
                  },
                },
              })
            }
          />
        </div>
      </ContentCard>
      {template?.finalizedForms.length ? (
        template?.finalizedForms.length > 0 && (
          <ContentCard gridMode>
            {template?.finalizedForms
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((form) => (
                <CompositeFinalRatingItem key={form.id} form={form} />
              ))}
          </ContentCard>
        )
      ) : (
        <ContentCard>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span>No forms have been generated yet....</span>
          </div>
        </ContentCard>
      )}
    </ScreenWrapper>
  )
}

export default CompositeCoursePage

const CompositeTemplateActions: FunctionComponent<{
  template?: RatingTemplate
  updateRatingTemplate: () => Promise<void>
  deleteRatingTemplate: (id?: string) => void
  setTemplateName: (n: string) => void
  templateName: string
}> = ({
  template,
  updateRatingTemplate,
  deleteRatingTemplate,
  setTemplateName,
  templateName,
}) => {
  const [
    showDeleteEvaluationConfirmModal,
    setShowDeleteEvaluationConfirmModal,
  ] = React.useState(false)
  const [showEditInput, setShowEditInput] = React.useState(false)
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {showEditInput ? (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
          <Input
            defaultValue={templateName || template?.name}
            onChange={(e) => setTemplateName(e.target.value)}
          />
          <RoundedButton
            title=""
            style={{ alignSelf: 'end' }}
            icon={<FeatherIcon color="white" name="check" size={20} />}
            onPress={async () => {
              await updateRatingTemplate()
              setShowEditInput(false)
            }}
          />

          <RoundedButton
            title=""
            style={{
              alignSelf: 'end',
              backgroundColor: 'transparent',
              boxSizing: 'border-box',
              border: '1px solid black',
            }}
            icon={<FeatherIcon color="black" name="x" size={20} />}
            onPress={() => {
              setTemplateName(template?.name || '')
              setShowEditInput(false)
            }}
          />
        </div>
      ) : (
        <>
          <RoundedButton
            textStyle={{ color: 'black' }}
            style={{
              justifyContent: 'flex-start',
              backgroundColor: 'transparent',
              border: '1px solid black',
            }}
            title="Change Composite Course Name"
            icon={<FeatherIcon name="edit-2" size={16} color="black" />}
            onPress={() => setShowEditInput(true)}
          />
        </>
      )}
      <RoundedButton
        textStyle={{ color: 'red' }}
        style={{
          justifyContent: 'flex-start',
          backgroundColor: 'transparent',
          border: '1px solid red',
        }}
        title="Delete Composite Course"
        icon={<FeatherIcon name="trash" size={16} color="red" />}
        onPress={() => setShowDeleteEvaluationConfirmModal(true)}
      />
      <TwoButtonModal
        isVisible={showDeleteEvaluationConfirmModal}
        title="Are you sure you want to delete Composite Course?"
        description="You will not be able to download the currently generated forms"
        onPressRight={() => deleteRatingTemplate(template?.id)}
        onPressLeft={() => setShowDeleteEvaluationConfirmModal(false)}
        rightButtonText="Delete"
        leftButtonText="Cancel"
        destructive
      />
    </div>
  )
}
