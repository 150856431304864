import 'firebase/database'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

import getEnv from '@app/environments/getEnv'
import firebase from 'firebase/app'

import { config } from './config/FirebaseWebConfig'

const _env = getEnv()

firebase.initializeApp(config)

if (_env.USE_EMULATORS) {
  firebase.functions().useEmulator('localhost', 5001)
  firebase.database().useEmulator('localhost', 9000)
  firebase.firestore().useEmulator('localhost', 8080)
  firebase.auth().useEmulator('http://localhost:9099/')
  firebase.storage().useEmulator('localhost', 9199)
}

global._firebase = firebase

export default global._firebase
