import { color8, color10 } from '@app/ui/colors'
import styled from 'styled-components'

export const Heading = styled.h2`
  color: ${color8};
  font-weight: 300 !important;
  font-size: 26px;
  margin-bottom: 30px;
`

export const Heading2 = styled(Heading)`
  margin-bottom: 0;
`

export const HeadingSmall = styled.h4`
  font-size: 22px;
  font-weight: 300;
`

export const LightParagraph = styled.p`
  color: ${color10};
  font-weight: 300;
  font-size: 13px;
`

export const Paragraph = styled.p`
  color: #333;
  font-weight: 300;
  line-height: 1.3em;
  font-size: 15px;
`
