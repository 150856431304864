import * as S from './styled'

import { FiEdit, FiUser } from 'react-icons/fi'

import Avatar from '@app/components/atoms/Avatar'
import PersonalData from '../PersonalData/PersonalData'
import React from 'react'
import Spacer from '@app/components/atoms/Spacer'
import { useDispatch } from 'react-redux'
import useUser from '@common/hooks/useUser'

const InformationBox = () => {
  const user = useUser()

  const dispatch = useDispatch<any>()

  return (
    <S.container>
      <S.ProfileHeader>
        <Avatar
          useIndicator={false}
          userId={user?.id?.toString()}
          size={51}
          userIndicatorSize={6}
        />

        <Spacer width={18} />
        <S.NameContainer>
          <S.Name>
            {user?.forename} {user?.lastname}
          </S.Name>
        </S.NameContainer>
      </S.ProfileHeader>
      <Spacer height={36} />
      <S.ProfileInformation>
        <span>Personal Information</span>
        <FiEdit
          onClick={() => {
            dispatch({
              type: 'SET_MODAL',
              params: {
                props: {
                  email: user?.email,
                  firstname: user?.forename,
                  lastname: user?.lastname,
                  gender: user?.gender,
                  handicap: user?.handicap,
                  homeclub: user?.clubname,
                },
                mode: 'editProfile',
              },
            })
          }}
          style={{ width: '20px', height: '20px', cursor: 'pointer' }}
        />
      </S.ProfileInformation>
      <Spacer height={40} />
      <S.ProfileDetails>
        <PersonalData
          email={user?.email}
          firstname={user?.forename}
          lastname={user?.lastname}
          handicap={user?.handicap}
          homeclub={user?.clubname}
          golfId={user?.golfID}
        />
      </S.ProfileDetails>
    </S.container>
  )
}

export default InformationBox
