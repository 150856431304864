import {
  DropDown,
  DropDownIconContainer,
  DropDownTitleContainer,
  MenuOverlay,
} from './DropDownMenu.styles'
import { Motion, spring } from 'react-motion'
import React, { useEffect, useRef, useState } from 'react'
import {
  FaAngleUp,
  FaAngleDown,
  FaEllipsisH,
  FaEllipsisV,
} from 'react-icons/fa'

import Overlay from '@app/overlay/Overlay'
import { State } from '@app/state/types'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { Theme } from '../theme'
import { useSnackbar } from '@app/snackbar'

const DD_SPRING_SETTINGS = { stiffness: 70, damping: 20 }
const DD_SPRING_TO_VALUE = 10
const DD_OFFSET_Y = -60
const DD_DEFAULT_WIDTH = 100
const DD_CLDRAWER_OFFSET_X = 0
const DD_ICON_SIZE = 14

interface DropDownMenuProps {
  value?: string | number | undefined
  children?: React.ReactNode
  width?: number
  noArrow?: boolean
  renderValue?: () => JSX.Element
  zIndex?: number
  style?: React.CSSProperties
  backgroundColor?: string
  color?: string
  iconColor?: string
  relative?: boolean
  reference?: React.MutableRefObject<HTMLDivElement>
  rounded?: boolean
  marginLeft?: boolean
  marginRight?: boolean
  marginTop?: boolean
  tablet?: boolean
  visible?: boolean
  disabledState?: {
    disabled: boolean
    message: string
  }
  x?: number
  y?: number
  xAdj?: number
  yAdj?: number
  useShadow?: boolean
  isMenu?: boolean
}

const DropDownMenu = ({
  x = 0,
  y = 0,
  xAdj = 0,
  yAdj = 40,
  value = undefined,
  children,
  width = DD_DEFAULT_WIDTH,
  noArrow = false,
  renderValue,
  zIndex = 200,
  style,
  backgroundColor,
  color,
  iconColor,
  reference,
  relative,
  tablet = false,
  rounded = false,
  visible,
  disabledState,
  useShadow = true,
  isMenu = false,
}: DropDownMenuProps) => {
  const theme = useTheme() as Theme
  const [menuOpen, setMenuState] = useState<boolean>(false)
  const menuItemRef = reference ? reference : useRef<HTMLDivElement>(null)
  const [openSnackbar] = useSnackbar()
  const YValue = (xx: number) => (relative ? 0 : DD_OFFSET_Y + (xx + x) / 2)
  const motionParams = {
    x: spring(DD_SPRING_TO_VALUE, DD_SPRING_SETTINGS),
  }
  const bounds = menuItemRef.current?.getBoundingClientRect()
  const motionTransform = (xx: number) => `
    translate3d(0, ${YValue(xx + x)}px, 100px) 
  `

  const _x = bounds
    ? window.innerWidth - bounds.width - width - DD_CLDRAWER_OFFSET_X
    : 0

  useEffect(() => {
    if (visible !== undefined) {
      setMenuState(visible)
    }
  }, [visible])

  const handleOpen = () => {
    if (disabledState?.disabled) {
      return openSnackbar(disabledState?.message)
    }
    setMenuState(!menuOpen)
  }

  function getIsOpenIcon() {
    return !isMenu ? (
      <FaAngleUp
        size={DD_ICON_SIZE}
        color={iconColor ? iconColor : theme.colors.secondary}
      />
    ) : (
      <FaEllipsisH
        size={DD_ICON_SIZE}
        color={iconColor ? iconColor : theme.colors.secondary}
      />
    )
  }

  function getIsClosedIcon() {
    return !isMenu ? (
      <FaAngleDown
        size={DD_ICON_SIZE}
        color={iconColor ? iconColor : theme.colors.secondary}
      />
    ) : (
      <FaEllipsisV
        size={DD_ICON_SIZE}
        color={iconColor ? iconColor : theme.colors.secondary}
      />
    )
  }

  const val = renderValue ? renderValue() : value

  return (
    <div>
      {menuOpen && (
        <>
          <Overlay closeAction={() => setMenuState(false)} />
          <Motion defaultStyle={{ x: 0 }} style={motionParams}>
            {({ x }) => (
              <MenuOverlay
                tablet={tablet}
                transform={motionTransform(x)}
                x={x + _x}
                y={relative ? y + (bounds?.y ?? 0) : y + (bounds?.y ?? 0)}
                xAdj={xAdj}
                yAdj={yAdj}
                width={width}
                zIndex={zIndex}
                relative={relative}
              >
                {children}
              </MenuOverlay>
            )}
          </Motion>
        </>
      )}
      <DropDown
        ref={menuItemRef}
        rounded={rounded}
        onClick={handleOpen}
        style={style}
        backgroundColor={backgroundColor}
        color={color}
        useShadow={useShadow}
      >
        {val && <DropDownTitleContainer>{val}</DropDownTitleContainer>}
        <DropDownIconContainer noArrow={noArrow}>
          {!noArrow && (menuOpen ? getIsOpenIcon() : getIsClosedIcon())}
        </DropDownIconContainer>
      </DropDown>
    </div>
  )
}

export { DropDownMenu }
