/* eslint-disable no-case-declarations */
import {
  ADD_COLLAPSED_TEE,
  SET_COLLAPSED_TEES,
  SET_COLOR,
  SET_EVALUATION,
  SET_SELECTED_HOLE,
} from '@app/evaluation-core/actions/actionTypes'
import {
  EvaluationAction,
  EvaluationState,
  EvaluationUser,
  EvaluationUserView,
  TeamRole,
} from '@app/evaluation-core/types'

import initialState from './initialState'

export default function evaluation(
  state: EvaluationState = initialState,
  action: EvaluationAction
) {
  const getUser = (userId: string) =>
    state?.evaluation?.userId
      ? Object.values(state?.evaluation?.users).find(
          (user) => user.userId === userId
        )
      : { view: null, role: TeamRole.EVALUATOR, userId: '' }
  const user = getUser(action.userId as string)
  const view = {
    ...user?.view,
  } as EvaluationUserView

  switch (action.type) {
    case SET_EVALUATION:
      return action.value
    case SET_COLOR:
      if (!user) return state

      if (action.color) {
        view.color = action.color
      }

      if (!state.evaluation) return state

      state.evaluation.users[0] = { ...user, view }

      return { ...state }
    case SET_COLLAPSED_TEES:
      if (!user) return state

      if (action.tees) {
        view.collapsedTees = action.tees
      }

      if (!state.evaluation) return state

      state.evaluation.users[0] = { ...user, view }

      return { ...state }
    case ADD_COLLAPSED_TEE:
      if (!user) return state

      if (action.tees as number[]) {
        const _user = user as EvaluationUser
        view.collapsedTees = [
          ...(_user.view.collapsedTees as number[]),
          action.tee as number,
        ]
      }

      if (!state.evaluation) return state

      state.evaluation.users[0] = { ...user, view }

      return { ...state }
    case SET_SELECTED_HOLE:
      if (!user) return state
      if (action.hole) {
        view.hole = action.hole
      }

      if (action.tee !== undefined) {
        view.tee = action.tee
      }

      if (action.golfer !== undefined) {
        view.golfer = action.golfer
      }

      if (action.group !== undefined) {
        view.adjustment = action.group
      }

      if (action.shot !== undefined) {
        view.shot = action.shot
      }

      if (!state.evaluation) return state
      state.evaluation.users[action.userId as string] = {
        ...user,
        view,
      }

      return { ...state }
    default:
      return state
  }
}
