import theme from '@app/ui/theme'
import styled from 'styled-components'
import S from 'styled-components/native'

interface LeftTabMenuItemProps {
  active?: boolean
}

const TAB_MENU_ACTIVE_COLOR = 'rgba(255, 255, 255, 0.2)'

export const LeftTabMenuItem = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  line-height: ${theme.size[7]}px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background-color: ${(props: LeftTabMenuItemProps) =>
    props.active ? TAB_MENU_ACTIVE_COLOR : 'transparent'};
  &:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`

export const LeftTabMenuItemText = S.Text`
  font-size: ${theme.fontSize[2]}px;
  font-weight: ${theme.fontWeight[3]};
  line-height: ${theme.size[7]}px;
  cursor: pointer;
  color: ${theme.colors.white};
  flex: 1;
`

export const LeftTabMenuIcon = S.View`
  width: 70px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Indicator = S.View<{ collapsed?: boolean }>`
height: 100%;
width: 12px;
right: 0px;
background-color: ${theme.colors.secondary2};
${({ collapsed }) => collapsed && 'right: 186px;'}
transition: 0.3s ease;
`

export const TAB_MENU_ICON_STYLE = {
  color: '#C4C4C4',
  fontSize: '20px',
}
