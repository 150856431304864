import styled from 'styled-components'

export interface OverlayContainerProps {
  x?: number
  y?: number
  zIndex?: number
}

export const OverlayContainer = styled.div`
  background: rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  position: fixed;
  left: ${(props: OverlayContainerProps) => (props.x ? props.x : 0)}px;
  top: ${(props: OverlayContainerProps) => (props.y ? props.y : 0)}px;
  width: 100vw;
  height: 100%;
  z-index: ${(props: OverlayContainerProps) =>
    props.zIndex ? props.zIndex : 200};
`
