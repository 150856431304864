import { useSnackbar } from '@app/snackbar'
import { useEffect } from 'react'

const loadingErrorHook = (
  fetchingData: boolean,
  onTimeout: () => void,
  timeoutMs = 15000,
  errorSnackbarText = 'Something went wrong. Please try again. If the problem persists, please contact Mapping Industries.'
) => {
  const [openSnackbar] = useSnackbar()
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (fetchingData) {
      timeout = setTimeout(() => {
        openSnackbar(errorSnackbarText)
        onTimeout()
      }, timeoutMs)
    } else if (timeout) {
      clearTimeout(timeout)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [fetchingData])
}

export default loadingErrorHook
