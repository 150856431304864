import styled from 'styled-components'

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`

interface MarkerIconProps {
  color: string
}

export const MarkerIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background-color: ${(props: MarkerIconProps) => props.color};
  cursor: pointer;
`

export const StyledPopup = styled.div`
  background: white;
  color: #3f618c;
  font-weight: 400;
  padding: 4px;
  border-radius: 2px;
`
