import { getCourseSetting } from '@app/evaluation-core'
import { State } from '@app/state/types'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

type AdjustmentType = string | number | undefined
export default (
  key: string,
  maxValue?: number
): [AdjustmentType, (value: AdjustmentType) => void] => {
  const adjustments = useSelector((state: State) => state.adjustments)
  const courseSettings = adjustments.courseSettings

  const defaultValue = useMemo(
    () => getCourseSetting(key, adjustments),
    [courseSettings, adjustments]
  ) as AdjustmentType

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue)
    }
  }, [adjustments.adjustments, adjustments.courseSettings])

  const [value, setValue] = useState<AdjustmentType>(defaultValue)

  const handleSetValue = (value: AdjustmentType) => {
    if (typeof value === 'number') {
      if (maxValue && value > maxValue) {
        return setValue(maxValue)
      }
    }
    setValue(value)
  }

  return [value, handleSetValue]
}
