import { OverlaySubHeader, OverlaySubHeaderTab } from '../../index.styles'

import React from 'react'
import { setSelectedHole } from '@app/evaluation-core/actions/evaluationActions'
import { useDispatch } from 'react-redux'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import usePortraitMode from '@common/hooks/usePortrait'

type Props = {
  amount: number
}

const LandingZones = ({ amount }: Props) => {
  const dispatch = useDispatch<any>()
  const { view } = useEvaluationUser()
  const isPortrait = usePortraitMode()

  /** Landing Zones are shots without the shot to green, thus 1 should be removed from the length of the shots. */
  const landingZones = amount - 1

  if (landingZones === 0) return <></>

  const lzText = isPortrait ? 'LZ' : 'Landing Zone'

  return (
    <OverlaySubHeader>
      {new Array(landingZones).fill(true).map((_, lzIndex: number) => (
        <OverlaySubHeaderTab
          key={lzIndex}
          selected={view.shot === lzIndex}
          isPortrait={isPortrait}
          onClick={() => {
            dispatch(
              setSelectedHole(
                view?.hole,
                view.tee,
                view.golfer,
                view.adjustment,
                lzIndex
              )
            )
          }}
        >
          {lzText} {lzIndex + 1}
        </OverlaySubHeaderTab>
      ))}
    </OverlaySubHeader>
  )
}

export default LandingZones
