import React, { useState } from 'react'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import {
  AdminTitle,
  BorderlessDropDownMenuItem,
  DropDownMenuItem,
} from '@app/ui/dropdown-menu/DropDownMenu.styles'
import { FormExGenderBtn } from '@app/evaluation-settings-menu/HeaderArea.styles'
import { Eval } from '@app/evaluation-core/types'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import {
  EvaluationDoc,
  EvaluationDocWithForms,
} from '../CompositeCoursePage/CompositeCoursePage'
import { useSnackbar } from '@app/snackbar'
import { FaSync, FaInfoCircle } from 'react-icons/fa'

interface AdminEvaluationActionMenuProps {
  evaluation: any
  onEvaluationInfo: () => void
  onRegenerateForms: () => void
  onDeleteEvaluation: () => void
  onResetUnlockedHoles: () => void
  onResetRatingRevision: () => void
}

export const AdminEvaluationActionMenu = ({
  evaluation,
  onEvaluationInfo,
  onRegenerateForms,
  onDeleteEvaluation,
  onResetUnlockedHoles,
  onResetRatingRevision,
}: AdminEvaluationActionMenuProps) => {
  const [openSnackbar] = useSnackbar()
  const evalUser = useEvaluationUser()

  const [showResetHoleCancelModal, setShowResetHoleCancelModal] =
    useState(false)

  const closeResetHoleModal = () => setShowResetHoleCancelModal(false)
  const openResetHoleModal = () => setShowResetHoleCancelModal(true)

  const resetHoleFn = async () => {
    const realTimeEval = evaluation as Eval

    closeResetHoleModal()
  }

  function isEvaluationWithForms(
    evaluation: EvaluationDoc
  ): evaluation is EvaluationDocWithForms {
    const formsEval = evaluation as EvaluationDocWithForms
    return formsEval.finalizedForms !== undefined
  }

  function isEvaluationWithActualForms(
    evaluation: EvaluationDoc
  ): evaluation is EvaluationDocWithForms {
    const formsEval = evaluation as EvaluationDocWithForms
    return (
      formsEval.finalizedForms !== undefined &&
      formsEval.finalizedForms?.length > 1 - 1
    )
  }

  const isAuthenticatedSystemAdmin = evalUser?.isAdmin

  return (
    <>
      <DropDownMenu
        value={''}
        isMenu={true}
        width={250}
        relative
        xAdj={-150}
        useShadow={false}
      >
        {
          <BorderlessDropDownMenuItem
            onPress={async () => {
              openSnackbar(
                'This function has been temporarily disabled because of inconsistency with data until /Stoffe'
              )
            }}
          >
            {/*<RatingListInfoDropdown evaluation={evaluation} />*/}
            <FormExGenderBtn>
              <span>Detailed info</span>
              <div>
                <FaInfoCircle size={20} color={'#4190e0'} />
              </div>
            </FormExGenderBtn>
          </BorderlessDropDownMenuItem>
        }

        <>
          <AdminTitle>Admin functions</AdminTitle>

          <DropDownMenuItem disabled={true} onPress={onDeleteEvaluation}>
            <FormExGenderBtn>
              <span>Sync to firestore</span>
              <div>
                <FaSync size={20} />
              </div>
            </FormExGenderBtn>
          </DropDownMenuItem>

          <DropDownMenuItem
            disabled={!isAuthenticatedSystemAdmin && !evalUser?.isTeamLeader}
            onPress={onDeleteEvaluation}
          >
            <FormExGenderBtn>
              <span>Test holeids</span>
              <div>
                <FaSync size={20} />
              </div>
            </FormExGenderBtn>
          </DropDownMenuItem>

          <DropDownMenuItem
            disabled={!isAuthenticatedSystemAdmin && !evalUser?.isTeamLeader}
            onPress={onDeleteEvaluation}
          >
            <FormExGenderBtn>
              <span>Delete</span>
              <div>
                <FaSync size={20} />
              </div>
            </FormExGenderBtn>
          </DropDownMenuItem>
        </>
      </DropDownMenu>
    </>
  )
}
