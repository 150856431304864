import theme from '@app/ui/theme'
import S from 'styled-components/native'

export const TableValueContainer = S.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between
`

export const TableValueLabel = S.Text`
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
`

export const TableValueBox = S.View`
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  background: ${theme.colors.primary};
`

export const TableValueText = S.Text`
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.white};
`

export const TableValueSeparator = S.View`
  width: 100%;
  height: 1px;
  position: absolute;
  top: -16px;
  background: rgba(0,0,0,0.1);
`
