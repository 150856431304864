import theme from '@app/ui/theme'
import styled from 'styled-components'
import S from 'styled-components/native'

export const LoginWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 40px;
    text-wrap: wrap;
    display: block;
    width: 280px;
    line-height: 38px;
    margin: 40px;
  }
  & > div:nth-child(2) {
    background-color: ${theme.colors.white};
    width: 280px;
    box-shadow: 0 0px 50px 0 ${theme.colors.dark1};
  }
  & input {
    border: 1px solid ${theme.colors.light};
    padding: 10px;
    font-size: 14px;
    font-family: Inter, sans-serif;
    outline-color: #191919;
    margin: 10px;
    margin-bottom: 0;
    width: calc(100% - 20px);
  }
  & button {
    border: 0;
    padding: 10px;
    font-size: 14px;
    color: ${theme.colors.white};
    font-family: Inter, sans-serif;
    margin: 10px;
    width: calc(100% - 20px);
    background-color: #191919;
  }
  & button:active {
    background-color: rgba(0, 0, 0, 0.7);
  }
`

export const SubmitButton = S.TouchableOpacity`
    border: 0;
    padding: 10px;
    font-size: 14px;
    color: ${theme.colors.white};
    font-family: Inter, sans-serif;
    margin: 10px;
    width: calc(100% - 20px);
    background-color: #191919;
`

export const SubmitButtonText = S.Text`
    color: ${theme.colors.white};
    text-align: center;
`

export const LoginWrapperBottomContainer = S.View`
    margin-top: 10px;
    margin-bottom: 10px;
`

export const AlreadyHaveAccountLink = styled.a`
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  & > b {
    text-decoration: underline;
  }
`

export const GenderContainer = styled.div`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  outline-color: #191919;
  margin: 10px;
  margin-bottom: 0;
  width: calc(100% - 20px);
  border-radius: 8px;
  display: flex;
`

export const GenderButton = styled.div<{ selected?: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary2 : theme.colors.primary};
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:nth-child(2) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`
