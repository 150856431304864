import Spacer from '@app/components/atoms/Spacer'
import { FBREF_COURSE_EVALUATIONS } from '@app/evaluation-core/refs'
import { ClubManagementStackParamList } from '@app/navigation/CoursesStack'
import type { StackScreenProps } from '@react-navigation/stack'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useFirebaseConnect } from 'react-redux-firebase'
import * as S from './styled'
import ContentHeading from '@app/components/atoms/ContentHeading'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { useSnackbar } from '@app/snackbar'
import { AdminCombinedEvaluationsList } from './AdminCombinedEvaluationsList'
import useFilteredEvaluations, {
  EvalContainer,
} from '@common/hooks/useFilteredEvaluations'
import { InLineSpinner } from '@app/evaluation/components/Spinner'

type OwnProps = {}

type Props = OwnProps &
  StackScreenProps<ClubManagementStackParamList, 'ClubManagement'>

const AdminEvaluationsPage: FunctionComponent<Props> = ({ route }) => {
  useFirebaseConnect({ path: FBREF_COURSE_EVALUATIONS() })
  const { openSnackBar } = useSnackbar()
  const filteredEvals = useFilteredEvaluations()
  if (
    !filteredEvals ||
    filteredEvals == undefined ||
    filteredEvals?.isLoading
  ) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InLineSpinner size={32} />
      </div>
    )
  }

  return (
    <S.Wrapper>
      <Spacer height={24} />
      <S.ContentCard>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ContentHeading>RT+FS ratings</ContentHeading>
        </div>
        <Spacer height={4} />
        <span style={{ lineHeight: '15px' }}>
          Here you can see all ratings that exists in both places
        </span>
        <S.ActionsContainer>
          Filter
          <span style={{ maxWidth: '260px' }}>
            <RoundedButton
              title={`FS+RT`}
              onPress={() => {
                openSnackBar('Dont click everywhere :)')
              }}
            />
          </span>
          <span style={{ maxWidth: '260px' }}>
            <RoundedButton
              title={`FSOnly`}
              onPress={() => {
                openSnackBar('Dont click everywhere :)')
              }}
            />
          </span>
          <span style={{ maxWidth: '260px' }}>
            <RoundedButton
              title={`RTOnly`}
              onPress={() => {
                openSnackBar('Dont click everywhere :)')
              }}
            />
          </span>
          <span style={{ maxWidth: '260px' }}>
            <RoundedButton
              title={`In progress`}
              onPress={() => {
                openSnackBar('Dont click everywhere :)')
              }}
            />
          </span>{' '}
          <span style={{ maxWidth: '260px' }}>
            <RoundedButton
              title={`Finalized`}
              onPress={() => {
                openSnackBar('Dont click everywhere :)')
              }}
            />
          </span>{' '}
          <span style={{ maxWidth: '260px' }}>
            <RoundedButton
              title={`V1`}
              onPress={() => {
                openSnackBar('Dont click everywhere :)')
              }}
            />
          </span>
        </S.ActionsContainer>
        <S.ActionsContainer>
          {filteredEvals?.combined?.length} items
        </S.ActionsContainer>
        <Spacer height={24} />
        <AdminCombinedEvaluationsList
          isLoading={filteredEvals.isLoading!}
          evals={filteredEvals.combined as EvalContainer[]}
        />
      </S.ContentCard>

      <Spacer height={24} />
      <S.ContentCard>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ContentHeading>V1 ratings</ContentHeading>
        </div>
        <Spacer height={4} />
        <span style={{ lineHeight: '15px' }}>
          Here you can see all ratings that were in v1
        </span>
        <S.ActionsContainer>
          <span style={{ maxWidth: '260px' }}>
            <RoundedButton
              title={`Update Evaluation IDs`}
              onPress={() => {
                return
              }}
            />
          </span>
        </S.ActionsContainer>
        <S.ActionsContainer>
          {filteredEvals?.v1?.length} items
        </S.ActionsContainer>
        <Spacer height={24} />
        <AdminCombinedEvaluationsList
          isLoading={filteredEvals.isLoading}
          evals={filteredEvals?.v1 as EvalContainer[]}
        />
      </S.ContentCard>
    </S.Wrapper>
  )
}

export default AdminEvaluationsPage
