import theme from '@app/ui/theme'
import styled from 'styled-components'

interface EvalSideBarTabItemProps {
  sideBarCollapsed?: boolean
}

export const EvalSideBarTabItem = styled.div<EvalSideBarTabItemProps>`
  height: 39px;
  border-bottom: 1px solid ${theme.colors.light};
  background-color: ${theme.colors.white};

  & a {
    cursor: pointer;
    white-space: nowrap;
    background-color: ${theme.colors.white};
    color: #333;
    display: block;
    line-height: 40px;
    padding-left: 20px;
    padding-right: 10px;
    height: 100%;
    font-size: 16px;
    font-weight: 460;
    text-wrap: no-wrap;
    letter-spacing: 0px;
  }

  &:hover a {
  }

  &.active a {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  }
`
