import styled from 'styled-components'
import theme from '@app/ui/theme'

export const container = styled.div`
  padding: 20px 40px 20px 40px;
  width: 480px;
  height: 685px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  background-color: #f9f9f9; ;
`
export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ProfilePicture = styled.span`
  display: flex;
  width: 72px;
  height: 72px;
  background-color: #228558;
  border-radius: 500px;
  align-items: center;
  justify-content: center;
`
export const NameContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 85%;
`

export const Name = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`

export const ProfileInformation = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
`
export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`
