import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  margin: 0px;
  height: 100%;
  padding: 30px;
  background-color: #e6e6e6;
  box-sizing: border-box;
`

export const ContentCard = styled.div`
  box-shadow: 0 0px 4px 0 ${({ theme }) => theme.colors.dark1};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.4);
  padding: 30px;
  border-radius: 4px;
  overflow: scroll;
  height: 100%;
  box-sizing: border-box;
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const TableHead = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
  font-weight: bold;
`

export const TableRow = styled.tr`
  /* Your table row styles here */
`

export const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`

export const UsersTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const UsersTableHead = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
`

export const UsersTableCell = styled.td<{
  animationDuration: number
}>`
  border: 1px solid #ddd;
  padding: 8px;
  width: 80%;
  transition: background-color ${(props) => props.animationDuration}ms
    ease-in-out;
  &.highlighted {
    background-color: yellow;
  }
`

export const UsersTableCellCenter = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  width: 10%;
  text-align: center;
`

export const HighlightedTableCell = styled(UsersTableCellCenter)<{
  animationDuration: number
}>`
  transition: background-color ${(props) => props.animationDuration}ms
    ease-in-out;
  &.highlighted {
    background-color: yellow;
  }
`
