import { User } from '@app/auth/types'
import {
  CourseEvaluation,
  Eval,
  Evaluation,
  MiClub,
  MiProjectStatus,
} from '@app/evaluation-core/types'
import theme from '@app/ui/theme'
import { BranchStatus, LRCourse, MICourse } from '../../types/Clubs'

export enum Statuses {
  NOT_AVAILABLE = 0,
  PLANNED = 1,
  MEASURED = 2,
  REVIEW_BEFORE_RATING = 3,
  READY_FOR_RATING = 4,
  PREPARING = 5,
  IN_PROGRESS = 6,
  RATED = 7,
  OUTDATED = 8,
  UNKNOWN = 9,
}
export enum EvalStatus {
  'NOT_STARTED' = 0,
  'PREPARING' = 1,
  'IN_PROGRESS' = 2,
  'FINALIZED' = 3,
}

const convertToDate = (dateString: string) =>
  new Date(dateString).toLocaleDateString('sv-se')

export const COURSE_STATUS_DESCRIPTIONS = (branchStatus: BranchStatus) => ({
  [Statuses.UNKNOWN]: 'Unknown',
  [Statuses.NOT_AVAILABLE]: 'No map info, contact Mapping Industries',
  [Statuses.PLANNED]: 'Waiting for map information',
  [Statuses.MEASURED]: `Mapped: ${convertToDate(
    branchStatus?.hasTPDDate
  )} but not uploaded to LiveCR.`,
  [Statuses.REVIEW_BEFORE_RATING]: `Review in LiveCR and upload - ${convertToDate(
    branchStatus?.isBranchedLiveCRDate
  )}`,
  [Statuses.PREPARING]: 'Preparing for evaluation ',
  [Statuses.READY_FOR_RATING]: `${convertToDate(
    branchStatus?.firstTimeSyncedDate
  )}. Prepare and begin a evaluation.`,
  [Statuses.IN_PROGRESS]: `Last synced from LiveCR: ${convertToDate(
    branchStatus?.latestTimeSyncedDate
  )}`,
  [Statuses.OUTDATED]: `The CRData was last uploaded: ${branchStatus?.liveCRRevision}. Please reupload the data in LiveCR`,
})

export const EVALUATION_STATUS = [
  'Not Available',
  'Planned',
  'Measured',
  'Review before rating',
  'Ready for rating',
  'Preparing',
  'In progress',
  'Rated',
  'Outdated',
  'Unknown',
]

export const getCourseStatusColor = (status: number) => {
  switch (status) {
    case -1:
      return '#9B9B9B'
    case 0:
      return '#EE0000'
    case 1:
      return '#FF7A00'
    case 2:
      return '#FFC107'
    case 3:
      return '#1E90FF'
    case 4:
      return '#154f19'
    case 5:
      return '#2B9E33'
    case 6:
      return '#70FF75'
    case 7:
      return '#C62828'
    case 8:
      return '#5A337F'
    default:
      return theme.colors.primary
  }
}

export const getStatusName = (status: Statuses) => {
  if (status === Statuses.NOT_AVAILABLE) {
    return 'not_available'
  }
  if (status === Statuses.PLANNED) {
    return 'planned'
  }
  if (status === Statuses.PREPARING) {
    return 'preparing'
  }
  if (status === Statuses.MEASURED) {
    return 'measured'
  }
  if (status === Statuses.READY_FOR_RATING) {
    return 'ready_for_rating'
  }
  if (status === Statuses.REVIEW_BEFORE_RATING) {
    return 'in_progress'
  }
  if (status === Statuses.RATED) {
    return 'rated'
  }
  if (status === Statuses.OUTDATED) {
    return 'outdated'
  }
}

const getSvg = (
  color: string
) => `<svg width="16px" height="22px" viewBox="0 0 42 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<path
  d="M1.67363 24.8179L1.66949 24.7989L1.66471 24.7801C1.33329 23.4743 1.16667 22.1685 1.16667 20.6847C1.16667 9.95151 10.0598 1.16667 21 1.16667C31.9402 1.16667 40.8333 9.95151 40.8333 20.6847C40.8333 22.3406 40.6672 23.8413 40.4958 25.0312C39.9104 27.6273 38.4023 30.6525 36.3905 33.783C34.3789 36.9132 31.9152 40.0702 29.5133 42.8971C27.1137 45.7214 24.7892 48.2006 23.0639 49.9742C22.223 50.8386 21.5254 51.5344 21.0323 52.0195C20.5427 51.5342 19.8508 50.8389 19.0169 49.9748C17.3029 48.1987 14.9934 45.7145 12.6088 42.8796C10.222 40.0422 7.77325 36.8692 5.77265 33.715C3.76168 30.5444 2.25345 27.4694 1.67363 24.8179Z"
  fill="${color}"
  stroke="black"
  strokeWidth="2"
/>
</svg>`

export const courseMarkers = {
  unknown: getSvg(getCourseStatusColor(Statuses.UNKNOWN)),
  not_available: getSvg(getCourseStatusColor(Statuses.NOT_AVAILABLE)),
  planned: getSvg(getCourseStatusColor(Statuses.PLANNED)),
  preparing: getSvg(getCourseStatusColor(Statuses.PREPARING)),
  measured: getSvg(getCourseStatusColor(Statuses.MEASURED)),
  ready_for_rating: getSvg(getCourseStatusColor(Statuses.READY_FOR_RATING)),
  in_progress: getSvg(getCourseStatusColor(Statuses.REVIEW_BEFORE_RATING)),
  rated: getSvg(getCourseStatusColor(Statuses.RATED)),
  outdated: getSvg(getCourseStatusColor(Statuses.OUTDATED)),
}

export const getCourseSubText = (
  courseStatus: Statuses,
  branchStatus: BranchStatus
) => {
  return COURSE_STATUS_DESCRIPTIONS(branchStatus)[courseStatus]
}

export const courseHasActiveEvaluationWithUser = (
  evaluations: CourseEvaluation[] | undefined,
  user: User,
  courseId: string
) => {
  if (!evaluations) {
    return false
  }

  const evaluation = evaluations?.find(
    (courseEval) => +courseEval?.courseId === +courseId
  )

  if (!evaluation || !evaluation?.users) {
    return false
  }

  const users = Object.values(evaluation?.users)
  if (!user) {
    return false
  }
  return users.some(
    (evalUser) =>
      (evalUser?.userId && +evalUser?.userId === user?.id) ||
      evalUser.view.email === user.email
  )
}
export const getActiveEvaluation = (
  evaluations: CourseEvaluation[] | undefined,
  courseId: string
) => {
  if (!evaluations) {
    return null
  }

  const evaluation = evaluations?.find(
    (courseEval) => +courseEval?.courseId === +courseId
  )

  if (!evaluation || !evaluation?.users) {
    return null
  }

  if (evaluation?.users?.length === 0) {
    return null
  }
  return evaluation
}

export const evaluationButtonText = (status: EvalStatus) => {
  if (status === EvalStatus.IN_PROGRESS) {
    return 'Continue'
  }
  if (status === EvalStatus.FINALIZED) {
    return 'Create Revision'
  }
  return ''
}

enum MiBool {
  TRUE = '1',
  FALSE = '0',
}

export const getCourseStatus = (
  branchStatus: BranchStatus,
  projectStatus?: MiProjectStatus
): Statuses => {
  const projectStatusInt = Number(projectStatus)

  if (projectStatus && !Number.isNaN(projectStatusInt)) {
    return Number(projectStatus) as Statuses
  }

  const notAvailable =
    branchStatus?.isBranchedLiveCR === MiBool.FALSE &&
    branchStatus?.isBranched === MiBool.FALSE &&
    branchStatus?.hasTPD === MiBool.FALSE
  if (notAvailable) {
    return Statuses.NOT_AVAILABLE
  }

  const planned =
    branchStatus?.isBranchedLiveCR === MiBool.FALSE &&
    branchStatus?.isBranched === MiBool.TRUE &&
    branchStatus?.hasTPD === MiBool.FALSE
  if (planned) {
    return Statuses.PLANNED
  }

  const measured =
    branchStatus?.isBranchedLiveCR === MiBool.FALSE &&
    branchStatus?.isBranched === MiBool.TRUE &&
    branchStatus?.hasTPD === MiBool.TRUE
  if (measured) {
    return Statuses.MEASURED
  }

  const reviewBeforeRating =
    branchStatus?.isBranchedLiveCR === MiBool.TRUE &&
    branchStatus?.isBranched === MiBool.TRUE &&
    branchStatus?.hasTPD === MiBool.TRUE &&
    branchStatus?.isGreenMarked === MiBool.FALSE
  if (reviewBeforeRating) {
    return Statuses.REVIEW_BEFORE_RATING
  }

  const readyForRating =
    branchStatus?.isBranchedLiveCR === MiBool.TRUE &&
    branchStatus?.isBranched === MiBool.TRUE &&
    branchStatus?.hasTPD === MiBool.TRUE &&
    branchStatus?.isGreenMarked === MiBool.TRUE
  if (readyForRating) {
    return Statuses.READY_FOR_RATING
  }
  return Statuses.UNKNOWN
}

export const canEvaluate = (courseStatus: Statuses) =>
  courseStatus === Statuses.READY_FOR_RATING ||
  courseStatus === Statuses.IN_PROGRESS ||
  courseStatus === Statuses.RATED ||
  courseStatus === Statuses.PREPARING
