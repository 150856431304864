export const isColorDark = (RGB: string) => {
  if (RGB.length !== 6) {
    return false
  }
  const c_r = parseInt(RGB.substring(0, 0 + 2), 16)
  const c_g = parseInt(RGB.substring(2, 2 + 2), 16)
  const c_b = parseInt(RGB.substring(4, 4 + 2), 16)
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
  return brightness < 155
}
