import {
  CourseOptionSelect,
  CourseOptionSelectItem,
  CourseOptionSeparator,
} from './HeaderArea.styles'

import CourseOption from '@app/components/molecules/CourseOption'
import { CourseOptionView } from './HeaderArea.styles'
import React, { useState } from 'react'
import { State } from '@app/state/types'
import { saveCourseSettings, setCourseSetting } from '@app/evaluation-core'
import useCourseSetting from '@common/hooks/useCourseSetting'
import useCourseSettingWithConversion from '@common/hooks/useCourseSettingWithConversion'
import useEvaluation from '@common/hooks/useEvaluation'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { useSelector } from 'react-redux'
import useUser from '@common/hooks/useUser'
import useReadOnly from '@app/evaluation/hooks/useReadOnly'
import * as S from './CourseSettings.styles'
import { Measurement } from '@app/evaluation-core/types'
import Spacer from '@app/components/atoms/Spacer'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { EvalStatus } from '@app/courses/domain/ratingStatus'

interface CourseSettingsProps {
  withValidationLabels?: boolean
  interactive?: boolean
  inModal?: boolean
  onClose?: () => Promise<void>
}

const CourseSettings = ({
  withValidationLabels = false,
  interactive = true,
  inModal = false,
  onClose,
}: CourseSettingsProps) => {
  const { currentId, evaluation } = useEvaluation()
  const { view, isAdmin } = useEvaluationUser()
  const courseSettings = useSelector(
    (state: State) => state.adjustments.courseSettings
  )
  const { id: userId } = useUser()
  const [meas, setMeas] = useState<'imperial' | 'metric'>('imperial')

  const [
    roughHeight,
    setRoughHeight,
    setFinalRoughHeight,
    roughHeightRef,
    roughImerial,
  ] = useCourseSettingWithConversion('rough_height', meas)
  const [wind9, setWind9, setFinalWind9, wind9Ref, wind9Imperial] =
    useCourseSettingWithConversion('wind_1-9', meas)
  const [wind18, setWind18, setFinalWind18, wind18Ref, wind18Imperial] =
    useCourseSettingWithConversion('wind_10-18', meas)
  const [intermediateCut, setIntermediateCut] =
    useCourseSetting('intermediate_cut')
  const [stimpFeet, setStimpFeet] = useCourseSetting('stimp_feet')
  const [stimpInches, setStimpInches] = useCourseSetting('stimp_inches', 11)
  const [
    bunkerDepthDefault,
    setBunkerDepthDefault,
    setFinalBunkerDepthDefault,
    bunkDepthRef,
    bunkDepthImperial,
  ] = useCourseSettingWithConversion('bunk_depth_default', meas)
  const [grass_type, setGrass_Type] = useCourseSetting('grasstype')

  if (!currentId) return null

  if (!evaluation || !userId || !view) return null

  const canEdit = evaluation?.status !== EvalStatus.FINALIZED && isAdmin

  const getMeasurementText = (
    usName: string,
    metricName: string,
    meas: 'imperial' | 'metric'
  ): string => (meas === 'imperial' ? usName : metricName)

  function capitalize(str: string): string {
    if (!str) {
      return ''
    }
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const saveChanges = async () => {
    await saveCourseSettings(
      {
        rough_height: roughImerial,
        'wind_1-9': wind9Imperial,
        'wind_10-18': wind18Imperial,
        bunk_depth_default: bunkDepthImperial,
        intermediate_cut: intermediateCut,
        stimp_feet: stimpFeet,
        stimp_inches: stimpInches,
      },
      currentId
    )
    if (onClose) {
      await onClose()
    }
  }

  return (
    <>
      <CourseOptionView>
        <div>
          <span>Measurement</span>
          <CourseOptionSelect disabled={!canEdit}>
            <CourseOptionSelectItem
              disabled={!canEdit}
              selected={meas === 'imperial'}
              onPress={() => setMeas('imperial')}
            >
              Imperial
            </CourseOptionSelectItem>
            <CourseOptionSelectItem
              disabled={!canEdit}
              selected={meas === 'metric'}
              onPress={() => setMeas('metric')}
            >
              Metric
            </CourseOptionSelectItem>
          </CourseOptionSelect>
        </div>
      </CourseOptionView>
      <CourseOptionView>
        <div>
          <span>Grass Type</span>
          <CourseOptionSelect disabled={!canEdit}>
            {interactive ? (
              <>
                <CourseOptionSelectItem
                  disabled={!canEdit}
                  selected={courseSettings ? grass_type === 'warm' : false}
                  onPress={() =>
                    setCourseSetting('grasstype', currentId, 'warm')
                  }
                >
                  Warm
                </CourseOptionSelectItem>
                <CourseOptionSelectItem
                  disabled={!canEdit}
                  selected={courseSettings ? grass_type === 'cool' : true}
                  onPress={() =>
                    setCourseSetting('grasstype', currentId, 'cool')
                  }
                >
                  Cool
                </CourseOptionSelectItem>
              </>
            ) : (
              <S.SettingValueText>
                {capitalize(grass_type as string)}
              </S.SettingValueText>
            )}
          </CourseOptionSelect>
        </div>
      </CourseOptionView>

      <CourseOption
        title={'Rough height'}
        getMeasurementText={getMeasurementText}
        measurementTextArgs={['in', 'mm']}
        inputRef={roughHeightRef as any}
        defaultValue={roughHeight}
        onChangeSetValue={setRoughHeight}
        meas={meas}
        disabled={!canEdit}
        interactive={interactive}
      />
      <CourseOptionSeparator />
      <CourseOption
        title="Wind"
        holeInfo="Hole 1-9"
        getMeasurementText={getMeasurementText}
        measurementTextArgs={['mph', 'm/s']}
        inputRef={wind9Ref as any}
        defaultValue={wind9}
        onChangeSetValue={setWind9}
        disabled={!canEdit}
        meas={meas}
        interactive={interactive}
      />
      {evaluation.holes.length !== 9 && (
        <CourseOption
          title="Wind"
          holeInfo="Hole 10-18"
          getMeasurementText={getMeasurementText}
          measurementTextArgs={['mph', 'm/s']}
          inputRef={wind18Ref as any}
          defaultValue={wind18}
          onChangeSetValue={setWind18}
          disabled={!canEdit}
          meas={meas}
          interactive={interactive}
        />
      )}
      <CourseOptionSeparator />
      <CourseOption
        title="Default Bunker Depth"
        getMeasurementText={getMeasurementText}
        measurementTextArgs={['ft', 'm']}
        inputRef={bunkDepthRef as any}
        defaultValue={bunkerDepthDefault}
        onChangeSetValue={setBunkerDepthDefault}
        disabled={!canEdit}
        meas={meas}
        interactive={interactive}
      />
      <CourseOptionView>
        <div>
          <span>Intermediate cut width (yards)</span>
          {interactive ? (
            <input
              type="number"
              value={intermediateCut}
              onChange={(event) => setIntermediateCut(+event.target?.value)}
              onFocus={(e) => e.target.select()}
              disabled={!canEdit}
            />
          ) : (
            <S.SettingValueText>{intermediateCut} yards</S.SettingValueText>
          )}
        </div>
      </CourseOptionView>
      <CourseOptionView>
        <div>
          <span>Stimp</span>
          <div>
            {interactive ? (
              <>
                <input
                  type="number"
                  value={stimpFeet}
                  onChange={(event) => setStimpFeet(+event.target?.value)}
                  onFocus={(e) => e.target.select()}
                  disabled={!canEdit}
                />
                &nbsp; ft &nbsp;
                <input
                  type="number"
                  value={stimpInches}
                  onChange={(event) => setStimpInches(+event.target?.value)}
                  onFocus={(e) => e.target.select()}
                  disabled={!canEdit}
                />
                &nbsp; in
              </>
            ) : (
              <S.SettingValueText>
                {stimpFeet} ft {stimpInches} in
              </S.SettingValueText>
            )}
          </div>
        </div>
      </CourseOptionView>

      {inModal && (
        <>
          <Spacer height={16} />
          <RoundedButton title="Save Changes" onPress={saveChanges} />
        </>
      )}
    </>
  )
}

export { CourseSettings }
