import { DefaultTheme } from 'styled-components'
import { button, ThemeButton } from './button'
import * as colors from './colors'
import { fontSize, fontWeight } from './fonts'
import { size, space } from './space'

export interface Theme {
  colors: {
    primary: string
    secondary: string
    secondary2: string
    light: string
    light2: string
    white: string
    black: string
    dark1: string
    dark2: string
    primary2: string
    primary3: string
    updated: string
    saved: string
    yellow: string
    warning: string
    color13: string
    dark: string
    background: string
    prevField: string
    newField: string
    reject: string
    accept: string
    disabled: string
    [key: string]: string
  }
  fontSize: number[]
  fontWeight: number[]
  space: number[]
  size: number[]
  button: ThemeButton
  borderRadius: number
  baseline: number
}

const theme: Theme = {
  colors,
  fontSize,
  fontWeight,
  space,
  size,
  button,
  baseline: 8,
  borderRadius: 5,
}

export default theme
