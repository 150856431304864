import styled from 'styled-components'
import theme from '@app/ui/theme'

export const Data = styled.div`
  font-weight: 700;
  font-size: 16px;
  & > span {
    font-weight: 400;
  }
`
