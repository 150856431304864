import React, { useState, useEffect } from 'react'
import theme from '@app/ui/theme'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import sv from 'date-fns/locale/sv'
import { ButtonProps } from 'react-native'
import * as S from './styled'
import { useAxios } from '@common/hooks/useAxios'
import {
  ProjectCourse,
  ProjectHole,
  ProjectTee,
  ProjectTeeHole,
} from '../../../types/Project'
import { shadeColor } from '@app/evaluation-core/evaluation'
import ScorecardTotalRow from './ScorecardTotalRow'
import ScorecardRow from './ScorecardRow'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import { DropDownMenuItem } from '@app/ui/dropdown-menu/DropDownMenu.styles'
import {
  CourseOptionSelect,
  CourseOptionSelectItem,
} from '@app/evaluation-settings-menu/HeaderArea.styles'

registerLocale('sv', sv)

type Props = { courseId?: number; projectCourse?: Partial<ProjectCourse> }

const metersInOneYard = 0.9144

export const convertToUnits = (length: number, units): number => {
  return units === 'imperial'
    ? Math.round(Math.round(length) / metersInOneYard)
    : Math.round(length)
}

export const parseColor = (color: string) => {
  if (color.startsWith('#') && color.length === 9)
    return `#${color.substring(3)}`

  return color
}

const CourseScorecard: React.FC<Props> = ({ courseId, projectCourse }) => {
  if (
    (!courseId || courseId === null) &&
    (!projectCourse || projectCourse === null)
  )
    return <>Loading</>

  const axios = useAxios('/courses')
  const [course, setCourse] = useState<Partial<ProjectCourse>>()

  const refreshScorecard = async (courseId) => {
    setCourse(await axios.post(`/scorecard/${courseId}`))
  }

  useEffect(() => {
    if (projectCourse) setCourse(projectCourse)
    else if (courseId) {
      refreshScorecard(courseId)
    }
  }, [courseId, projectCourse])

  const editMode = false
  const developerMode = false
  const [units, setUnits] = useState<'metric' | 'imperial'>('metric') // metric/imperial

  return (
    <div>
      <S.ModalTitleContainer>
        <S.ModalTitle>{projectCourse?.name}</S.ModalTitle>
        <div>
          <CourseOptionSelect>
            <CourseOptionSelectItem
              selected={units === 'imperial'}
              onPress={() => setUnits('imperial')}
            >
              <span>Imperial</span>
            </CourseOptionSelectItem>
            <CourseOptionSelectItem
              selected={units === 'metric'}
              onPress={() => setUnits('metric')}
            >
              <span>Metric</span>
            </CourseOptionSelectItem>
          </CourseOptionSelect>
        </div>
      </S.ModalTitleContainer>
      <table className="table-auto" style={{ borderSpacing: 0 }}>
        <thead style={{ fontWeight: 600 }}>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            {course?.tees &&
              course?.tees.map((tee: ProjectTee) => (
                <th key={`tee-head-${tee.id}`} colSpan={3}>
                  <div
                    className="statusColor"
                    style={{
                      display: 'inline-block',
                      backgroundColor: parseColor(tee.color),
                      border: `1px solid ${shadeColor(tee.color, -24)}`,
                      height: 24,
                      width: 24,
                      borderRadius: 50,
                      verticalAlign: 'middle',
                      marginRight: 8,
                      marginTop: 6,
                      marginBottom: 6,
                    }}
                  />
                  {tee.name}
                </th>
              ))}
          </tr>
          <tr>
            <S.TitleTableCell style={{ verticalAlign: 'bottom' }}>
              Hole
            </S.TitleTableCell>
            <S.TitleTableCell style={{ verticalAlign: 'bottom' }}>
              Par
            </S.TitleTableCell>
            <S.TitleTableCell style={{ verticalAlign: 'bottom' }}>
              Hcp
            </S.TitleTableCell>
            {course?.tees &&
              course?.tees.map((tee: ProjectTee) => (
                <>
                  <S.RatedTableCell
                    style={{ height: 64, verticalAlign: 'bottom' }}
                  >
                    <S.LengthTitle>Rated</S.LengthTitle>
                  </S.RatedTableCell>
                  <S.FixedTableCell
                    style={{ height: 64, verticalAlign: 'bottom' }}
                  >
                    <S.LengthTitle>Fixed</S.LengthTitle>
                  </S.FixedTableCell>
                  <S.ScorecardTableCell
                    style={{ height: 64, verticalAlign: 'bottom' }}
                  >
                    <S.LengthTitle>Defined</S.LengthTitle>
                  </S.ScorecardTableCell>
                </>
              ))}
          </tr>
        </thead>
        <tbody style={{ fontWeight: 300 }}>
          {course?.holes &&
            course?.holes.map((hole: ProjectHole) => {
              return (
                <>
                  <ScorecardRow editMode={editMode} hole={hole} units={units} />

                  {Number(hole.courseHoleNumber) === 9 && (
                    <ScorecardTotalRow
                      course={course}
                      editMode={editMode}
                      hole={hole}
                      projectCourse={projectCourse}
                      units={units}
                      sumType="out"
                      startHole={1}
                      endHole={9}
                    />
                  )}
                  {Number(hole.courseHoleNumber) > 9 &&
                    Number(hole.courseHoleNumber) === course?.holes?.length && (
                      <ScorecardTotalRow
                        course={course}
                        editMode={editMode}
                        hole={hole}
                        projectCourse={projectCourse}
                        units={units}
                        sumType="in"
                        startHole={10}
                        endHole={18}
                      />
                    )}
                  {Number(hole.courseHoleNumber) > 9 &&
                    Number(hole.courseHoleNumber) === course?.holes?.length && (
                      <ScorecardTotalRow
                        course={course}
                        editMode={editMode}
                        hole={hole}
                        projectCourse={projectCourse}
                        units={units}
                        sumType="out"
                        startHole={1}
                        endHole={9}
                      />
                    )}
                  {Number(hole.courseHoleNumber) === course?.holes?.length && (
                    <ScorecardTotalRow
                      course={course}
                      editMode={editMode}
                      hole={hole}
                      projectCourse={projectCourse}
                      units={units}
                      sumType="tot"
                      startHole={1}
                      endHole={course?.holes?.length}
                    />
                  )}
                </>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default CourseScorecard
