import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0px;
  height: 100%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.background};
  box-sizing: border-box;
`

export const ContentCard = styled.div`
  box-shadow: 0 0px 4px 0 ${({ theme }) => theme.colors.dark1};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  border-radius: 4px;
  overflow: scroll;
  height: 100%;
  box-sizing: border-box;
`

export const ChatWindow = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f1f0f0;
  padding: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100%;
  box-sizing: border-box;
  overflow: scroll;
`

export const UserImage = styled.img`
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  flex-basis: 40px;
`
export const UserImageBackupContainer = styled.div`
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 40px;
`
export const Initials = styled.span`
  color: white;
  font-weight: 600;
  font-size: 18px;
`

export const Message = styled.div<{ isUser: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  justify-content: ${({ isUser }) => (isUser ? 'flex-start' : 'flex-end')};
`

export const MessageContainer = styled.div<{ isUser: boolean }>`
  padding: 8px 4px 12px 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: ${({ isUser }) => (isUser ? 'row' : 'row-reverse')};
  align-items: center;
  background-color: ${({ isUser, theme }) =>
    isUser ? theme.colors.primary : theme.colors.secondary2};
  position: relative;
  width: 50%;
  box-sizing: border-box;
`

export const ChatText = styled.p`
  color: white;
  white-space: pre-wrap;
  word-break: break-word;
  flex-grow: 1;
  flex: 1;
`

export const NameText = styled.span`
  color: #cecece;
  font-size: 10px;
  position: absolute;
  bottom: 4px;
`

export const InputField = styled.input`
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
  max-width: 600px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary2};
    outline: ${({ theme }) => theme.colors.secondary2};
  }
`

export const SendButton = styled.button`
  height: 40px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.secondary2};
  color: white;
  font-size: 16px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
`

export const ClearButton = styled.button`
  max-width: 400px;
  width: 50%;
  margin-left: auto;
  height: 40px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.warning};
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
`

export const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-end;
`
