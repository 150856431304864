import React, { FunctionComponent } from 'react'
import Field from '../../atoms/Field'
import { AcceptReject } from '../UpdateCard/UpdateCard'
import * as S from './styled'

type Props = {
  tee: string
  player: string
  adjustment: string
  keyName: string
  prevValue: string
  newValue: string
  onPressChangeStatus: (acceptReject: AcceptReject) => void
  pressed: boolean
  portraitMode?: boolean
}

const UpdateCardRow: FunctionComponent<Props> = ({
  tee,
  player,
  adjustment,
  keyName,
  prevValue,
  newValue,
  onPressChangeStatus,
  pressed,
  portraitMode,
}) => {
  if (pressed) {
    return null
  }

  const getAcronym = (str: string) => {
    const matches = str.replace(/[a-z]/g, '')
    if (matches) {
      return matches
    }
    return str
  }

  const maxPortaitModeLenght = (str: string) => {
    //if string is more than 17 characters, it will be cut
    if (str.length > 16) {
      return str.slice(0, 16) + '...'
    }
    return str
  }

  return (
    <S.LowerContainer>
      <S.ValueAndButtonContainer>
        {tee && <Field value={tee} variant="outlined" description="Tee" />}
        {player && (
          <Field value={player} variant="outlined" description="Player" />
        )}
        <Field
          value={portraitMode ? maxPortaitModeLenght(adjustment) : adjustment}
          variant="outlined"
          description="Adjustment"
          width={140}
        />
        <Field
          flex
          value={portraitMode ? getAcronym(keyName) : keyName}
          variant="outlined"
          description="Key"
          width={180}
        />
        <Field
          value={prevValue}
          variant="prev"
          description="Previous Value"
          width={90}
        />
        <Field
          value={newValue}
          variant="new"
          description="Update Value"
          width={90}
        />
        <div style={{ width: 110 }} />
        {/* <AcceptRejectCheckbox
          onPress={() => onPressChangeStatus(AcceptReject.REJECTED)}
          text="Test"
          reject
        />
        <AcceptRejectCheckbox
          onPress={() => onPressChangeStatus(AcceptReject.RESOLVED)}
          text="Test"
          accept
        /> */}
      </S.ValueAndButtonContainer>
    </S.LowerContainer>
  )
}

export default UpdateCardRow
