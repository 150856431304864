import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import React from 'react'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { getPlayerShots } from '@common/hooks/getPlayerShots'

const CROSSING_FACTORS = (): AdmFactorGroup[] => [
  {
    factors: [
      {
        label: 'C*',
        itemKey: 'cross_carry',
        format: 'shot',
        shouldRender: (adjustments, view) => {
          const shots = getPlayerShots(view.golfer)
          return view.shot === shots - 1
        },
        items: [
          {
            value: 1,
            text: `If the CARRY over a Crossing Obstacle closely borders the front of the green.`,
          },
        ],
      },
      {
        label: 'P*',
        itemKey: 'cross_percentage',

        format: 'shot',
        factorText: `If a Crossing Obstacle is narrow, only partial or can be played from some of the time, consider using an appropriate PERCENTAGE of the table value to determine the shot rating value (25%, 50% or 75%).`,
        items: [
          {
            value: '100%',
          },
          {
            value: '75%',
          },
          {
            value: '50%',
          },
          {
            value: '25%',
          },
        ],
      },
      // {
      //   label: "",
      //   items: [],
      //   customWidget: () => (
      //     <TwoWidget
      //       selectedLandingZone={view.shot}
      //       holeTotalValues={holeTotalValues}
      //     />
      //   ),
      // },
      {
        label: '2*',
        itemKey: 'cross_two',
        format: 'shot',
        showOr: true,
        disabled: true,
        items: [
          {
            value: 1,
            disabled: true,
            text: `If a Crossing Obstacle comes into play on two (2) or more shots; each rating must be 5 or greater (table value plus adjustments). Adjustment is made to the highest of the separate shot evaluations. Add all values of 5 or greater; if they total 11 or less, +1; if they total 12 or more, +2.`,
          },
          {
            value: 2,
            disabled: true,
            text: '',
          },
        ],
      },
    ],
  },
]

export const CrossingObstacles = () => {
  const adjustment = useAdjustment()
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  if (!evaluation || !adjustment) return null

  return (
    <Adjustment
      useLz={true}
      factors={CROSSING_FACTORS()}
      parameters={[
        {
          label: 'Shot Length to Carry',
          adjustmentKey: 'crossingDistance',
          format: 'shot',
          stepperValues: [1, 5, 10, 50],
          stepDefault: 10,
        },
        {
          label: 'Carry Safely Distance',
          adjustmentKey: 'cross_safely_carry',
          format: 'shot',
          stepDefault: 10,
          minValue: 10,
          stepperValues: [10],
        },
        {
          label: 'Player Shot Length to Carry Safely',
          adjustmentKey: 'cross_shot_length_to_carry',
          format: 'shot',
          inputDisabled: true,
        },
        {
          type: 'table-value',
          label: 'Crossing Obstacle Rating',
          inputDisabled: true,
          adjustmentKey: 'cross_rating',
          format: 'shot',
        },
      ]}
    />
  )
}
