import React, { FunctionComponent } from 'react'
import * as S from './styled'

type Props = {
  title: string
  selected: boolean
  navTitle: string
  onPress: (name: string) => void
}

const NavigationButton: FunctionComponent<Props> = ({
  title,
  selected,
  navTitle,
  onPress,
}) => {
  return (
    <S.Container onClick={() => onPress(navTitle)}>
      <S.Header selected={selected}>{title}</S.Header>
      <br></br>
      <S.UnderlineContainer>
        {selected ? <S.Underline /> : <S.SelectedUnderline />}
      </S.UnderlineContainer>
    </S.Container>
  )
}

export default NavigationButton
