import theme from '@app/ui/theme'
import styled from 'styled-components'

export const OuterContainer = styled.div`
  width: 455px;
  border-radius: 5px;
  background-color: ${theme.colors.white};
  z-index: 10;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow-y: scroll;
  @media (max-width: 820px) {
    width: 100%;
  }
`
export const HeaderContainer = styled.div`
  border-bottom: 1px solid #cecece;
`

export const HeaderText = styled.span`
  font-size: 24px;
  font-weight: 500;
  margin-left: 40px;
`
export const SelectorText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 24x;
  font-weight: 500;
  margin-bottom: 6px;
`

export const MiddleContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
`

export const ContentContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
`

export const HoleContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: calc(33.3333% - 16px);
  margin: 8px;
  @media (max-width: 820px) {
    max-width: calc(25% - 16px);
  }
`

export const BottomContainer = styled.div`
  margin-left: 192px;
  margin-right: 40px;
`
