import * as S from './styled'

import React, { FunctionComponent, useState } from 'react'
import {
  setLiveCRAdjustment,
  setLiveCRNotification,
} from '@app/evaluation-core'

import AcceptRejectButton from '../../atoms/AcceptRejectButton/AcceptRejectButton'
import { EvaluationUserView } from '@app/evaluation-core/types'
import UpdateCardRow from '../UpdateCardRow'
import moment from 'moment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

type Props = {
  items: any
  holeObj: any
  time: number
  portraitMode?: boolean
  acceptRejectOnPress: (
    view: EvaluationUserView,
    evaluationId: string,
    acceptReject: AcceptReject
  ) => void
}

export enum AcceptReject {
  REJECTED = 1,
  RESOLVED = 2,
}

const UpdateCard: FunctionComponent<Props> = ({
  items,
  time,
  holeObj,
  portraitMode,
  acceptRejectOnPress,
}) => {
  const { currentId, evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  const [pressed, setPressed] = useState<any[]>([])
  const [buttonPressed, setButtonPressed] = useState<boolean>(false)

  const blacklist = [
    'Fairway_UsedWidthScratch',
    'Fairway_UsedWidthBogey',
    'Trees_ShotLengthScratch',
    'Trees_ShotLengthBogey',
    'WH_ShotLengthScratch',
    'WH_ShotLengthBogey',
  ]

  const titleTime = moment(time).format('DD/MM H:m').toString()

  const onPressChangeStatus =
    (item: any, key) => async (acceptReject: AcceptReject) => {
      setPressed((oldArray) => [...oldArray, key])
      await setLiveCRNotification(
        acceptReject,
        item,
        view?.hole,
        currentId,
        holeObj
      )
      if (acceptReject === AcceptReject.RESOLVED) {
        await setLiveCRAdjustment(item, view, currentId)
      }
    }

  return (
    <S.OuterContainer portraitMode={portraitMode}>
      <S.UpperContainer>
        <S.TitleText>{titleTime}</S.TitleText>
        <S.AcceptOrDeclineAllContainer>
          <AcceptRejectButton
            pressed={buttonPressed}
            onPress={() => {
              setButtonPressed(true)
              acceptRejectOnPress(
                view,
                currentId as string,
                AcceptReject.REJECTED
              )
            }}
            text="Reject all"
            reject
          />
          <AcceptRejectButton
            pressed={buttonPressed}
            onPress={() => {
              setButtonPressed(true)
              acceptRejectOnPress(
                view,
                currentId as string,
                AcceptReject.RESOLVED
              )
            }}
            text="Accept all"
            accept
          />
        </S.AcceptOrDeclineAllContainer>
      </S.UpperContainer>
      {items?.map((item, index, array) => {
        const { adjustment, key, oldValue, newValue, context } = item?.data
        const keyName = key.split('_HOLE')[0]
        const tee = context.find((contextItem) => contextItem.key === 'tee')
        const teeName =
          evaluation.holes[view?.hole].tees[tee?.value]?.name || ''
        const player = context.find(
          (contextItem) => contextItem.key === 'player'
        )

        if (blacklist.includes(keyName)) {
          return null
        }
        if (index !== array.length - 1) {
          const alreadyRendered = array[index + 1].data.key.includes(keyName)
          if (alreadyRendered) {
            return null
          }
        }

        const onPress = onPressChangeStatus(item, key)
        return (
          <UpdateCardRow
            key={`update-card-row-${index}`}
            tee={teeName}
            player={player?.value}
            adjustment={adjustment}
            keyName={keyName}
            prevValue={oldValue}
            newValue={newValue}
            onPressChangeStatus={onPress}
            pressed={pressed.includes(key)}
            portraitMode={portraitMode}
          />
        )
      })}
    </S.OuterContainer>
  )
}

export default UpdateCard
