export type ThemeButton = {
  borderRadius: number
  size: {
    sm: number
    md: number
    lg: number
  }
}

export const button: ThemeButton = {
  borderRadius: 25,
  size: {
    sm: 40,
    md: 40,
    lg: 40,
  },
}
