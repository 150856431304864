import S from 'styled-components'
import _S from 'styled-components'

import theme from './theme'

export const AdjustmentInput = S.input<{
  isReadonly?: boolean
  disabled?: boolean
}>`
    width: 60px;
    height: 36px;
    border: 1px solid ${({ isReadonly, disabled }) =>
      isReadonly ? theme.colors.light3 : theme.colors.light4};;
    background-color: ${({ isReadonly, disabled }) =>
      isReadonly ? theme.colors.light4 : theme.colors.light3};
    border-radius: ${theme.borderRadius}px;
    outline: none;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    font-family: "Proxima Nova";
    align-self: flex-end;
    &:disabled {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        opacity: 1;
      }
    cursor: ${({ isReadonly, disabled }) => {
      if (isReadonly || disabled) return 'default'
      return 'pointer'
    }};
    
`
