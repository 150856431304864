import theme from '@app/ui/theme'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { ButtonProperties } from 'react-native'
import styled, { keyframes, css } from 'styled-components'
import S from 'styled-components/native'

interface ButtonContainerProps {
  width?: number | string
  animateError?: boolean
  disabled?: boolean
  isLoading?: boolean
  isDefault?: boolean
}

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
`

export const ButtonContainer = styled.div<ButtonContainerProps>`
  padding-left: 18px;
  padding-right: 18px;
  background-color: ${({ isDefault, disabled, isLoading }) => {
    if (disabled) return theme.colors.disabled
    if (isLoading) return 0.8
    if (isDefault) return 'rgb(65, 144, 224)'
    return theme.colors.primary
  }};
  opacity: ${({ disabled, isLoading }) => {
    //if (disabled) return 0.75
    if (isLoading) return 0.8
    return 1
  }};
  color: black;
  overflow: hidden;
  border-radius: 5px;
  cursor: ${({ disabled, isLoading }) => {
    if (disabled) return 'not-allowed'
    if (isLoading) return 'wait'
    return 'pointer'
  }};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  elevation: 20;
  animation-name: ${({ animateError }) =>
    animateError
      ? css`
          ${shakeAnimation}
        `
      : 'none'};
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 0.6;
    transform: scale(1.1);
    transition: all 50ms ease-in-out;
  }

  ${(props) =>
    props.width &&
    `
  min-width: ${props.width}px;
  width: ${props.width}px;
 `}
`

export const ButtonText = S.Text`
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: ${theme.colors.light2};
  text-align: center;
`

interface HSpacingProps {
  size?: number
}

export const HSpacing = S.View`
  height: auto;
  width: ${(props: HSpacingProps) => (props.size ? props.size : 14)}px;
`

interface VSpacingProps {
  size?: number
}

export const VSpacing = S.View`
  height: ${(props: VSpacingProps) => (props.size ? props.size : 14)}px;
`

export const ArrowIconNext = S(FaAngleRight)`
  color: ${theme.colors.secondary2}
  font-size: 10px;
  height: 40px;
  margin: 10px;
`

export const ArrowIconPrev = S(FaAngleLeft)`
  color: ${theme.colors.secondary2}
  font-size: 10px;
  height: 40px;
  margin: 10px;
`
