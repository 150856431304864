import { CourseOptionView } from '@app/evaluation-settings-menu/HeaderArea.styles'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import theme from '@app/ui/theme'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useClubData } from '../hooks'

const FILTER_DROP_WIDTH = 260

interface HeaderProps {
  filterStatusValue: string
  onChangeFilterValue: (value: string) => void
  region: any
}

const FILTER_STATUS_LABEL = 'District'

const DistrictFilter = ({
  filterStatusValue,
  onChangeFilterValue,
  region,
}: HeaderProps) => {
  const { clubs } = useClubData()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1524px)' })

  const uniqueDistricts =
    clubs &&
    Object.values(clubs)
      .filter((club) => !region || region.clubs.includes(+club.districtID))
      .map((club) => club.districtName)
      .filter(
        (club, index, newArray) => newArray.indexOf(club) === index && club
      )

  return (
    <DropDownMenu
      tablet={isTabletOrMobile}
      iconColor={theme.colors.white}
      value={filterStatusValue || FILTER_STATUS_LABEL}
      width={FILTER_DROP_WIDTH}
      style={{ fontWeight: 'bold' }}
      backgroundColor={theme.colors.primary}
      color={'white'}
      yAdj={100}
    >
      <CourseOptionView>
        <div>
          <span>{FILTER_STATUS_LABEL}</span>
          <select
            style={{ width: '180px' }}
            onChange={(event) => onChangeFilterValue(event.target.value)}
          >
            <option value={''} selected={filterStatusValue === ''}>
              All
            </option>
            {uniqueDistricts &&
              uniqueDistricts.map((item, i) => (
                <option
                  value={item}
                  key={i}
                  selected={filterStatusValue === item}
                >
                  {item}
                </option>
              ))}
          </select>
        </div>
      </CourseOptionView>
    </DropDownMenu>
  )
}

export { DistrictFilter }
