import images from '@app/images'
import theme from '@app/ui/theme'
import styled, { keyframes } from 'styled-components'

import S from 'styled-components/native'

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  video {
    object-fit: cover;
  }
  position: relative;
`

export const BG = styled(images.svg.Topography)``

export const LoginWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  height: fit-content;
  width: fit-content;
  padding: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  h1 {
    font-size: 40px;
    text-wrap: wrap;
    display: block;
    width: 300px;
    line-height: 38px;
    margin: 40px;
  }

  & input {
    border: 1px solid ${theme.colors.light};
    padding: 14px;
    font-size: 16px;
    outline-color: #191919;
    margin: 10px;
    margin-bottom: 0;
    width: calc(100% - 50px);
    border-radius: 8px;
  }
  & button {
    border: 0;
    padding: 5px;
    font-size: 17px;
    color: ${theme.colors.white};
    margin: 10px;
    width: calc(100% - 20px);
    background-color: ${theme.colors.primary};
    border-radius: 8px;
  }
  & button:active {
    background-color: rgba(0, 0, 0, 0.9);
  }
`

export const SubmitButton = styled.button`
  border: 0;
  padding: 12px;
  font-size: 15px;
  color: ${theme.colors.white};
  margin: 10px;
  width: calc(100% - 10px);
  background-color: ${theme.colors.primary};
  cursor: pointer;
`

export const SubmitButtonText = S.Text`
  line-height: 40px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: ${theme.colors.light2};
  text-align: center;
`

export const ErrorTextContainer = styled.span`
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0px 14px 0 ${theme.colors.dark1};
`
export const ErrorText = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  color: ${theme.colors.warning};
  text-align: center;
`

export const LoginLogo = styled.img`
  padding: 32px;
  background-color: black;
  border-radius: 8px;
  position: absolute;
  top: -120px;
`

export const TopoBG = styled.img``

const waveAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`

export const Wave = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(-45deg, #81b6ea 0%, #61dafb 35%, #81b6ea 70%);
  background-size: 400% 400%;
  animation: ${waveAnimation} 4s linear infinite;
`

export const Container = styled.div`
  position: relative;
`
