import React, { FunctionComponent } from 'react'
import {
  AdmFactorContainer,
  AdmFactorText,
  AdmFactorTextLabel,
  ButtonHeader,
  TotalText,
  TotalValue,
} from './AdjustmentFactors.styles'
import ExpandableAdjustmentLabel from './ExapandableAdjustmentLabel'
import FactorButton from './FactorButton'
import { AdmGroupFactor } from '../types'
import PlaceHolderLabel from './PlaceHolderLabel'
import theme from '@app/ui/theme'
import OrLabel from './OrLabel'
import { AdjustmentItem, EvaluationUserView } from '@app/evaluation-core/types'

type OwnProps = {
  totalValueSign: '' | '+'
  totalValue: string | number | undefined
  notOr: boolean
  factor: AdmGroupFactor
  lzAdjustments: number[]
  factorIndex: number
  index: number
  openFactors: string[]
  setOpenFactors: React.Dispatch<React.SetStateAction<string[]>>
  view: EvaluationUserView
  adjustments: AdjustmentItem[]
}

type Props = OwnProps

export const LzAndShotAdjustment: FunctionComponent<Props> = ({
  totalValueSign,
  totalValue,
  notOr,
  factor,
  lzAdjustments,
  factorIndex,
  index,
  openFactors,
  view,
  adjustments,
  setOpenFactors,
}) => {
  const handleShowOr = (factor: AdmGroupFactor) =>
    typeof factor.showOr === 'function'
      ? factor.showOr(adjustments, view)
      : factor.showOr

  return (
    <AdmFactorContainer key={factorIndex}>
      <>
        {factor.label && notOr && (
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <ExpandableAdjustmentLabel
              factorIndex={factorIndex}
              index={index}
              openFactors={openFactors}
              setOpenFactors={setOpenFactors}
              factor={factor}
            />
            <TotalText>
              <TotalValue>
                {factor.totalKey ? `${totalValueSign}${totalValue}` : 0}{' '}
              </TotalValue>
              Cumulative Total
            </TotalText>
          </div>
        )}

        <div style={{ flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {lzAdjustments.map((adj: number, shotIndex) => (
              <>
                {shotIndex === 0 &&
                  (handleShowOr(factor) ? (
                    <OrLabel style={{ marginTop: 'auto', paddingBottom: 28 }} />
                  ) : (
                    lzAdjustments?.length < 5 && <PlaceHolderLabel />
                  ))}
                <div
                  key={shotIndex}
                  style={{ flexGrow: 1, flexShrink: 0, flexBasis: '20%' }}
                >
                  {notOr &&
                    (shotIndex + 1 === lzAdjustments?.length &&
                    factor.type !== 'landingZones' ? (
                      <ButtonHeader>{`Green`}</ButtonHeader>
                    ) : (
                      <ButtonHeader>{`LZ ${shotIndex + 1}`}</ButtonHeader>
                    ))}
                  {factor.items.map((button, buttonIndex) => {
                    const shouldRender =
                      typeof button.shouldRender === 'undefined' ||
                      (button.shouldRender &&
                        typeof button.shouldRender === 'function' &&
                        button.shouldRender(adjustments, view))

                    if (shouldRender) {
                      return (
                        <FactorButton
                          key={buttonIndex}
                          buttonIndex={buttonIndex}
                          view={view}
                          adjustments={adjustments}
                          useLz
                          useShot
                          shotIndex={shotIndex}
                          factor={factor}
                          button={button}
                          hideText
                        />
                      )
                    }
                    return null
                  })}
                </div>
              </>
            ))}
          </div>
          <div style={{ flexDirection: 'column' }}>
            <div style={{ height: theme.space[3] * 1.25 }} />
            {factor.items.map((button, buttonIndex) => {
              const shouldRenderItem =
                button.shouldRender && typeof button.shouldRender === 'function'
                  ? button.shouldRender(adjustments, view)
                  : true
              return (
                shouldRenderItem && (
                  <AdmFactorText key={buttonIndex}>
                    {button.text && shouldRenderItem && (
                      <AdmFactorTextLabel>{button.text}</AdmFactorTextLabel>
                    )}
                    {button.renderText &&
                      shouldRenderItem &&
                      button.renderText(adjustments, view)}
                    {factor.selectWidget && factor.selectWidget()}
                  </AdmFactorText>
                )
              )
            })}
          </div>
        </div>
      </>
    </AdmFactorContainer>
  )
}

export default LzAndShotAdjustment
