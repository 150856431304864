import theme from '@app/ui/theme'
import styled from 'styled-components'

export default styled.div<{ gridMode?: boolean }>`
  display: ${({ gridMode }) => (gridMode ? 'grid' : 'block')};
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin: 20px;
  box-shadow: 0 0px 4px 0 ${({ theme }) => theme.colors.dark1};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  border-radius: 4px;
  overflow: hidden;
`
