import store, {
  PersistGate,
  Provider,
  ReactReduxFirebaseProvider,
  persistor,
  rrfProps,
} from '@app/state'

import Main from '@app/navigation'
import ModalWrapper from '@app/modal'
import React from 'react'
import { SnackbarProvider } from '@app/snackbar'
import { ThemeProvider as TP } from 'styled-components/native'
import { ThemeProvider } from 'styled-components'
import theme from '@app/ui/theme'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobile } from 'react-device-detect'

import { DndProvider } from 'react-dnd'

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <TP theme={theme}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <DndProvider
                backend={isMobile ? TouchBackend : HTML5Backend}
                options={isMobile ? { delayTouchStart: 500 } : {}}
              >
                <Main />
                <ModalWrapper />
              </DndProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </TP>
      </ReactReduxFirebaseProvider>
    </PersistGate>
  </Provider>
)

export default App
