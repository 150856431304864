import { GolferShortName, MIShot } from '@app/evaluation-core/types'
import { useEffect, useState } from 'react'

import { getEvaluationState } from '@app/evaluation-core/evaluation'
import useEvaluation from './useEvaluation'
import useEvaluationUser from './useEvaluationUser'

export const getPlayerShots = (golfer?: GolferShortName) => {
  const [shots, setShots] = useState(1)
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  const getShots = () => {
    if (!evaluation.holes) return 0

    if (golfer) {
      if (evaluation?.holes[view?.hole]?.tees[view.tee]?.shots[golfer]) {
        return (
          evaluation?.holes[view?.hole]?.tees[view.tee]?.shots[
            golfer
          ] as MIShot[]
        ).length
      }
    }

    const shots = evaluation?.holes[view?.hole]?.tees[view.tee]?.shots
    const shotKeys = Object.keys(shots)

    if (!shotKeys.includes(view.golfer)) {
      console.log(`shotKeys missing for golfer: view.golfer`)
      return (shots[shotKeys[0]] as MIShot[]).length ?? 1
    }

    return (shots[view.golfer] as MIShot[]).length ?? 1
  }

  useEffect(() => {
    setShots(getShots())
  }, [evaluation, view])

  return shots
}
