import { AdjustmentTypes, getAdjustmentValue } from '@app/evaluation-core'

import { AdjustmentItem } from '@app/evaluation-core/types'
import { getPlayerShots } from './getPlayerShots'
import useAdjustment from './useAdjustment'
import useEvaluationUser from './useEvaluationUser'

type Value = { [key: string]: number | string | null }

export default (adjustment: AdjustmentItem): Value => {
  const { view } = useEvaluationUser()
  const adjustments = useAdjustment()
  const shots = getPlayerShots()
  const indexNormalization = 1

  const totalKey = `${adjustment.valueKey}_final`

  const adjustmentIsShots =
    adjustment.name === 'Topography' ||
    adjustment.shots ||
    adjustment.landingZones

  const getAdjustmentFormat = (): AdjustmentTypes => {
    if (adjustment.name === 'Elevation') {
      return 'tee'
    }
    return adjustmentIsShots && adjustment.adjustmentIndex !== 1
      ? 'shot'
      : 'golfer'
  }

  const getShotValues = (shots: number) =>
    Array(shots)
      .fill('')
      .map((_, shotIndex) => {
        return getAdjustmentValue(
          getAdjustmentFormat(),
          { ...view, shot: shotIndex },
          totalKey,
          adjustments
        ) as number
      })

  if (adjustment.valueKey === 'topo') {
    const values = getShotValues(shots)
    const isPar3 = values.length <= 1
    const twoShotHole = values.length === 2

    /** 
  * ONE-SHOT HOLES (PAR-3)
  * 
  * - Use the Par-3 Hole column and rate Topography based on the elevation difference between the tee and the green. For holes
  * where the bogey player cannot reach the green in one shot, rate Topography as a two-shot hole.

  * TWO-SHOT HOLES

  * - Rate the approach shot to the green based on the stance or lie in the landing zone and the elevation change from the landing
  * zone to the green.
  *
  * THREE (OR MORE) SHOT HOLES
  * 
  * - For shots from landing zone to landing zone, use the top “Almost Level with Fairway” row and rate Topography based on
  * stance or lie difficulty only.
  * - Rate the approach shot to the green based on the stance or lie in that landing zone and the elevation change from that landing
  * zone to the green.
  * - Rate each of the individual landing zones and use the highest rating. 
  **/
    const getTopoValues = () => {
      switch (true) {
        case twoShotHole:
          return values.slice(0, -1)
        case isPar3:
          return values.slice(0)
        default:
          return values.slice(1)
      }
    }

    const shotsValues = getTopoValues()
    const maxValue = Math.max(...shotsValues)

    /** Topography is not measured on the first shot so the index removed must be added  */
    const topographyOffset = shotsValues.length - 1 >= 1 ? 1 : 0

    const maxIndex =
      shotsValues.indexOf(maxValue) + indexNormalization + topographyOffset

    const valueIndex = isPar3 ? 0 : view.shot - topographyOffset
    return {
      value: shotsValues[valueIndex],
      highestValue: shotsValues[shotsValues.indexOf(maxValue)],
      index: maxIndex === shots ? 'A' : maxIndex,
    }
  }
  if (adjustment.landingZones) {
    /** Topography is not measured on the first shot so the first is to be removed */
    const shotsSliceAmount = shots === 1 ? 1 : -1
    const shotsValues = getShotValues(shots).slice(0, shotsSliceAmount)
    const maxValue = Math.max(...shotsValues)
    /** Topography is not measured on the first shot so the index removed must be added  */

    const maxIndex = shotsValues.indexOf(maxValue) + indexNormalization

    return {
      value: shotsValues[view.shot],
      highestValue: shotsValues[shotsValues.indexOf(maxValue)],
      index: maxIndex,
    }
  }

  const shotTotals = getShotValues(shots)

  const maxValue = Math.max(...shotTotals)

  const maxIndex = shotTotals.indexOf(maxValue) + indexNormalization

  return {
    value: shotTotals[view.shot],
    highestValue: shotTotals[shotTotals.indexOf(maxValue)],
    index: maxIndex === shots && adjustment.shots ? 'A' : maxIndex,
  }
}
