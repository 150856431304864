import { State } from '@app/state/types'
import { useDispatch, useSelector } from 'react-redux'

export function useDrawer() {
  const dispatch = useDispatch<any>()
  const collapsed = useSelector((state: State) => state.drawer)

  const toggleMenu = () => dispatch({ type: 'SET_DRAWER', value: !collapsed })

  return { collapsed, toggleMenu }
}
