import React from 'react'
import Feather from '@ovaeasy/react-native-vector-icons/Feather'

import {
  TableValueBox,
  TableValueContainer,
  TableValueLabel,
  TableValueText,
} from './TableValue.styles'
import { LoadingContainer } from './AdjustmentFactors.styles'
import theme from '@app/ui/theme'
import useAdjustment from '@common/hooks/useAdjustment'

type TableValueProps = {
  value: number | string
  label?: string
}

const TableValue: React.FC<TableValueProps> = ({
  value,
  label = 'Table Value',
}: TableValueProps) => {
  const adjustments = useAdjustment()
  const isLayup = label === 'Crossing Obstacle Rating' && value === -1
  return (
    <TableValueContainer>
      <TableValueLabel>{label}</TableValueLabel>
      <TableValueBox>
        {!adjustments || !adjustments.adjustments ? (
          <LoadingContainer>
            <Feather name="loader" size={16} color={theme.colors.light} />
          </LoadingContainer>
        ) : (
          <TableValueText>{isLayup ? 'FL' : value}</TableValueText>
        )}
      </TableValueBox>
    </TableValueContainer>
  )
}

export default TableValue
