import { __DEBUG__clearAllEvaluations } from '@app/evaluation-core/evaluation'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import * as React from 'react'
import { View } from 'react-native'

const Profile: React.FC = () => (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <RoundedButton
      onPress={() => __DEBUG__clearAllEvaluations()}
      title="Clear all evaluations"
      testId="btn-clear-evaluations"
    />
  </View>
)

export default Profile
