import { EvaluationState } from '@app/evaluation-core/types'

export default {
  evaluation: {
    id: '',
    adjustments: [],
    userId: '',
    courseName: '',
    courseId: '',
    clubId: 0,
    status: 0,
    holes: [],
    users: {},
    view: undefined,
    isMixed: false,
    ratingRevision: {
      rootEvaluation: '',
      parentEvaluation: '',
      ratingRevision: 0,
      ratingDate: '',
    },
    loading: {
      progress: 0,
      message: '',
      loading: 0,
    },
    settingsChecked: false,
  },
} as EvaluationState
