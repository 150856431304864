import React, { useState, useEffect } from 'react'
import theme from '@app/ui/theme'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import sv from 'date-fns/locale/sv'
import { ButtonProps } from 'react-native'
import * as S from './styled'
import { useAxios } from '@common/hooks/useAxios'
import {
  ProjectCourse,
  ProjectHole,
  ProjectTee,
  ProjectTeeHole,
} from '../../../types/Project'
import { shadeColor } from '@app/evaluation-core/evaluation'
import { convertToUnits } from './CourseScorecard'

interface ScorecardRowProps {
  editMode: false
  hole: ProjectHole
  units: 'metric' | 'imperial'
}

export default function ScorecardRow({
  editMode,
  hole,
  units,
}: ScorecardRowProps) {
  return (
    <tr key={`hole-${hole.id}`}>
      <S.TableCell>{hole.courseHoleNumber}</S.TableCell>
      <S.TableCell>
        {editMode ? (
          <input
            className="w-auto text-center max-w-10"
            value={Number(hole?.par) !== -1 ? hole?.par : ''}
          />
        ) : (
          <>{Number(hole?.par) !== -1 ? hole?.par : 'N/A'}</>
        )}
      </S.TableCell>
      <S.TableCell>
        {editMode ? (
          <input
            className="w-auto text-center max-w-10"
            value={Number(hole?.hcp) !== -1 ? hole?.hcp : ''}
          />
        ) : (
          <>{Number(hole?.hcp) !== -1 ? hole?.hcp : 'N/A'}</>
        )}
      </S.TableCell>
      {hole?.teesOnHole.map((tee: ProjectTeeHole) => {
        const slopeLength = convertToUnits(Number(tee?.slopeLength), units)
        const fixedLength = convertToUnits(Number(tee?.fixedLength), units)
        const definedLength = convertToUnits(Number(tee?.definedLength), units)

        return (
          <>
            <S.RatedTableCell style={{ backgroundColor: '#8b8b8b' }}>
              {editMode ? (
                <input
                  className="w-auto text-center max-w-14"
                  value={slopeLength !== -1 ? slopeLength : ''}
                />
              ) : (
                <>{Number(slopeLength) !== -1 ? slopeLength : 'N/A'}</>
              )}
            </S.RatedTableCell>
            <S.FixedTableCell style={{ backgroundColor: '#c6c6c6' }}>
              {editMode ? (
                <input
                  className="w-auto text-center max-w-14"
                  value={Number(fixedLength) !== -1 ? fixedLength : ''}
                />
              ) : (
                <>{Number(fixedLength) !== -1 ? fixedLength : 'N/A'}</>
              )}
            </S.FixedTableCell>
            <S.ScorecardTableCell style={{ backgroundColor: '#e4e4e4' }}>
              {editMode ? (
                <input
                  className="w-auto text-center max-w-14"
                  value={Number(definedLength) !== -1 ? definedLength : ''}
                />
              ) : (
                <>{Number(definedLength) !== -1 ? definedLength : 'N/A'}</>
              )}
            </S.ScorecardTableCell>
          </>
        )
      })}
    </tr>
  )
}
