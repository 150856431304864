import theme from '@app/ui/theme'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import styled from 'styled-components'
import _styled from 'styled-components/native'

import { media } from '../ui'

export const HeadingContainer = styled.div``

export const HeadingRight = styled.div`
  display: flex;
  margin-right: 15px;
`

export const RightMenu = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 0 0px 40px 0 ${theme.colors.dark1};
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 25px;
  border-radius: ${theme.borderRadius}px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.secondary2};
  margin-left: 10px;
  & > * {
    height: 40px;
    margin-right: 10px;
  }
  margin-top: 0px;
  cursor: pointer;
  user-select: none;
  &:active {
    opacity: 0.7;
  }
`

export const RightMenuIcon = styled(FaAngleDown)`
  height: 40px;
`

export const RightMenuIconUp = styled(FaAngleUp)`
  height: 40px;
`

export const RightMenuIconR = styled(RightMenuIcon)`
  margin-left: 10px;
  margin-right: 0;
`

export const RightMenuIconUpR = styled(RightMenuIconUp)`
  margin-left: 10px;
  margin-right: 0;
`

interface CourseOptionViewProps {
  justifyInnerContent?: string
}

export const CourseOptionView = styled.div<CourseOptionViewProps>`
  user-select: none;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  z-index: 999;
  width: 100%;

  & > div {
    display: flex;
    flex: 1;
    justify-content: ${(props) => props.justifyInnerContent ?? 'space-between'};
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  & > div > div {
  }

  & span {
    font-size: 15px;
    font-weight: 460;
    color: rgba(0, 0, 0, 0.8);
    float: left;
    line-height: 24px;
  }

  & input {
    width: 45px;
    height: 36px;
    border: none;
    border: 1px solid ${theme.colors.dark1};
    border-radius: ${theme.borderRadius}px;
    outline: none;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    font-weight: bold;
    font-family: 'Proxima Nova';
    align-self: flex-end;
    -webkit-appearance: none;
    appearance: none;
    opacity: 1;
  }
  & select {
    width: 60px;
    height: 36px;
    border: none;
    background-color: ${theme.colors.light2};
    border-radius: ${theme.borderRadius}px;
    outline: none;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    font-weight: bold;
    font-family: 'Proxima Nova';
    align-self: flex-end;
    text-align-last: center;
    padding-left: 9px;
    border-right: 10px solid ${theme.colors.light2};
  }
`

export const OptionInput = styled.input`
  margin: 0;
  padding: 0;
  width: 45px;
  height: 24px !important;
  border: none;
  border: 1px solid ${theme.colors.dark1};
  border-radius: ${theme.borderRadius}px;
  outline: none;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  font-family: 'Proxima Nova';

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
`

export const CollapsedInputContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
  padding: 15px;
`

interface CourseOptionSelectProps {
  selected?: boolean
}

export const CourseOptionSelect = styled.div<{ disabled?: boolean }>`
  height: 36px;
  border-radius: 5px;
  overflow: hidden !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 5px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

export const CourseOptionSelectItem = _styled.TouchableOpacity<{
  disabled?: boolean
}>`
  width: 80px;
  line-height: 36px;
  text-align: center;
  background-color: ${(props: CourseOptionSelectProps) =>
    props.selected ? theme.colors.primary : theme.colors.light2};
  color: ${(props: CourseOptionSelectProps) =>
    props.selected ? theme.colors.white : '#191919'};
  font-size: 16px;
  font-weight: 460;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => {
    if (disabled) return 'not-allowed'
    return 'pointer'
  }};
  `

export const CourseOptionSeparator = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  margin-bottom: 5px;
`

export const FormExGenderBtn = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  & > div {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }
  & > div > div {
    width: 30px;
    text-align: center;
  }
`

export const HSpacer = styled.div`
  width: 15px;
`

export const ReadOnlyLabel = styled.div`
  display: flex;
  align-items: center;

  color: ${theme.colors.primary};
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
  margin-right: 20px;
  margin-top: 5px;
  & > div {
    margin-right: 5px;
  }
`

export const TeeButton = _styled.TouchableOpacity<{ selected: boolean }>`
  background: ${({ selected }) =>
    selected ? theme.colors.primary : '#c4c4c4'};
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 13px;
`

export const TeeButtonContainer = _styled.View`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`

export const AvatarContainer = _styled.View<{ width: number; padding: number }>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: ${({ padding }) => padding}px;
  width: ${({ width, padding }) => width - padding}px;
  height: 42px;
`

export const FileNameInput = styled.div`
  box-shadow: 0 0px 4px 0 ${theme.colors.dark1};
  transition: 0.3s;
  width: 260px;
  height: 40px;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: 21px;
  max-width: 340px;
  ${media.tablet} {
    width: 100%;
    max-width: 100%;
  }
  & > input {
    border: none;
    outline: none;
    height: 40px;
    width: 240px;
    text-align: left;
    padding-left: 5px;
    font-size: 16px;
    font-weight: 500;
    align-self: flex-start;
    background-color: transparent;
  }
  & > input::placeholder {
    color: #333;
  }
`
