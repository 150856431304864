/* eslint-disable react/prop-types */

import { LoadingContainer } from '@app/evaluation-adjustments/components/AdjustmentFactors.styles'
import { AdjustmentItem } from '@app/evaluation-core/types'
import theme from '@app/ui/theme'
import getHighestIndexAndValue from '@common/hooks/getHighestIndexAndValue'
import { getPlayerShots } from '@common/hooks/getPlayerShots'
import useAdjustment from '@common/hooks/useAdjustment'
import Feather from '@ovaeasy/react-native-vector-icons/Feather'

import React from 'react'

type Props = {
  adjustment: AdjustmentItem
  showValue?: boolean
}

const HighestIndex: React.FC<Props> = ({ adjustment, showValue }) => {
  const { index, highestValue } = getHighestIndexAndValue(adjustment)
  const totalShots = getPlayerShots()
  const adjustments = useAdjustment()

  const type = adjustment.shots ? 'shots' : 'landingZones'
  const getTitle = () => {
    if (showValue) {
      return 'Highest Rating'
    }

    return type === 'shots' ? 'Highest Shot' : 'Highest LZ'
  }

  if (adjustment.valueKey === 'topo') {
    const shouldShowShots = totalShots >= 3
    if (!shouldShowShots) {
      return <></>
    }
  }

  if (adjustment.valueKey === 'dl') {
    return <></>
  }

  const highestValueOrIndex = showValue ? highestValue : index

  const value =
    !adjustments || !adjustments.adjustments ? (
      <LoadingContainer>
        <Feather name="loader" size={16} color={theme.colors.light} />
      </LoadingContainer>
    ) : (
      highestValueOrIndex
    )
  return (
    <>
      <div>{getTitle()}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {value}
      </div>
    </>
  )
}

export default HighestIndex
