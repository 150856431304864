import 'mapbox-gl/dist/mapbox-gl.css'

import {
  MAPBOX_ACCESS_TOKEN,
  MAP_INIT_CENTER,
  MAP_INIT_ZOOM,
  MAP_STYLE,
} from '../domain/config'
import { MapWrapper, StyledPopup } from './CourseMap.styles'
import React, { useState } from 'react'
import ReactMapboxGl, { GeoJSONLayer, Layer, Popup } from 'react-mapbox-gl'

import {
  courseMarkers,
  getCourseStatus,
  getStatusName,
  Statuses,
} from '@app/courses/domain/ratingStatus'
import { LRCourse } from '../../types/Clubs'

const Map: any = ReactMapboxGl({
  accessToken: MAPBOX_ACCESS_TOKEN,
  logoPosition: 'top-left',
  attributionControl: false,
})

export interface CourseMapProps {
  courses?: LRCourse[]
}

const convertCourseToGeoJSON = (course: LRCourse) => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [
      Number(course.mapLongitude || course.longitude) || 0,
      Number(course.mapLatitude || course.latitude) || 0,
    ],
  },
  properties: {
    ...course,
    status: getStatusName(getCourseStatus(course.branchStatus)),
  },
})

const getLayoutLayer = () => ({
  'icon-image': ['get', 'status'],
  'icon-allow-overlap': true,
})

const getImage = (name: string) => {
  const image = new Image()

  image.src =
    'data:image/svg+xml;charset=utf-8;base64,' + btoa(courseMarkers[name])

  return image
}

const images = [
  ['unknown', getImage('unknown')],
  ['not_available', getImage('not_available')],
  ['planned', getImage('planned')],
  ['measured', getImage('measured')],
  ['preparing', getImage('preparing')],
  ['ready_for_rating', getImage('ready_for_rating')],
  ['in_progress', getImage('in_progress')],
  ['rated', getImage('rated')],
  ['outdated', getImage('outdated')],
]

const CourseMap = ({ courses }: CourseMapProps) => {
  const [mapState, setMapState] = useState({
    zoom: MAP_INIT_ZOOM,
    center: MAP_INIT_CENTER,
  })
  const [course, setCourse] = useState<LRCourse | null>()
  if (!courses) return null

  const courseGeoJSON = {
    type: 'FeatureCollection',
    features: courses.map(convertCourseToGeoJSON),
  }

  const clickMarker = (point) => {
    const [course] = point.features
    setCourse(course.properties)
    setMapState({
      center: [point.lngLat.lng, point.lngLat.lat],
      zoom: [8],
    })
  }

  const getCoordinates = (course: LRCourse) => {
    if (course?.mapLatitude && course?.mapLongitude) {
      return [+course.mapLongitude, +course.mapLatitude]
    }
    return [+course.longitude, +course.latitude]
  }

  return (
    <MapWrapper>
      <Map
        style={MAP_STYLE}
        containerStyle={{ height: '100%', width: '100%' }}
        center={mapState.center}
        zoom={mapState.zoom}
        onRegionChange={console.log}
        onStyleLoad={() => setMapState({ ...mapState, zoom: MAP_INIT_ZOOM })}
        onClick={() => setCourse(null)}
        antialias
        pitchWithRotate={false}
        dragRotate={false}
        touchZoomRotate={false}
        compass
      >
        <GeoJSONLayer
          id="courses"
          data={courseGeoJSON}
          sourceOptions={{
            cluster: false,
          }}
        />

        <Layer
          id="clustered_arrived_layer"
          sourceId="courses"
          type="symbol"
          images={images}
          layout={getLayoutLayer()}
          onClick={clickMarker}
        />

        {course && (
          // @ts-ignore
          <Popup key={course.id} coordinates={getCoordinates(course)}>
            <StyledPopup>
              <div>{`${course.golfClubName} - ${course.name}`}</div>
            </StyledPopup>
          </Popup>
        )}
      </Map>
    </MapWrapper>
  )
}

export default CourseMap
