import Spacer from '@app/components/atoms/Spacer'
import React from 'react'
import * as S from './styled'

interface PersonalDataProps {
  email: string
  firstname: string
  lastname: string
  handicap: number
  homeclub: string
  golfId?: string
}

const PersonalData = ({
  email,
  firstname,
  lastname,
  handicap,
  homeclub,
  golfId,
}: PersonalDataProps) => {
  return (
    <>
      <S.Data>
        Email: <span>{email}</span>
      </S.Data>
      <Spacer height={25} />
      <S.Data>
        First name: <span>{firstname}</span>
      </S.Data>
      <Spacer height={25} />
      <S.Data>
        Last name: <span>{lastname}</span>
      </S.Data>
      <Spacer height={25} />
      <S.Data>
        Home club: <span>{homeclub}</span>
      </S.Data>
      <Spacer height={25} />
      <S.Data>
        Golf ID: <span>{golfId}</span>
      </S.Data>
    </>
  )
}

export default PersonalData
