import { color7, color8, color10, primary } from '@app/ui/colors'
import styled from 'styled-components'

import theme from '../theme'

export const StepperBox = styled.div`
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  & > span {
    color: #333;
  }
  &:active {
    opacity: 0.5;
    background-color: ${theme.colors.light};
  }
  &:last-child {
    padding-right: 10px;
  }
  &:first-child {
    padding-left: 10px;
  }
`

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${theme.borderRadius}px;
  border: 1px solid ${theme.colors.light};
  overflow: hidden;
`

export const SelectBoxContainer = styled.div`
  border: none;
  background-color: ${theme.colors.light2};
  border-radius: ${theme.borderRadius}px;
  width:100%
`

export const SelectBox = styled.select`
  height: 36px;
  font-size: 16px;
  line-height: 36px;
  font-weight: bold;
  font-family: 'Proxima Nova';
  border: 0;
  height: 36px;
  border: none;
  background-color: ${theme.colors.light2};
  border-radius: ${theme.borderRadius}px;
  outline: none;
  align-self: flex-end;
  padding-left: 2px;
  padding-right: 2px;
`
