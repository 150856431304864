// @ts-nocheck
import GreenA from './png/A.png'
import GreenB from './png/B.png'
import GreenC from './png/C.png'
import CourseFallback from './png/courseFallback.png'
import GreenD from './png/D.png'
import GreenE from './png/E.png'
import Logo from './png/logo2.png'
import Trees from './png/Trees.png'
import BackArrow from './svg/BackArrow.tsx'
import Check from './svg/Check'
import EvaluationLeader from './svg/EvaluationLeader.tsx'
import LiveRateLogo from './svg/LiveRate.svg'
import LiveRateIcon from './svg/LiveRateIcon.svg'
import Marker from './svg/Marker.tsx'
import VerticalLine from './svg/VerticalLine.svg'
import Warning from './svg/Warning.tsx'
import X from './svg/X'
import loginBg from './video/video.mp4'
import Topography from './svg/Topography.tsx'
import MappingLogo from './png/Mapping.png'

const video = {
  loginBg,
}

const png = {
  GreenA,
  GreenB,
  GreenC,
  GreenD,
  GreenE,
  Trees,
  CourseFallback,
  Logo,
  MappingLogo,
}

const svg = {
  Check,
  Marker,
  Warning,
  BackArrow,
  X,
  LiveRateLogo,
  LiveRateIcon,
  VerticalLine,
  BackArrow,
  EvaluationLeader,
  Topography,
}

export default { png, svg, video }
