import fb from '@app/firebase'
import store from '@app/state'
import { GolferShortName } from './types'

export const ref = (path: string): any => fb.database().ref(path)

export const FBREF_COURSE_EVALUATION = (
  key?: string | null | undefined
): string => `evaluations/${key}`

export const FBREF_COURSE_EVALUATIONS = (
  key?: string | null | undefined
): string => `evaluations`

export const FBREF_HOLE_TEE = (
  hole?: number | null | undefined,
  tee?: number | null | undefined
): string => `holes/${hole}/tees/${tee}`

export const FBREF_EVALUATION_NOTIFICATIONS = (
  key?: string | null | undefined
): string => `adjustments/${key}/notifications`

export const FBREF_EVALUATION_HOLE_STATUS = (
  key: string,
  hole: number
): string => `evaluations/${key}/holes/${hole}/locked`

export const FBREF_ADJUSTMENT = (key?: string | null | undefined): string =>
  `adjustments/${key}/adjustment/holes/`

export const FBREF_ADJUSTMENT_COURSE = (
  key?: string | null | undefined
): string => `adjustments/${key}/adjustment/course/`

export const FBREF_USER_EVALUATION_VIEW = (user?: string): string => {
  const state = store.getState()
  const userId = user ?? state.user.id

  return `users/${userId}/view`
}

export const FBREF_ADJUSTMENT_UPDATE_QUEUE = (evalId?: string): string => {
  return `/queues/updateAdjustments/adjustments/${evalId}`
}
export const FBREF_ADJUSTMENT_CR_UPDATE_QUEUE = (evalId?: string): string => {
  return `/queues/crAdjustments/adjustments/${evalId}`
}

export const FBREF_ADJUSTMENT_RESET_QUEUE = (evalId?: string): string => {
  return `/queues/resetAdjustments/adjustments/${evalId}`
}
export const FBREF_HOLE_RESET_QUEUE = (evalId?: string): string => {
  return `/queues/resetHole/adjustments/${evalId}`
}
export const FBREF_TEE_RESET_QUEUE = (evalId?: string): string => {
  return `/queues/resetTee/adjustments/${evalId}`
}
export const FBREF_HOLE_REFRESH_QUEUE = (evalId?: string): string => {
  return `/queues/refreshHole/adjustments/${evalId}`
}

export const FBREF_TEE_REFRESH_QUEUE = (evalId?: string): string => {
  return `/queues/refreshTee/adjustments/${evalId}`
}

export const FBREF_TEE_COPY_QUEUE = (evalId?: string): string => {
  return `/queues/copyTee/adjustments/${evalId}`
}

export const FBREF_HOLE_RESET_CRDATA_QUEUE = (evalId?: string): string => {
  return `/queues/resetCRData/adjustments/${evalId}`
}

export const FBREF_NOTIFICATIONS_UPDATE_SET = (
  evaluationId: string,
  hole: number
): string => {
  return `adjustments/${evaluationId}/notifications/${hole}/`
}

export type AdjustmentContext = {
  hole: number
  golfer?: GolferShortName
  shot?: number
  tee?: number
  adjustment?: number
  userId?: string
}

export type QueueTask<T> = {
  timestamp: number
  data: T
}

export type UpdateTask = {
  adjustment: { [key: string]: number | string }
  type: 'adjustment' | 'course'
  context?: AdjustmentContext
}
