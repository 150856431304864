import React from 'react'
import { version } from '../../../../../package.json'
import getEnv from '../../../environments/getEnv'
import * as S from './styled'

const EnvTag = () => {
  const env = getEnv()
  const tag = `v${version}`

  return (
    <S.Container pointerEvents="box-none">
      <S.Tag>{`${env.env.toLocaleUpperCase()} ${tag}`}</S.Tag>
    </S.Container>
  )
}

export default EnvTag
