import { DrawerAction, DrawerState } from '../types'

export const DRAWER_INITIAL_STATE: DrawerState = {
  drawer: false,
}

export default function drawer(
  state = DRAWER_INITIAL_STATE,
  action: DrawerAction
) {
  switch (action.type) {
    case 'SET_DRAWER':
      return action.value
    default:
      return state
  }
}
