import { useEffect } from 'react'
import { TeamRole } from '@app/evaluation-core/types'
import { navigate } from '@app/navigation'
import { useEvaluation } from '@common/hooks'
import getEnv from '@app/environments/getEnv'

const useEvaluatable = () => {
  const { evaluation } = useEvaluation()
  const users = Object.values(evaluation.users)
  const hasSystemAdmin = users.some(
    (user) => user.role === TeamRole.SYSTEM_ADMIN
  )
  const evaluators = users.filter((user) => user.role < TeamRole.READ_ONLY)

  useEffect(() => {
    if (evaluators.length < 3 && !hasSystemAdmin && getEnv().env === 'PROD') {
      return navigate('Overview')
    }
  }, [evaluators, users])
}

export default useEvaluatable
