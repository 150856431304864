import {
  ContentCardMap,
  ContentMain,
  ContentRight,
  LeftPane,
} from './Courses.styles'
import React, { useState, useEffect } from 'react'
import {
  clubDisplayFilter,
  filterBySearch,
  filterByStatus,
  filterByVisibleClubs,
} from '../domain/searchFilters'
import { useClubData, useLoading } from '../hooks'

import { ContentCard } from '@app/evaluation/components/Preparation.styles'
import CourseMap from '@app/course-map'
import { Flex } from '@app/ui/Flex'
import { Header } from './Header'
import TableData from '@app/components/molecules/TableData'
import useInvite from '@common/hooks/useInvite'
import { useMediaQuery } from 'react-responsive'
import { BranchStatus } from '@app/evaluation-core/types'
import useUser from '@common/hooks/useUser'
import { useSnackbar } from '@app/snackbar'
import theme from '@app/ui/theme'
import { useIsFocused } from '@react-navigation/native'
import ScreenWrapper from '@app/components/atoms/ScreenWrapper'
import { LRCourse } from '../../types/Clubs'

export type CourseWithStatus = LRCourse & { branchStatus: BranchStatus }

const Courses = () => {
  const [openSnackbar] = useSnackbar({
    style: {
      backgroundColor: theme.colors.warning,
    },
  })
  useInvite()
  const [searchValue, setSearchValue] = useState('')
  const [filterValue, setFilterValue] = useState(-1)
  const [regionFilter, setRegionFilter] = useState('')
  const [districtFilter, setDistrictFilter] = useState('')
  const { courses, clubs, loadingClubs, clubsWithCourses } = useClubData()
  const { loading } = useLoading(loadingClubs)
  const user = useUser()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1524px)' })
  const focused = useIsFocused()
  useEffect(() => {
    if (!user.golfID && focused) {
      // TODO: Check if this is needed
      // openSnackbar(
      //   <div onClick={() => navigate('Teams')}>
      //     Press here to go to your profile and add your GIT-ID.
      //   </div>,
      //   10000
      // )
    }
  }, [user.golfID, focused])

  if (!clubs || !courses) return null

  const visibleClubs = clubsWithCourses.filter((club, index) =>
    clubDisplayFilter(
      club,
      index,
      searchValue,
      filterValue,
      regionFilter,
      districtFilter
    )
  )

  const coursesWithClubLocationAndStatus = courses?.map((course) => {
    const club = clubs.find((club) => club.id === course.golfClubID)
    if (club) {
      return {
        ...course,
        branchStatus: club.branchStatus,
        mapLatitude: club.mapLatitude || club.latitude || '0',
        mapLongitude: club.mapLongitude || club.longitude || '0',
      }
    }
    return course
  })

  if (!courses) return null

  return (
    <ScreenWrapper style={{ display: 'flex', flex: 1 }}>
      <LeftPane>
        <Header
          tablet={isTabletOrMobile}
          onChangeSearchValue={setSearchValue}
          searchValue={searchValue}
          onChangeFilterValue={(value) => setFilterValue(parseInt(value))}
          filterStatusValue={filterValue}
          onChangeRegionFilterValue={(value) => setRegionFilter(value)}
          regionFilterStatusValue={regionFilter}
          onChangeDistrictFilterValue={(value) => setDistrictFilter(value)}
          districtFilterStatusValue={districtFilter}
        />
        <Flex row>
          <ContentMain>
            {courses && (
              <ContentCard>
                <TableData
                  clubs={clubsWithCourses}
                  searchValue={searchValue}
                  filterStatusValue={filterValue}
                  regionFilterStatusValue={regionFilter}
                  districtFilterStatusValue={districtFilter}
                  loading={loading}
                />
              </ContentCard>
            )}
          </ContentMain>
          <ContentRight>
            <ContentCardMap>
              <CourseMap
                courses={coursesWithClubLocationAndStatus
                  .filter((course) => filterBySearch(course, null, searchValue))
                  .filter((course) =>
                    filterByStatus(course, course.branchStatus, filterValue)
                  )
                  .filter((course) =>
                    filterByVisibleClubs(course, visibleClubs)
                  )}
              />
            </ContentCardMap>
          </ContentRight>
        </Flex>
      </LeftPane>
    </ScreenWrapper>
  )
}

export default Courses
