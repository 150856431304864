import styled from 'styled-components'

export const HoleSelection = styled.div`
  width: 385px;
  height: 40px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 8px;
  z-index: 10;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
`
