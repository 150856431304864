import { props } from 'cypress/types/bluebird'
import React, { FunctionComponent } from 'react'
import { Text, View } from 'react-native'
import AcceptRejectButton from '../../atoms/AcceptRejectButton/AcceptRejectButton'
import Spacer from '../../atoms/Spacer'
import * as S from './styled'

type Props = {
  isVisible: boolean
  title: string
  description: string
  leftButtonText: string
  rightButtonText: string
  onPressLeft: () => void
  onPressRight: () => void
  destructive?: boolean
  children?: any
}

const AcceptDenyModal: FunctionComponent<Props> = ({
  isVisible,
  title,
  description,
  leftButtonText,
  rightButtonText,
  onPressLeft,
  onPressRight,
  destructive,
  children,
}) => {
  if (!isVisible) return null
  return (
    <S.ModalContainer visible={isVisible}>
      <S.ModalContainerBackground></S.ModalContainerBackground>
      <S.ModalContentContainer>
        <S.ModalContent>
          <View
            style={{
              marginBottom: 20,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {children}
          </View>
          <S.ModalTitle>{title}</S.ModalTitle>
          <Spacer height={20} />
          <S.ModalDescription>{description}</S.ModalDescription>
          <Spacer height={40} />
          <View
            style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}
          >
            <AcceptRejectButton
              onPress={onPressRight}
              text={rightButtonText}
              accept
              sameSize
              destructive={destructive}
            />
            <AcceptRejectButton
              onPress={onPressLeft}
              text={leftButtonText}
              reject
              sameSize
            />
          </View>
        </S.ModalContent>
      </S.ModalContentContainer>
    </S.ModalContainer>
  )
}

export default AcceptDenyModal
