import theme from '@app/ui/theme'
import styled, { keyframes } from 'styled-components'

export const CloseButton = styled.div`
  height: 42px;
  width: 42px;
  background-color: ${theme.colors.warning};
  border-radius: 20px;
  margin: 0px 10px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  color: white;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
`

const dotAnimation = keyframes`
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".";
  }
`

export const LoadingText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: ${theme.colors.secondary2};
  &:after {
    content: '.';
    animation: ${dotAnimation} 1.5s steps(3, end) infinite;
  }
`

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
