import React from 'react'
import { OverlayContainer } from './Overlay.styles'

interface OverlayProps {
  closeAction?: () => void
  children?: React.ReactNode
  ref?: React.MutableRefObject<HTMLDivElement>
  zIndex?: number
}

const Overlay: React.FC<OverlayProps> = ({
  closeAction,
  children,
  ref,
  zIndex,
}) => {
  function _handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const className = (event.target as Element).className
    const exists = className.indexOf('vp-overlay') !== -1

    if (exists && closeAction) {
      closeAction()
    }
  }

  return (
    <OverlayContainer
      ref={ref}
      onClick={_handleClick}
      className="vp-overlay"
      zIndex={zIndex}
    >
      {children}
    </OverlayContainer>
  )
}

export default Overlay
