import { useEffect, useState } from 'react'
import firebase from 'firebase'

export const useRTDBConnection = () => {
  const [isConnected, setIsConnected] = useState(true)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const connectedRef = firebase.database().ref('.info/connected')
    const onConnect = (e) => {
      setLoading(false)
      setIsConnected(e.val())
    }
    const onDisconnect = (e) => {
      setLoading(false)
      setIsConnected(e.val())
    }

    firebase.database().goOnline()

    connectedRef.on('value', onConnect)
    connectedRef.on('value', onDisconnect)

    return () => {
      connectedRef.off('value', onConnect)
      connectedRef.off('value', onDisconnect)
    }
  }, [])

  const connect = () => {
    if (loading) return
    setLoading(true)
    firebase.database().goOnline()
  }

  return { isConnected, connect, loading }
}
