import React, { useEffect, useRef, useState } from 'react'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import * as S from './styled'
import { AdminEvaluationActionMenu } from './AdminEvaluationActionMenu'
import { EvalContainer, Issue } from '@common/hooks/useFilteredEvaluations'
import { InLineSpinner } from '@app/evaluation/components/Spinner'

interface AdminCombinedEvaluationsListProps {
  evals: EvalContainer[]
  isLoading?: boolean
}

const convertStatusToText = (status: 0 | 1 | 2 | 3 | 4) => {
  switch (true) {
    case status === EvalStatus.NOT_STARTED:
      return 'Not started'
    case status === EvalStatus.PREPARING:
      return 'Preparing'
    case status === EvalStatus.IN_PROGRESS:
      return 'In Progress'
    case status === EvalStatus.FINALIZED:
      return 'Finalized'
    case status === undefined:
      return 'Undefined'
    default:
      return 'Unknown'
  }
}

export const AdminCombinedEvaluationsList = ({
  evals,
  isLoading,
}: AdminCombinedEvaluationsListProps) => {
  if (!evals || evals == undefined || isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InLineSpinner size={32} />
      </div>
    )
  }

  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <th style={{ textAlign: 'start' }}></th>
          <th style={{ textAlign: 'start' }}>Key</th>
          <th style={{ textAlign: 'start' }}>ID</th>
          <th style={{ textAlign: 'start' }}>Status</th>
          <th style={{ textAlign: 'start' }}>Rating Name</th>
          <th style={{ textAlign: 'start' }}>Club+Course Name</th>
          <th style={{ textAlign: 'start' }}>Rating Date</th>
          <th style={{ textAlign: 'start' }}>Issues detected</th>
          <th style={{ textAlign: 'start', width: 'auto' }}></th>
        </tr>
      </thead>
      <tbody>
        {evals &&
          evals.map((ec, index) => {
            //EvalID is the realTime KEY ID, the id in evaluation is the property id. SHould be the same BUT IS NOT GARANTEED since the import v1 function did not replace old property id

            const getIssues = () => {
              const issues: Issue[] = []

              if (
                ec.rtEval &&
                ec.fsEval &&
                ec.rtEval.saveName !== ec.fsEval.saveName
              )
                issues.push({
                  text: 'Rating NAME mismatch',
                  mostLikelyWrong: 'fs',
                })
              if (
                ec.rtEval &&
                ec.fsEval &&
                ec.rtEval.status !== ec.fsEval.status
              )
                issues.push({
                  text: `Rating STATUS mismatch ${ec.rtEval?.status} vs ${ec.fsEval?.status}`,
                  mostLikelyWrong: 'fs',
                })
              if (ec.rtEval && ec.fsEval && ec.rtEval.id !== ec.fsEval.id)
                issues.push({
                  text: 'ID mismatch',
                  mostLikelyWrong: 'fs',
                })

              if (ec.rtEval && ec.rtEvalKey !== ec.rtEval.id)
                issues.push({
                  text: 'Key + ID mismatch',
                  mostLikelyWrong: 'fs',
                })

              return issues
            }

            const issues = getIssues()

            return (
              <React.Fragment key={index}>
                <S.TableRow
                  key={'rt' + index}
                  style={{
                    backgroundColor:
                      issues.length > 0 ? 'rgba(150,0,0,0.1)' : '#fff',
                  }}
                >
                  <S.RTTableHeaderCell>RT</S.RTTableHeaderCell>
                  <S.RTTableCell>{ec.rtEvalKey}</S.RTTableCell>
                  <S.RTTableCell>{ec.rtEval?.id}</S.RTTableCell>
                  <S.RTTableCell style={{ whiteSpace: 'nowrap' }}>
                    {ec.rtEval?.status}{' '}
                    {convertStatusToText(ec.rtEval?.status as any)}
                  </S.RTTableCell>{' '}
                  <S.RTTableCell>{ec.rtEval?.saveName}</S.RTTableCell>
                  <S.RTTableCell>
                    {ec.rtEval?.clubName} / {ec.rtEval?.courseName}
                  </S.RTTableCell>
                  <S.RTTableCell>{ec.rtEval?.evaluationDate}</S.RTTableCell>
                  <S.RTTableCell>
                    {issues &&
                      issues.map((issue, index) => (
                        <div key={index}>{issue.text}</div>
                      ))}
                  </S.RTTableCell>
                  <S.RTTableCell
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                    }}
                  >
                    <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                      <AdminEvaluationActionMenu
                        evaluation={ec.rtEval}
                        onEvaluationInfo={() => {
                          return
                        }}
                        onRegenerateForms={() => {
                          return
                        }}
                        onDeleteEvaluation={() => {
                          return
                        }}
                        onResetRatingRevision={() => {
                          return
                        }}
                        onResetUnlockedHoles={() => {
                          return
                        }}
                      />
                    </span>
                  </S.RTTableCell>
                </S.TableRow>
                <S.TableRow
                  key={'fs' + index}
                  style={{
                    backgroundColor:
                      issues.length > 0 ? 'rgba(150,0,0,0.1)' : '#fff',
                  }}
                >
                  <S.FSTableHeaderCell>FS</S.FSTableHeaderCell>
                  <S.FSTableCell></S.FSTableCell>
                  <S.FSTableCell>
                    {ec.fsEval ? ec.fsEval.id : '-'}
                  </S.FSTableCell>
                  <S.FSTableCell style={{ whiteSpace: 'nowrap' }}>
                    {ec.fsEval?.status}{' '}
                    {ec.fsEval
                      ? convertStatusToText(ec.fsEval.status as any)
                      : '-'}
                  </S.FSTableCell>
                  <S.FSTableCell>
                    {ec.fsEval ? ec.fsEval.saveName : '-'}
                  </S.FSTableCell>
                  <S.FSMissingTableCell color="#d00">
                    No param
                  </S.FSMissingTableCell>
                  <S.FSMissingTableCell color="#d00">
                    No param
                  </S.FSMissingTableCell>
                  <S.FSTableCell></S.FSTableCell>
                  <S.FSTableCell
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                    }}
                  >
                    <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                      <AdminEvaluationActionMenu
                        evaluation={ec.rtEval}
                        onEvaluationInfo={() => {
                          return
                        }}
                        onRegenerateForms={() => {
                          return
                        }}
                        onDeleteEvaluation={() => {
                          return
                        }}
                        onResetRatingRevision={() => {
                          return
                        }}
                        onResetUnlockedHoles={() => {
                          return
                        }}
                      />
                    </span>
                  </S.FSTableCell>
                </S.TableRow>
                <S.TableRow key={'spacer' + index}>
                  <S.TableCell
                    style={{
                      paddingBottom: '10px',
                    }}
                  ></S.TableCell>
                </S.TableRow>
              </React.Fragment>
            )
          })}
      </tbody>
    </table>
  )
}
