import {
  AdjustmentItem,
  GolferShortName,
  LockStatus,
} from '@app/evaluation-core/types'
import {
  MenuOverlay,
  OverlayFooter,
  OverlayFooterContent,
  OverlayMain,
  OverlayTop,
  OverlayTopNav,
  ResetLoadingContainer,
  TopTitle,
} from './index.styles'
import {
  ModalCloseButton,
  ModalNavigateContainer,
} from '@app/evaluation-modal/index.styles'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import {
  resetModifications,
  saveAdjustment,
} from '@app/evaluation-core/modifications'

import { ActivityIndicator } from 'react-native'
import { AdjustmentInfoButton } from './components/AdjustmentInfoButton'
import { HSpacer } from '@app/evaluation-settings-menu/HeaderArea.styles'
import { HSpacing } from '@app/ui/rounded-button/RoundedButton.styles'
import Header from './components/Header'
import HighestIndex from './components/HighestIndex'
import { IoMdClose } from 'react-icons/io'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import TotalValue from './components/TotalValue'
import { getPlayerShots } from '@common/hooks/getPlayerShots'
import { resetAdjustment } from '@app/evaluation-core'
import theme from '@app/ui/theme'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationLockedState from '@common/hooks/useEvaluationLockedState'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { useSnackbar } from '@app/snackbar'
import useViewExists from '@common/hooks/useViewExists'
import { useMediaQuery } from 'react-responsive'
import usePortraitMode from '@common/hooks/usePortrait'
import useReadOnly from '@app/evaluation/hooks/useReadOnly'
import { EvalStatus } from '@app/courses/domain/ratingStatus'

export interface ModalOverlayProps {
  open: boolean
  height: number | string
  setModalState: (
    hole: number,
    tee: number | undefined,
    golfer: GolferShortName,
    adjustment: number | undefined
  ) => void
  children: React.ReactNode
}

const ModalOverlay: React.FC<ModalOverlayProps> = ({
  setModalState,
  open,
  children,
  height,
}: ModalOverlayProps) => {
  const { evaluation, currentId } = useEvaluation()
  const { view } = useEvaluationUser()
  const adjustments = useAdjustment()
  const user = useEvaluationUser()
  const bottomBarRef = useRef<HTMLDivElement>(null)
  const lockStatus = useEvaluationLockedState()
  const [openSnackbar] = useSnackbar()
  const shots = getPlayerShots()
  const isPortrait = usePortraitMode()
  const handleReadOnly = useReadOnly()
  const [isResetting, setIsResetting] = useState(false)

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1180px)' })

  useViewExists(view)

  if (!view || !evaluation) return null
  if (!open || !adjustments) return null

  const { golfer, adjustment } = view

  const teeItem = evaluation?.holes[view?.hole].tees[view.tee]

  const exitTitle = () => {
    if (
      lockStatus !== LockStatus.UNLOCKED ||
      evaluation.status == EvalStatus.FINALIZED ||
      adjustment === 12
    ) {
      return 'Exit'
    }
    return 'Confirm'
  }

  const currentAdjustment = evaluation.adjustments.find(
    (adj) => adj.adjustmentIndex === adjustment
  )

  const shouldShowTopographyShots =
    currentAdjustment?.name === 'Topography' && shots >= 3

  const adjustmentIsShots =
    shouldShowTopographyShots ||
    currentAdjustment?.shots ||
    currentAdjustment?.landingZones

  const shouldShowIndex =
    currentAdjustment &&
    (currentAdjustment.shots || currentAdjustment.landingZones)

  const title = useMemo(() => {
    return isTabletOrMobile && isPortrait
      ? `${currentAdjustment?.shortName} \n Tee: ${teeItem.teeNumber} (${teeItem.name}) - ${golfer}`
      : `${currentAdjustment?.name}, Tee: ${teeItem.teeNumber} (${teeItem.name}) - ${golfer}`
  }, [isTabletOrMobile, isPortrait, currentAdjustment])

  const handleSave = () => {
    // if (lockStatus === LockStatus.LOCKED) {
    //   return openSnackbar(
    //     'This hole is locked, please contact your team leader.'
    //   )
    // }
    const tee = evaluation.holes[view?.hole].tees[view.tee].teeIndex
    saveAdjustment({
      evaluationId: currentId as string,
      adjustmentIndex: currentAdjustment?.adjustmentIndex as number,
      hole: view?.hole,
      tee,
      golfer: view.golfer,
      shot: view.shot,
    })
    setModalState(view?.hole, view.tee, view.golfer, -1)
  }

  const handleReset = async () => {
    if (lockStatus === LockStatus.READ_ONLY) {
      return openSnackbar('This rating is in read-only mode.')
    }
    if (lockStatus === LockStatus.LOCKED) {
      return openSnackbar(
        'This hole is locked, please contact your team leader.'
      )
    }

    try {
      setIsResetting(true)
      setTimeout(() => setIsResetting(false), 4000)
      await Promise.all([
        resetModifications({
          evaluationId: currentId as string,
          adjustmentIndex: currentAdjustment?.adjustmentIndex as number,
          hole: view?.hole,
          tee: teeItem.teeIndex,
          golfer: view.golfer,
        }),
        resetAdjustment(
          currentAdjustment?.key as any,
          view,
          currentId as string,
          shots
        ),
      ])
    } catch (error) {
      console.error(error)
      setIsResetting(false)
    }
  }

  const handleConfirmOrExit = (cb: () => void) => () => {
    if (lockStatus !== LockStatus.UNLOCKED) {
      setModalState(view?.hole, view.tee, view.golfer, -1)
      return
    }

    cb()
  }

  return (
    <>
      <MenuOverlay height={height} ref={bottomBarRef}>
        <OverlayTop showLandingZones={adjustmentIsShots}>
          <OverlayTopNav>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: 160,
              }}
            >
              <ModalCloseButton
                onClick={() => {
                  setModalState(view?.hole, view.tee, view.golfer, -1)
                }}
              >
                <IoMdClose size={20} color={theme.colors.primary2} />
              </ModalCloseButton>

              {user.isAdmin && isResetting ? (
                <div style={{ marginLeft: 15 }}>
                  <ResetLoadingContainer>
                    <ActivityIndicator
                      color={theme.colors.primary}
                      size="small"
                    />
                  </ResetLoadingContainer>
                </div>
              ) : (
                <div style={{ marginLeft: 15 }}>
                  <RoundedButton
                    title="Reset"
                    onPress={handleReset}
                    disabled={lockStatus !== LockStatus.UNLOCKED}
                  />
                </div>
              )}
            </div>
            <TopTitle>{title}</TopTitle>
            <ModalNavigateContainer>
              <AdjustmentInfoButton type="guide" />
              <HSpacer />
              <AdjustmentInfoButton type="manual" />
            </ModalNavigateContainer>
          </OverlayTopNav>
          {currentAdjustment && (
            <Header adjustment={currentAdjustment.valueKey} />
          )}
        </OverlayTop>
        <OverlayMain>
          {adjustment !== -1 ? (
            children
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                flex: 1,
              }}
            >
              <ActivityIndicator color={theme.colors.primary} size="large" />
            </div>
          )}
          <OverlayFooter>
            <OverlayFooterContent leftAlign portraitMode={isPortrait}>
              {shouldShowIndex && (
                <HighestIndex
                  adjustment={currentAdjustment as AdjustmentItem}
                />
              )}
            </OverlayFooterContent>
            <OverlayFooterContent leftAlign portraitMode={isPortrait}>
              {shouldShowIndex && (
                <HighestIndex
                  adjustment={currentAdjustment as AdjustmentItem}
                  showValue
                />
              )}
            </OverlayFooterContent>
            <OverlayFooterContent portraitMode={isPortrait}>
              {currentAdjustment && (
                <TotalValue
                  adjustment={currentAdjustment}
                  isMultipleShots={adjustmentIsShots}
                  view={view}
                />
              )}
            </OverlayFooterContent>
            <OverlayFooterContent portraitMode={isPortrait}>
              <RoundedButton
                // width={portraitMode ? 60 : 140}
                title={exitTitle()}
                onPress={handleConfirmOrExit(handleSave)}
              />
            </OverlayFooterContent>
            <HSpacing size={5} />
          </OverlayFooter>
        </OverlayMain>
      </MenuOverlay>
    </>
  )
}

export default ModalOverlay
