import styled from 'styled-components'

export const HeaderText = styled.span`
  font-weight: bold;
  font-size: 20px;
`

export const SubText = styled.span`
  color: #9b9b9b;
  font-size: 12px;
`

export const CardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  padding: 8px;
  box-sizing: border-box;
`
