import { GolferShortName, MITee } from '@app/evaluation-core/types'
import React, { useEffect, useRef } from 'react'
import {
  TeeContainer,
  TeeRowGender,
  TeeRowGroup,
  TeeRowScorer,
  TeeRowScorerItem,
  TeeRowTee,
  TeeRowTeeLabel,
  TeeRowTeeLabelText,
  TeeRowValue,
  ValueItem,
  DisplayText,
  ShotText,
  TeeRowTeeName,
  TeeRowTeeNameLabelText,
} from './TeeItem.styles'
import {
  copyTee,
  getAdjustment,
  getAdjustmentValue,
  resetTee,
} from '@app/evaluation-core'
import {
  getModification,
  getModificationPercentage,
  resetModifications,
} from '@app/evaluation-core/modifications'
import {
  setCollapsedTees,
  setSelectedHole,
} from '@app/evaluation-core/actions/evaluationActions'

import useAdjustment from '@common/hooks/useAdjustment'
import { useDispatch } from 'react-redux'
import useEvaluation from '@common/hooks/useEvaluation'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import useModifications from '@common/hooks/useModifications'
import useUser from '@common/hooks/useUser'
import { useCopiedTee } from './CopiedTeeContext'
import { useSnackbar } from '@app/snackbar'
import TeeOptionsModal from './TeeOptionsModal'

interface TeeItemProps {
  tee: MITee
  index: number
  modalOpen?: boolean
}

export const TeeItem = ({ tee, index, modalOpen }: TeeItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { currentId, evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  const [openSnackBar] = useSnackbar()
  const modifications = useModifications()
  const adjustments = useAdjustment()
  const userId = useUser().id.toString()
  const dispatch = useDispatch<any>()
  const [_, __, ___, getIsCopied, ____] = useCopiedTee()

  const handleResetTee = (teeIndex: number) => {
    if (!currentId) return
    resetModifications({
      evaluationId: currentId,
      hole: view?.hole,
      tee: teeIndex,
    })
    resetTee(view?.hole, teeIndex, currentId)
  }

  const handleResetTeeOnAllHoles = (teeIndex: number) => {
    if (!currentId) return
    evaluation?.holes.forEach((hole, index) => {
      resetModifications({
        evaluationId: currentId,
        hole: hole.holeIndex,
        tee: teeIndex,
      })
      resetTee(index, teeIndex, currentId)
    })
  }

  useEffect(() => {
    if (tee.teeIndex === view.tee && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [view])

  const teeIsCopied = () => getIsCopied(tee.teeIndex)

  if (!adjustments.adjustments) {
    return null
  }

  const allTeeIndexes = evaluation?.holes[view?.hole].tees.map(
    (tee) => tee?.teeIndex
  )

  const _collapsedTees: number[] = view?.collapsedTees || []
  const collapsed = _collapsedTees.includes(tee.teeIndex)

  const openAllTees = () => dispatch(setCollapsedTees([], userId))

  const closeAllTees = () =>
    dispatch(setCollapsedTees(allTeeIndexes as number[], userId))

  const collapseTee = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (!collapsed) {
      const tees = [..._collapsedTees, tee.teeIndex]
      if (e.shiftKey) {
        return closeAllTees()
      }
      return dispatch(setCollapsedTees(tees, userId))
    }
    const filteredTees = _collapsedTees.filter((collapsedTee) => {
      return +collapsedTee !== +tee.teeIndex
    })
    if (e.shiftKey) {
      return openAllTees()
    }
    return dispatch(setCollapsedTees(filteredTees, userId))
  }

  const par =
    adjustments && getAdjustmentValue('hole', view, 'Par', adjustments)

  const teeLength =
    adjustments &&
    getAdjustmentValue('hole', view, `tee${tee.teeNumber}Length`, adjustments)

  const getTeeLength = (teeNumber: number) =>
    adjustments
      ? (getAdjustmentValue(
          'hole',
          view,
          `tee${teeNumber}Length`,
          adjustments
        ) as number)
      : 0

  const teeNumberText = `Tee: ${tee.teeNumber}`
  const teeNameText = `(${tee.name})`

  const currentTeeIndex = evaluation?.holes[view?.hole]?.tees[view.tee].teeIndex

  const tees = evaluation?.holes[view?.hole].tees.map((tee) => tee) as MITee[]

  return (
    <TeeContainer ref={ref} collapsed={collapsed} disabled={teeIsCopied()}>
      <div
        style={{
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
        }}
      >
        <TeeRowTee selected={tee.teeIndex === currentTeeIndex}>
          {!collapsed && (
            <>
              <TeeRowTeeLabelText style={{ maxWidth: 50, fontSize: 16 }}>
                {teeLength}
              </TeeRowTeeLabelText>
              <TeeRowTeeLabel>
                <TeeRowTeeLabelText>{teeNumberText}</TeeRowTeeLabelText>
              </TeeRowTeeLabel>
            </>
          )}
          {!modalOpen && (
            <TeeOptionsModal
              collapsed={collapsed}
              evaluation={evaluation}
              view={view}
              tees={tees}
              tee={tee}
              getTeeLength={getTeeLength}
              collapseTee={collapseTee}
              handleResetTee={handleResetTee}
              handleResetTeeOnAllHoles={handleResetTeeOnAllHoles}
            />
          )}
        </TeeRowTee>

        <TeeRowTeeName>
          <TeeRowTeeLabel>
            <TeeRowTeeNameLabelText>{teeNameText}</TeeRowTeeNameLabelText>
          </TeeRowTeeLabel>
        </TeeRowTeeName>

        <TeeRowScorer>
          {tee.golfers
            .map((golferShortName: GolferShortName, typeIndex: number) => (
              <div key={typeIndex}>
                <TeeRowScorerItem
                  selected={
                    tee.teeIndex === currentTeeIndex &&
                    view.golfer === golferShortName
                  }
                >
                  {golferShortName}
                </TeeRowScorerItem>
              </div>
            ))
            .slice(0, collapsed ? 1 : tee.golfers.length)}
        </TeeRowScorer>
      </div>

      <TeeRowGroup>&nbsp;</TeeRowGroup>
      <div
        style={{
          zIndex: 100,
        }}
        onClick={(e) => {
          if (teeIsCopied()) {
            e.stopPropagation()
            e.preventDefault()
            return openSnackBar(
              `Tee: ${tee.teeNumber} (${tee.name}) is currently being copied over. If you would like to rate this tee, please press the "X" button to cancel the tee copying.`
            )
          }
        }}
      >
        <div style={{ pointerEvents: teeIsCopied() ? 'none' : 'auto' }}>
          {evaluation.adjustments
            .slice(0, 3)
            .map((adjustment, groupIndex: number) => {
              const localIndex = allTeeIndexes?.findIndex(
                (index) => index === tee.teeIndex
              )
              return (
                <TeeRowValue key={groupIndex}>
                  {tee.golfers
                    .map((golferType, typeIndex: number) => {
                      const usersOnAdjustment = evaluation
                        ? Object.values(evaluation?.users).filter(
                            (user) =>
                              view?.hole === user.view?.hole &&
                              groupIndex === user.view.adjustment &&
                              localIndex === user.view.tee &&
                              golferType === user.view.golfer
                          )
                        : []

                      const filteredValues = adjustments.adjustments
                        ? Object?.entries(adjustments.adjustments).filter(
                            ([key, value]) => (key as string).includes('_final')
                          )
                        : []

                      const values = filteredValues.filter(
                        ([key, value]) =>
                          key ===
                            `${adjustment.valueKey.replace(
                              '&',
                              ''
                            )}_final_HOLE_${view?.hole}` ||
                          key ===
                            `${adjustment.valueKey.replace(
                              '&',
                              ''
                            )}_final_HOLE_${view?.hole}_TEE_${localIndex}` ||
                          key ===
                            `${adjustment.valueKey.replace(
                              '&',
                              ''
                            )}_final_HOLE_${
                              view?.hole
                            }_TEE_${localIndex}_GOLFER_${golferType}` ||
                          key.includes(
                            `${adjustment.valueKey.replace(
                              '&',
                              ''
                            )}_final_HOLE_${
                              view?.hole
                            }_TEE_${localIndex}_GOLFER_${golferType}_SHOT_`
                          )
                      )

                      const valuesOnly = values.map(
                        ([key, value]) => value as number
                      )

                      const sortedValues = [...valuesOnly].sort(
                        (a: number, b: number) => b - a
                      )
                      const [displayValue] = sortedValues

                      if (!displayValue && displayValue !== 0) {
                        return (
                          <ValueItem disabled key={groupIndex + typeIndex} />
                        )
                      }

                      const modificationType = getModification(
                        modifications,
                        adjustment.adjustmentIndex,
                        {
                          tee: localIndex,
                          shot: view.shot,
                          golfer: golferType,
                        },
                        adjustment.shots || adjustment.landingZones
                      )

                      const wasSelected =
                        view?.tee === tee.teeIndex &&
                        view.golfer === golferType &&
                        view?.prevAdj === adjustment.adjustmentIndex

                      return (
                        <ValueItem
                          disabled={teeIsCopied()}
                          last={wasSelected}
                          modification={modificationType}
                          modificationPercentage={100}
                          key={groupIndex + typeIndex}
                          onClick={() =>
                            dispatch(
                              setSelectedHole(
                                view?.hole,
                                localIndex,
                                golferType,
                                adjustment.adjustmentIndex
                              )
                            )
                          }
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              padding: 4,
                            }}
                          >
                            {usersOnAdjustment.map((user) => (
                              <div
                                key={user.userId}
                                style={{
                                  height: 7,
                                  width: 7,
                                  borderRadius: 500,
                                  background: user.view.color,
                                }}
                              />
                            ))}
                          </div>
                          <DisplayText>{displayValue}</DisplayText>
                        </ValueItem>
                      )
                    })
                    .slice(0, collapsed ? 1 : tee.golfers.length)}
                </TeeRowValue>
              )
            })}

          <TeeRowGroup>&nbsp;</TeeRowGroup>
          {evaluation.adjustments.slice(3).map((adjustment, i: number) => {
            const localIndex = allTeeIndexes?.findIndex(
              (index) => index === tee.teeIndex
            )
            return (
              <TeeRowValue key={`${adjustment.adjustmentIndex}-${i}`}>
                {tee.golfers
                  .map((golferType: GolferShortName, golferIndex: number) => {
                    const filteredValues = adjustments.adjustments
                      ? Object?.entries(adjustments.adjustments).filter(
                          ([key, value]) => (key as string).includes('_final')
                        )
                      : []

                    const values = filteredValues.filter(
                      ([key, value]) =>
                        key ===
                          `${adjustment.valueKey.replace('&', '')}_final_HOLE_${
                            view?.hole
                          }_TEE_${localIndex}_GOLFER_${golferType}` ||
                        key.includes(
                          `${adjustment.valueKey.replace('&', '')}_final_HOLE_${
                            view?.hole
                          }_TEE_${localIndex}_GOLFER_${golferType}_SHOT_`
                        )
                    )

                    const valuesOnly = values.map(
                      ([key, value]) => value as number
                    )

                    const displayValue = () => {
                      if (adjustment.shortName === 'TOPO.') {
                        /** Topography is not measured on the first shot so the first is to be removed */
                        const isPar3 = valuesOnly.length <= 1
                        const twoShotHole = valuesOnly.length === 2

                        const getTopoValues = () => {
                          switch (true) {
                            case twoShotHole:
                              return valuesOnly.slice(0, -1)
                            case isPar3:
                              return valuesOnly.slice(0)
                            default:
                              return valuesOnly.slice(1)
                          }
                        }
                        const shotsValues = getTopoValues()
                        const maxValue = Math.max(...shotsValues)

                        /** Topography is not measured on the first shot so the index removed must be added  */
                        const topographyOffset =
                          shotsValues.length - 1 >= 1 ? 1 : 0
                        const valueIndex = isPar3
                          ? 0
                          : view.shot - topographyOffset
                        return maxValue
                      }
                      if (adjustment.name === 'Fairway' && tee.teeIndex === 0) {
                        console.log(valuesOnly)
                      }
                      return adjustment.landingZones && valuesOnly.length > 1
                        ? Math.max(...valuesOnly.slice(0, -1))
                        : Math.max(...valuesOnly)
                    }

                    const maxLandingZone = valuesOnly.indexOf(displayValue())

                    if (!displayValue() && displayValue() !== 0) {
                      return (
                        <ValueItem
                          disabled
                          key={`${adjustment.adjustmentIndex}-${index}-${golferType}`}
                        />
                      )
                    }

                    const fairway = getAdjustment('fairway')
                    const isPar3Fwy =
                      adjustment.adjustmentIndex === fairway?.groupIndex &&
                      par === 3

                    if (isPar3Fwy) {
                      return (
                        <ValueItem
                          key={`${adjustment.adjustmentIndex}-${index}-${golferType}`}
                          style={{
                            backgroundColor: 'transparent',
                            cursor: 'default',
                          }}
                          disabled
                        >
                          <DisplayText>{'N/A'}</DisplayText>
                        </ValueItem>
                      )
                    }

                    const highestShotText = () => {
                      if (adjustment.landingZones) {
                        const topoSortedValues =
                          valuesOnly.length > 1
                            ? valuesOnly.slice(0, -1)
                            : valuesOnly
                        const maxValue = Math.max(...topoSortedValues)
                        const maxIndex = topoSortedValues.indexOf(maxValue)

                        return maxIndex + 1
                      }
                      if (adjustment.shots) {
                        if (adjustment.valueKey === 'topo') {
                          /** Topography is not measured on the first shot so the first is to be removed */
                          const isPar3 = valuesOnly.length <= 1
                          const twoShotHole = valuesOnly.length === 2
                          const getTopoValues = () => {
                            switch (true) {
                              case twoShotHole:
                                return valuesOnly.slice(0, -1)
                              case isPar3:
                                return valuesOnly.slice(0)
                              default:
                                return valuesOnly.slice(1)
                            }
                          }

                          const shotsValues = getTopoValues()
                          const maxValue = Math.max(...shotsValues)

                          /** Topography is not measured on the first shot so the index removed must be added  */
                          const topographyOffset =
                            shotsValues.length - 1 >= 1 ? 1 : 0

                          const maxIndex =
                            shotsValues.indexOf(maxValue) + 1 + topographyOffset
                          if (shotsValues.length === 1) {
                            return 'A'
                          }
                          return maxIndex === valuesOnly.length ? 'A' : maxIndex
                        }
                        if (valuesOnly.length === maxLandingZone + 1) {
                          return 'A'
                        }
                        return maxLandingZone + 1
                      }
                      return null
                    }

                    const modificationType = getModification(
                      modifications,
                      adjustment.adjustmentIndex,
                      {
                        tee: tee.teeIndex,
                        shot: view.shot,
                        golfer: golferType,
                      },
                      adjustment.shots || adjustment.landingZones
                    )

                    const modificationFillPercentage =
                      getModificationPercentage(
                        modifications,
                        adjustment.adjustmentIndex,
                        {
                          tee: tee.teeIndex,
                          shot: view.shot,
                          golfer: golferType,
                        },
                        adjustment.landingZones ||
                          adjustment.valueKey === 'topo',
                        adjustment.landingZones ||
                          adjustment.valueKey === 'topo' ||
                          adjustment.shots
                      )

                    const wasSelected =
                      view?.tee === tee.teeIndex &&
                      view.golfer === golferType &&
                      view?.prevAdj === adjustment.adjustmentIndex

                    return (
                      <ValueItem
                        disabled={teeIsCopied()}
                        modification={modificationType}
                        modificationPercentage={modificationFillPercentage}
                        onClick={() =>
                          dispatch(
                            setSelectedHole(
                              view?.hole,
                              localIndex,
                              golferType,
                              adjustment.adjustmentIndex
                            )
                          )
                        }
                        key={`${adjustment.adjustmentIndex}-${index}-${golferType}`}
                        last={wasSelected}
                      >
                        <div style={{ position: 'relative' }}>
                          <DisplayText>{displayValue()}</DisplayText>
                          <ShotText>{highestShotText()}</ShotText>
                        </div>
                      </ValueItem>
                    )
                  })
                  .slice(0, collapsed ? 1 : tee.golfers.length)}
              </TeeRowValue>
            )
          })}
        </div>
      </div>
    </TeeContainer>
  )
}
