import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import React, { useState } from 'react'

import { ButtonContainer, ButtonText, HSpacing } from './RoundedButton.styles'
import { ActivityIndicator } from 'react-native'
import theme from '../theme'
import { isColorDark } from '@app/evaluation/components/Finalize/utils'

interface ButtonProps {
  onPress: () => void | Promise<void>
  testId?: string
  title: string
  style?: React.CSSProperties
  textStyle?: React.CSSProperties
  width?: number | string
  loading?: boolean
  disabled?: boolean
  icon?: React.ReactNode
  isDefault?: boolean
}

const RoundedButton = (props: ButtonProps) => {
  const [animateError, setAnimateError] = useState(false)
  const [animationComplete, setAnimationComplete] = useState(false)

  const handlePress = async () => {
    if (props.disabled) return
    try {
      await props.onPress()
    } catch (error) {
      setAnimateError(true)
    }
  }

  return (
    <ButtonContainer
      onClick={handlePress}
      className={props.testId}
      style={props.style}
      width={props.width}
      onAnimationEnd={() => setAnimateError(false)}
      animateError={animateError}
      disabled={props.disabled}
      isLoading={props.loading || false}
      isDefault={props.isDefault}
    >
      {!props.loading ? (
        <>
          {props.icon ? (
            <div
              style={{
                marginRight: props.title.length > 0 ? '8px' : '0px',
              }}
            >
              {props.icon}
            </div>
          ) : null}
          <ButtonText style={props.textStyle}>{props.title}</ButtonText>
        </>
      ) : (
        <ActivityIndicator color={theme.colors.secondary2} />
      )}
    </ButtonContainer>
  )
}

export default RoundedButton
