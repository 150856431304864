import theme from '@app/ui/theme'
import styled from 'styled-components'

export const ContentHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  font-weight: 460;
  letter-spacing: 0.4px;
  color: #242c2b;
`

export const ContentSeparator = styled.div`
  margin-top: 14px;
  margin-bottom: 25px;
  height: 1px;
  background-color: ${theme.colors.light};
`

interface CourseOptionViewProps {
  justifyInnerContent?: string
  inputWidth?: string
}

export const CourseOptionView = styled.div<CourseOptionViewProps>`
  user-select: none;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  z-index: 999;
  width: 100%;

  & > div {
    display: flex;
    flex: 1;
    justify-content: ${(props) => props.justifyInnerContent ?? 'space-between'};
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  & > div > text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  & > div > text > span:nth-child(2) {
    color: #555;
    font-size: 10px;
  }

  & span {
    font-size: 15px;
    font-weight: 460;
    color: rgba(0, 0, 0, 0.8);
    float: left;
    line-height: 24px;
  }

  & input {
    width: ${(props) => props.inputWidth ?? '45px'};
    height: 36px;
    border: none;
    border: 1px solid ${theme.colors.dark1};
    border-radius: ${theme.borderRadius}px;
    outline: none;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    font-weight: bold;
    font-family: 'Proxima Nova';
    align-self: flex-end;
    -webkit-appearance: none;
    appearance: none;
    opacity: 1;
  }

  & textarea {
    width: ${(props) => props.inputWidth ?? '45px'};
    border: none;
    border: 1px solid ${theme.colors.dark1};
    border-radius: ${theme.borderRadius}px;
    outline: none;
    font-size: 16px;
    line-height: 36px;
    font-weight: bold;
    font-family: 'Proxima Nova';
    align-self: flex-end;
    -webkit-appearance: none;
    appearance: none;
    opacity: 1;
  }

  & select {
    width: ${(props) => props.inputWidth ?? '45px'};
    height: 40px;
    border: none;
    border: 1px solid ${theme.colors.dark1};
    border-radius: ${theme.borderRadius}px;
    outline: none;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    font-weight: bold;
    font-family: 'Proxima Nova';
    align-self: flex-end;
    opacity: 1;
  }

  & select > option {
    flex: 1;
  }
`
