import React, { FunctionComponent, useState, useEffect } from 'react'
import * as S from './styled'
import { useSnackbar } from '@app/snackbar'

import styled, { css } from 'styled-components'
import theme from '@app/ui/theme'
import Spacer from '@app/components/atoms/Spacer'
import { useApi } from '@common/hooks/useApi'
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native'

import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import RatingHoles from './components/RatingHoles'
import CourseNameInput from './components/CourseNameInput'
import RatingHoleCountSelector from './components/RatingHoleCountSelector'
import { goBack } from '@app/navigation'
import {
  MiProjectBranch,
  ProjectCourse,
  ProjectHole,
} from '../../../../types/Project'
import { useRatingTemplate } from './hooks/useRatingTemplate'
import { ClubManagementStackParamList } from '@app/navigation/CoursesStack'
import TwoComponentPage from '@app/components/molecules/TwoComponentPage'
import HoleSelector from '@app/components/molecules/HoleSelector'
import { RatingHole, UpdateRatingHole } from './hooks/useRatingTemplate/types'
import usePortraitMode from '@common/hooks/usePortraitMode'
import { setSelectedHole } from '@app/evaluation-core/actions/evaluationActions'

export const Divider = styled.hr`
  max-width: 100%;
  background-color: ${theme.colors.light4};
  height: 2px;
  opacity: 0.5;
  border: none;
  padding: 0px;
  margin: 0px;
`

export const HoleTitleContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  padding-bottom: 0px;
`

export const HoleCard = styled.div<{ index: number; totalHoles: number }>`
  width: 208px;
  height: 130px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  ${({ index }) => (index + 1) % 9 !== 0 && 'margin-bottom: 8px;'}
  ${({ index, totalHoles }) =>
    totalHoles - (index + 1) >= 9 && 'margin-right: 8px;'}
`

export const TrashIconContainer = styled.div<{ disabled: boolean }>`
  background: ${theme.colors.warning};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const defaultTextStyle = css`
  font-family: 'Roboto';
  font-style: normal;
`

export const HoleTitle = styled.span`
  ${defaultTextStyle}
  color: #282828;
  font-weight: 500;
  font-size: 16px;
`
export const CourseName = styled.span`
  ${defaultTextStyle}
  font-size: 12px;
`
export const GlobalHoleNumber = styled.span`
  ${defaultTextStyle}
  color: ${theme.colors.secondary2};
  font-size: 12px;
  font-weight: 600;
`

export type ProjectHoleWithCourseInfo = ProjectHole & {
  courseName?: string
  courseId?: string
}

function getCountOfUniqueHoleIds(holes: RatingHole[]): number {
  const uniqueIds: Set<string> = new Set()

  // Iterate over each object in the array
  holes.forEach((hole) => {
    if (hole.id) {
      uniqueIds.add(hole.id)
    }
  })

  return uniqueIds.size
}

type OwnProps = {}

type Props = OwnProps

const SelectHoles: FunctionComponent<Props> = () => {
  const route =
    useRoute<RouteProp<ClubManagementStackParamList, 'ClubManagement'>>()
  const [openSnackbar] = useSnackbar()
  const portraitMode = usePortraitMode()
  const navigation = useNavigation()

  const { data, isLoading } = useApi<MiProjectBranch>(
    `/courses/projects/${route?.params?.club?.id}`
  )

  const [selectedCourse, setSelectedCourse] = useState<ProjectCourse>()

  const {
    ratingHoles,
    setCourseRatingHoles,
    holes,
    setRatingHoleData,
    createRatingTemplate,
  } = useRatingTemplate()

  const par = ratingHoles.reduce((acc, hole) => acc + (hole?.par || 0), 0)

  useEffect(() => {
    if (!selectedCourse) {
      setSelectedCourse(data?.courses[0])
    }
  }, [data])

  const createCompositeCourseFn = () => {
    const courseRatingHoles: UpdateRatingHole[] =
      selectedCourse?.holes?.map((hole) => ({
        ...hole,
        courseName: selectedCourse?.name,
        courseId: selectedCourse?.id,
        courseHoleNumber: +hole.courseHoleNumber,
        par: +hole.par,
      })) ?? []

    if (!courseRatingHoles?.length) return
    setCourseRatingHoles(courseRatingHoles)
  }

  const handleHolePress = (hole: UpdateRatingHole) => {
    setRatingHoleData(hole)
  }

  const handleCourseChange = (course: ProjectCourse) =>
    setSelectedCourse(course)

  const createRating = async () => {
    const allHolesSelected = ratingHoles.every((hole) => hole.id)
    if (!allHolesSelected) {
      const error = 'Please select all holes for the composite course.'
      openSnackbar(error)
      throw error
    }

    const uniqueHoles = getCountOfUniqueHoleIds(ratingHoles)
    if (holes === 9) {
      if (uniqueHoles < 3) {
        const error = '3 or more unique holes required for a 9 hole rating.'
        openSnackbar(error)
        throw error
      }
    } else {
      if (uniqueHoles < 6) {
        const error = '6 or more unique holes required for an 18 hole rating.'
        openSnackbar(error)
        throw error
      }
    }
    const template = await createRatingTemplate(+route?.params?.club.id)
    try {
      navigation.navigate('ClubManagement', {
        screen: 'CreateRating',
        template,
        holeIds: template.holeIds,
        club: route.params.club,
      })
    } catch (err) {
      console.log(err)
      openSnackbar(err)
    }
  }

  return (
    <TwoComponentPage
      navigateBack={() => goBack()}
      RightComponent={
        <S.HoleSelectorContainer>
          <HoleSelector
            courses={data?.courses || []}
            selectedCourse={selectedCourse}
            createCompositeCoursePress={createCompositeCourseFn}
            handleDropdownChange={handleCourseChange}
            handleHolePress={handleHolePress}
            selectedHoles={ratingHoles}
          />
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          >
            <RoundedButton
              title="Create Composite Course"
              onPress={createRating}
            />
          </div>
        </S.HoleSelectorContainer>
      }
      LeftComponent={
        <S.HoleContainer>
          <S.FlexColumn>
            <S.FlexRow style={{ gap: 24 }}>
              <S.FlexColumn style={{ width: 'auto' }}>
                <CourseNameInput sublabel="This will be the name that appears on all forms. For example 5 or 10-års värdering." />
                <Spacer height={40} />
                <RatingHoleCountSelector />
              </S.FlexColumn>
              <S.FlexColumn style={{ flexGrow: 1 }}>
                <S.InputLabel
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Total par
                </S.InputLabel>
                <S.InputSubLabel>
                  The total par of the holes selected.
                </S.InputSubLabel>
                <div
                  style={{
                    fontSize: 34,
                    fontWeight: 500,
                    marginLeft: par > 0 ? 0 : 8,
                    flexGrow: 1,
                  }}
                >
                  {par > 0 ? par : '-'}
                </div>
              </S.FlexColumn>
            </S.FlexRow>
            <Spacer height={40} />
            <RatingHoles />
          </S.FlexColumn>
        </S.HoleContainer>
      }
      //title={`Configure Rating Par: ${par}`}
      title="Create Composite Course"
    />
  )
}

export default SelectHoles
