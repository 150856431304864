import * as S from './styles'

import React, { useState } from 'react'

import { AdjustmentInput } from '../input'

interface StepperContainerProps {
  label: string
  children?: any
  InputComponent?: JSX.Element
  secondaryLabel?: string
  width?: number | string
  adjustment?: number | string
  adjustmentKey?: any
  lz?: number
  duration?: number
  notExpandible?: boolean
  inputType?: string
  ResetAdjComponent?: JSX.Element | null
}

const StepperContainer = ({
  label,
  children,
  InputComponent,
  secondaryLabel,
  width,
  adjustment,
  adjustmentKey,
  duration = 500,
  notExpandible,
  inputType,
  ResetAdjComponent,
}: StepperContainerProps) => {
  const [showUpdate, setShowUpdate] = useState(!!adjustment)
  const [showAnimation, setShowAnimation] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const handleUpdatePress = () => {
    setShowAnimation(true)
    setTimeout(() => {
      setShowUpdate(!showUpdate)
      setShowAnimation(false)
      // setAdjustmentValue(
      //   adjustmentKey,
      //   {
      //     value: adjustment,
      //   },
      //   view.shot,
      //   true
      // );
    }, duration)
  }

  return (
    <div style={{ width }}>
      <S.InputContainer>
        <div
          style={{ display: 'flex', flexDirection: 'column', marginRight: 15 }}
        >
          {!notExpandible && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                // cursor: "pointer",
              }}
              // onClick={() => setExpanded(!expanded)}
            >
              {/* {!expanded ? (
                <MaterialIcon name="keyboard-arrow-right" size={20} />
              ) : (
                <MaterialIcon name="keyboard-arrow-down" size={20} />
              )} */}
              <S.Label>{label}</S.Label>
            </div>
          )}
          {notExpandible && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <S.Label>{label}</S.Label>
            </div>
          )}
          {secondaryLabel && (
            <span
              style={{
                maxWidth: '100%',
                textAlign: 'left',
                alignSelf: 'flex-start',
                marginTop: 4,
                fontSize: 15,
              }}
            >
              {secondaryLabel}
            </span>
          )}
        </div>
        <S.ValueContainer>
          <S.NewValueContainer>{InputComponent}</S.NewValueContainer>
          <S.OriginalValueContainer style={{ alignContent: 'center' }}>
            {ResetAdjComponent}
          </S.OriginalValueContainer>
        </S.ValueContainer>
      </S.InputContainer>
      {expanded ? (
        <div>
          <S.InputContainer>
            <S.Label>Initial value</S.Label>
            <AdjustmentInput type={inputType} value={adjustment} isReadonly />
          </S.InputContainer>
          <S.InputContainer>
            <S.Label>Confirmed value</S.Label>
            <AdjustmentInput type={inputType} value={adjustment} isReadonly />
          </S.InputContainer>
        </div>
      ) : null}
      {/* {showUpdate && (
        <S.InputContainer
          style={{ position: "relative" }}
          showAnimation={showAnimation}
        >
          <S.Label>LiveCR Update</S.Label>
          <AdjustmentInput
            type="number"
            value={adjustment}
            disabled
            style={{
              backgroundColor: theme.colors.updated,
              marginLeft: "auto",
            }}
          />
          <FeatherIcon
            name="check"
            size={28}
            style={{
              position: "absolute",
              right: -32,
              color: "rgb(0, 167, 122)",
              cursor: "pointer",
            }}
            onClick={handleUpdatePress}
          />
        </S.InputContainer>
      )} */}
      {children}
    </div>
  )
}

export default StepperContainer
