import theme from '@app/ui/theme'
import styledNative from 'styled-components/native'
import styled from 'styled-components'

export const DateViewContainer = styledNative.TouchableOpacity<{}>``

export const DateContainer = styled.p<{}>`
  display: inline;
  font-weight: 600;
  margin-top: auto;
  margin-bottom: auto;
  color: rgba(0, 0, 0, 0.8);
`

export const TimeContainer = styled.p<{}>`
  display: inline;
  font-weight: 800;
  font-size: 0.9em;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5em;
`
