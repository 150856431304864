import { Gender, Golfer } from '@app/evaluation-core/types'
import {
  getAdjustmentValue,
  getGenderFromGolferType,
} from '@app/evaluation-core'

import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import { AdmFactorTextContainer } from '../../components/AdjustmentFactors.styles'
import React from 'react'
import { getGolferPlayerFromShortName } from '@app/evaluation-core/actions/evaluationActions'
import { getPlayerShots } from '@common/hooks/getPlayerShots'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

enum BunkersExist {
  'DO_NOT_EXIST' = 0,
  'EXISTS' = 1,
}

const BUNKERS_FACTORS = (
  bunkerDepthRating: number,
  shots: number
): AdmFactorGroup[] => [
  {
    factors: [
      {
        label: 'Q*',
        itemKey: 'bunk_squeeze',
        totalKey: 'bunk_squeeze_total',
        format: 'shot',
        type: 'landingZones',
        showOr: true,
        items: [
          {
            value: 1,
            text: `If obstacle SQUEEZE occurs because the distance between bunkers that border both sides of the fairway landing zone is less than 30 yards.`,
          },
          {
            value: 2,
            text: `If obstacle SQUEEZE occurs because the distance between bunkers that border both sides of the fairway landing zone is less than 20 yards.`,
          },
        ],
      },
      {
        label: 'C*',
        itemKey: 'bunk_carry',
        format: 'shot',
        totalKey: 'bunk_carry_total',
        type: 'landingZones',
        items: [
          {
            value: 1,
            text: `If the player must CARRY a significant bunker(s) to reach a fairway landing zone. For the scratch player, the bunker(s) must be near the start of the fairway landing zone. For the bogey player, the bunker(s) may be anywhere along the line of play.`,
          },
        ],
      },
      {
        label: '',
        itemKey: 'bunk_carry_green',
        format: 'golfer',
        type: 'green',
        shouldRender: (adjustments, view) => {
          const isScratchPlayer =
            getGolferPlayerFromShortName(view.golfer) === Golfer.SCRATCH
          const gtIsGreaterThanFive =
            (getAdjustmentValue(
              'golfer',
              view,
              'gt_final',
              adjustments
            ) as number) >= 5
          return isScratchPlayer ? gtIsGreaterThanFive : true
        },
        items: [
          {
            value: 1,
            text: `If the player must CARRY a bunker(s) that protects a significant portion of the green. For the scratch player, the bunker(s) must closely border the green and Green Target rating must be 5 or greater. For the bogey player, the bunker(s) may be anywhere along the line of play with no minimum Green Target rating.`,
          },
        ],
      },
      {
        label: 'E*',
        itemKey: 'bunk_extreme',
        groupId: 'extreme',
        totalKey: 'bunk_extreme_total',
        format: 'shot',
        type: 'landingZones',
        items: [
          {
            value: 1,
            text: `If punitive bunker features make recovery from fairway EXTREMELY difficult (e.g. pot bunkers, stacked sod bunker faces, extreme rough interferes with swing, very large bunker or series of bunkers causing long carries over sand, etc.) To qualify, a bunker must be closely bordering the green or near the fairway landing zone.`,
          },
        ],
      },
      {
        label: '',
        itemKey: 'bunk_extreme2',
        groupId: 'extreme',
        format: 'shot',
        type: 'landingZones',
        items: [
          {
            value: 2,
            text: `If features listed above impact a fairway bunker and restrict options to playing out sideways or backwards causing substantial loss of distance on the shot.`,
          },
        ],
      },
      {
        label: '',
        itemKey: 'bunk_extreme_green',
        format: 'hole',
        type: 'green',
        items: [
          {
            value: 1,
            text: `If punitive bunker features make recovery from the greenside bunkers EXTREMELY difficult (e.g. pot bunkers, stacked sod bunker faces, extreme rough interferes with swing, very large bunker or series of bunkers causing long carries over sand, etc.) To qualify, a bunker must be closely bordering the green or near the fairway landing zone.`,
          },
        ],
      },
      {
        label: 'D',
        itemKey: 'bunk_depth_rating',
        format: 'golfer',
        items: [
          {
            selected: true,
            value: bunkerDepthRating,
            renderText: (adjustment, view) =>
              getGenderFromGolferType(view.golfer) === Gender.MEN ? (
                <AdmFactorTextContainer>
                  <div style={{ marginLeft: 15 }}>
                    <span>
                      If the DEPTH of greenside bunkers is more than 3 feet.
                      Consider their number, location, and the difficulty of
                      recovery – see the underneath "TABLE VALUE"
                    </span>
                  </div>
                </AdmFactorTextContainer>
              ) : (
                <AdmFactorTextContainer>
                  <div style={{ marginLeft: 15 }}>
                    <span>
                      If the DEPTH of greenside bunkers is more than 2 feet.
                      Consider their number, location, and the difficulty of
                      recovery – see the underneath "TABLE VALUE"
                    </span>
                  </div>
                </AdmFactorTextContainer>
              ),
          },
        ],
      },

      {
        label: 'N',
        itemKey: 'bunk_no',
        format: 'golfer',

        items: [
          {
            value: -1,
            shouldRender: (adjustment, view) => shots <= 1,
            text: 'Reduce the table value by one on a one-shot hole because there is no fairway bunker.',
          },
          {
            value: -1,
            shouldRender: (adjustment, view) => shots >= 2,
            text: 'Reduce the table value by one on a two (or more) shot hole if there are NO fairway bunkers near a landing zone for the scratch player or anywhere along the line of play for the bogey player. Greenside bunkers do not count as fairway bunkers unless the player has a landing zone just short of the green and the greenside bunkers are near that landing zone',
          },
        ],
      },
      {
        label: '2',
        itemKey: 'bunk_two',
        format: 'golfer',
        shouldRender: (adjustments, view) =>
          getAdjustmentValue('hole', view, 'Par', adjustments) === 5,
        items: [
          {
            value: 1,
            text: `If there are fairway bunkers near two (2) or more landing zones on a three (or more) shot hole.`,
          },
        ],
      },
    ],
  },
]

export const Bunkers = () => {
  const adjustments = useAdjustment()
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  const shots = getPlayerShots()

  if (!evaluation) return null

  const bunkDepth = adjustments
    ? (getAdjustmentValue(
        'golfer',
        view,
        'bunk_depth_rating',
        adjustments
      ) as number)
    : 3

  return (
    <Adjustment
      useLz={false}
      factors={BUNKERS_FACTORS(bunkDepth, shots)}
      parameters={[
        {
          adjustmentKey: 'bunk_exist_default',
          format: 'hole',
          type: 'select',
          label: 'Bunkers Exist Default',
          selectorValues: [BunkersExist.DO_NOT_EXIST, BunkersExist.EXISTS],
          selectorLabels: ['Do Not Exist', 'Bunkers Exist'],
          notExpandible: true,
        },
        {
          adjustmentKey: 'bunk_exist',
          format: 'golfer',
          type: 'select',
          label: 'Bunkers Exist (Player Specific)',
          selectorValues: [BunkersExist.DO_NOT_EXIST, BunkersExist.EXISTS],
          selectorLabels: ['Do Not Exist', 'Bunkers Exist'],
          notExpandible: true,
        },
        {
          label: 'Perimeter closely bordered by bunkers ',
          adjustmentKey: 'bunkerFractionValue',
          format: 'hole',
          stepperValues: [1, 5],
          stepDefault: 1,
          unit: 'yards',
        },
        {
          type: 'numeric-input',
          label: 'Fraction of Green Closely Bordered by Bunkers',
          adjustmentKey: 'bunkerFractionPercentage',
          format: 'hole',
          inputDisabled: true,
          inputType: 'text',
          notExpandible: true,
          unit: '%',
        },
        {
          type: 'numeric-input',
          label: 'Perimeter of Green',
          adjustmentKey: 'gt_perimeter',
          format: 'hole',
          inputType: 'text',
          notExpandible: true,
          unit: 'yards',
        },
        {
          type: 'numeric-input',
          label: 'Green Target Rating',
          adjustmentKey: 'gt_rating',
          format: 'golfer',
          inputDisabled: true,

          notExpandible: true,
        },
        {
          type: 'table-value',
          label: 'Greenside Bunker Rating',
          adjustmentKey: 'bunk_rating',
          format: 'golfer',

          inputDisabled: true,
        },
        {
          type: 'numeric-input',
          label: 'Bunker Depth',
          adjustmentKey: 'bunk_depth',
          notExpandible: true,
          saveMode: 'hole',
          format: 'hole',
          shouldRender: (adjustments, view) =>
            !!getAdjustmentValue('hole', view, 'bunk_depth', adjustments),
        },
      ]}
    />
  )
}
