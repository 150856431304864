import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import React from 'react'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

const DOGLEG_FACTORS: AdmFactorGroup[] = [
  {
    factors: [],
  },
]

export const Dogleg = () => {
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  if (!evaluation || !view) return null

  return (
    <Adjustment
      tweener={false}
      useLz={true}
      factors={DOGLEG_FACTORS}
      parameters={[
        {
          type: 'numeric-input',
          label: 'Dogleg',
          adjustmentKey: 'dogleg',
          format: 'golfer',
        },
        {
          type: 'numeric-input',
          label: 'Forced Layup',
          adjustmentKey: 'forcedLayup',
          format: 'shot',
        },
        {
          type: 'numeric-input',
          label: 'Layup By Choice',
          adjustmentKey: 'layupByChoice',
          format: 'shot',
        },
      ]}
    />
  )
}
