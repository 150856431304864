import styled from 'styled-components'

export const HeaderText = styled.p`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`
export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: row;
`
export const OuterLowerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`
export const InnerLowerContainer = styled.div`
  flex-basis: 46%;
`

export const InfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 10px;
`

export const InfoTextContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  user-select: text !important;
`

export const VerticalInfoTextContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: start;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
`

export const ValueText = styled.p`
  font-weight: 600;
  user-select: text !important;
`
