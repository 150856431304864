import React, { useEffect } from 'react'
import {
  ContentCard,
  ContentHeading,
  ContentSeparator,
} from './Preparation.styles'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { useDispatch, useSelector } from 'react-redux'
import { CourseSettings } from '@app/evaluation-settings-menu/CourseSettings'
import { ActivityIndicator } from 'react-native'
import theme from '@app/ui/theme'
import {
  FBREF_ADJUSTMENT_UPDATE_QUEUE,
  QueueTask,
  UpdateTask,
} from '@app/evaluation-core/refs'
import { useFirebaseConnect } from 'react-redux-firebase'
import { useEvaluation } from '@common/hooks'
import { State } from '@app/state/types'
import * as S from './EditCourseSettingsModal.styles'
import Spacer from '@app/components/atoms/Spacer'

type Props = { onClose: () => void }

const EditCourseSettingsModal = ({ onClose }: Props) => {
  const dispatch = useDispatch()
  const { currentId } = useEvaluation()
  const [closeWhenFinished, setCloseWhenFinished] = React.useState(false)

  useFirebaseConnect([
    { path: FBREF_ADJUSTMENT_UPDATE_QUEUE(currentId as string) },
  ])

  const loadingCourseSettings = useSelector(({ firebase: { data } }: State) => {
    const updateQueue = data.queues?.updateAdjustments.adjustments as {
      [key: string]: { [key: string]: QueueTask<UpdateTask> }
    }

    if (!updateQueue) return false

    const arr = Object.values(updateQueue).map((item) => {
      if (item === null) {
        return []
      }
      const task = Object.values(item)[0]
      return task
    }) as QueueTask<UpdateTask>[]

    return arr.some((item) => item?.data?.type === 'course')
  })

  const handleOnClose = async () => {
    setCloseWhenFinished(true)
  }
  useEffect(() => {
    if (closeWhenFinished && !loadingCourseSettings) {
      onClose()
    }
  }, [closeWhenFinished, loadingCourseSettings])

  return (
    <ContentCard style={{ width: '50%', position: 'relative' }}>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: -8, right: -8 }}>
          <S.CloseButton onClick={onClose}>X</S.CloseButton>
        </div>
        <ContentHeading>Course settings</ContentHeading>

        <ContentSeparator />
        <CourseSettings onClose={handleOnClose} withValidationLabels inModal />
      </div>
      {loadingCourseSettings && (
        <S.LoadingOverlay>
          <ActivityIndicator color={theme.colors.secondary2} size={36} />
          <Spacer height={4} />
          <S.LoadingText>Recalculating</S.LoadingText>
        </S.LoadingOverlay>
      )}
    </ContentCard>
  )
}

export default EditCourseSettingsModal
