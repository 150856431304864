import React from 'react'
import { View } from 'react-native'
import { useEvaluation } from '@common/hooks'

const Map: React.FC = () => {
  const { courseId } = useEvaluation()
  return (
    <View
      style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}
    >
      <iframe
        src={`https://www.livegis.com/course-map.php?course=${courseId}`}
        style={{ width: '100%', height: '100%' }}
      />
    </View>
  )
}

export default Map
