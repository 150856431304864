import theme from '@app/ui/theme'
import styled from 'styled-components'
import styledNative from 'styled-components/native'

export const ModalContainer = styled.div<{ visible: boolean }>`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999999;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

export const ModalContentContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`

export const ModalContent = styled.div`
  text-align: center;
  border-radius: 12px;
  border-color: transparent;
  background-color: ${theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 16px;
`

export const ModalTitleContainer = styled.div`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const ModalTitle = styledNative.Text`
  font-size: 23px;
  font-weight: 460;
  letter-spacing: 0.4px;
  margin-bottom: 32px;
  flex-grow: 1;
  text-align: left;
`

export const TableCell = styled.td`
  padding: 5px;
  border-bottom: solid 1px #e4e4e4;
  text-align: center;
`
export const TitleTableCell = styled(TableCell)`
  font-weight: 900;
`

export const RatedTableCell = styled(TableCell)`
  background-color: #8b8b8b;
  color: #fff;
  padding: 5px;
  border-bottom: solid 1px #e4e4e4;
  border-left: solid 2px #000;
  border-right: solid 2px #000;
  max-width: 40px;
`

export const FixedTableCell = styled(TableCell)`
  background-color: #e4e4e4;
  padding: 5px;
  border-bottom: solid 1px #e4e4e4;
  border-right: solid 1px #e4e4e4;
  max-width: 40px;
`

export const ScorecardTableCell = styled(TableCell)`
  background-color: #e4e4e4;
  padding: 5px;
  border-bottom: solid 1px #e4e4e4;
  border-left: solid 1px #e4e4e4;
  border-right: solid 1px #e4e4e4;
  max-width: 40px;
`

export const LengthTitle = styled.div`
  text-orientation: upright;
  rotate: -85deg;
  transform-origin: left center;
  translate: 15px 5px;
  white-space: nowrap;
`
