import { media } from '@app/ui'
import styled from 'styled-components'
import theme from '@app/ui/theme'

export const HeadingContainer = styled.div`
  background-color: ${theme.colors.white};
  width: calc(60% - 30px);
  ${media.tablet} {
    width: calc(100% - 40px);
  }
  padding: 20px;
  padding-top: 50px;
  padding-left: 40px;
  padding-bottom: 0;
  display: flex;
  flex: 1;
  justify-content: flex-start;
`

export const Heading = styled.h2`
  font-size: 26px;
  font-weight: 300;
`

export const ClubContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-top: 10px;
`

export const ClubHeader = styled.div``

export const ClubSubHeader = styled.div`
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > div > span,
  a {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-left: 15px;
    margin-right: 15px;
    line-height: 22px;
  }

  & > a {
    color: ${theme.colors.primary};
    font-weight: 460;
  }
`

export const ClubSubHeaderCountryFlag = styled.div`
  display: flex;
  align-items: center;
  width: 22px;
  height: 22px;
`

export const ClubSubHeaderSeparator = styled.div`
  width: 1px;
  height: 22px;
  background-color: rgba(0, 0, 0, 0.3);
`

export const ClubLogo = styled.div`
  height: 70px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ClubTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 460;
  letter-spacing: 0.4px;
  color: #242c2b;
  display: block;
  line-height: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
`

export const ClubSideCol = styled.div`
  width: 116px;
`
export const ClubStatusCol = styled.div`
  width: auto;
`

export const ClubMainCol = styled.div`
  width: 100%;
`

export const ClubsNoResults = styled.div`
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  opacity: 0.7;
  display: flex;
  flex-direction: row;

  & > span {
    margin-left: 10px;
    line-height: 36px;
    height: 36px;
    display: block;
  }
`
