import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import moment from 'moment'
import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

import { ProjectCourse, ProjectHole } from '../../../types/Project'
import HoleButton from '../../atoms/HoleButton'
import Spacer from '../../atoms/Spacer'
import * as S from './styled'
import { useRatingTemplate } from '../../../pages/CreateRatingTemplatePage/tabs/SelectHoles/hooks/useRatingTemplate'
import { RatingHole } from '../../../pages/CreateRatingTemplatePage/tabs/SelectHoles/hooks/useRatingTemplate/types'

type Props = {
  courses: ProjectCourse[]
  selectedCourse: ProjectCourse | undefined
  handleDropdownChange: (course: ProjectCourse) => void
  handleHolePress: (
    hole: RatingHole,
    courseName: string,
    courseId: string,
    holeIsSelected: boolean
  ) => void
  selectedHoles: RatingHole[]
  createCompositeCoursePress: () => void
}

const HoleSelector: FunctionComponent<Props> = ({
  courses,
  selectedCourse,
  handleDropdownChange,
  handleHolePress,
  createCompositeCoursePress: rateCoursePress,
  selectedHoles,
}) => {
  const { setRatingHoleData, ratingHoles } = useRatingTemplate()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1524px)' })
  const overlayRef = useRef() as React.MutableRefObject<HTMLDivElement>

  return (
    <S.OuterContainer>
      <div>
        <Spacer height={16} />
        <S.MiddleContainer>
          <div>
            <S.SelectorText>Course selector</S.SelectorText>
            <Spacer height={8} />
            <RoundedButton title="Include all 18" onPress={rateCoursePress} />
            <Spacer height={8} />
            <div style={{ display: 'none', flexDirection: 'row', gap: 8 }}>
              <RoundedButton
                title="Include front 9"
                onPress={rateCoursePress}
                style={{ flexGrow: 1 }}
              />
              <RoundedButton
                title="Include back 9"
                onPress={rateCoursePress}
                style={{ flexGrow: 1 }}
              />
            </div>
          </div>
        </S.MiddleContainer>
        <Spacer height={16} />
        <S.MiddleContainer>
          <DropDownMenu
            width={390}
            tablet={isTabletOrMobile}
            value={selectedCourse?.name}
            reference={overlayRef}
            relative
          >
            {courses.map((course) => (
              <div
                key={course.id}
                onClick={() => {
                  handleDropdownChange(course)
                  if (overlayRef?.current) {
                    overlayRef.current.click()
                  }
                }}
                style={{
                  padding: '8px 16px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #CECECE',
                }}
              >
                {course.name} - {course.holeCount} holes
              </div>
            ))}
          </DropDownMenu>
        </S.MiddleContainer>
        <Spacer height={16} />
        {selectedCourse && (
          <S.ContentContainer>
            {selectedCourse.holes.map((hole, index) => {
              const holeIsSelected = ratingHoles.some(
                (ratingHole) => ratingHole.physicalID === hole.physicalID
              )

              return (
                <S.HoleContainer
                  key={hole.physicalID}
                  style={{ marginBottom: 24 }}
                >
                  <HoleButton
                    key={hole.courseHoleNumber}
                    holeNr={+hole.courseHoleNumber}
                    ghNr={+hole.globalHoleNumber}
                    par={+hole.par}
                    selected={!!holeIsSelected}
                    onPress={() => {
                      setRatingHoleData({
                        courseName: selectedCourse.name,
                        courseHoleNumber: +hole.courseHoleNumber,
                        id: hole.physicalID,
                        par: +hole.par,
                      })
                      handleHolePress(
                        {
                          ...hole,
                          courseName: selectedCourse.name,
                          courseHoleNumber: +hole.courseHoleNumber,
                          ratingHoleNumber: 0,
                          par: +hole.par,
                        },
                        selectedCourse.name,
                        selectedCourse.id,
                        !!holeIsSelected
                      )
                    }}
                  />
                </S.HoleContainer>
              )
            })}
          </S.ContentContainer>
        )}
      </div>
      {!isTabletOrMobile && <Spacer height={32} />}
    </S.OuterContainer>
  )
}

export default HoleSelector
