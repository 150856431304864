import theme from '@app/ui/theme'
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  text-align: center;
  cursor: pointer;
`

export const UnderlineContainer = styled.div`
  height: 8px;
`

export const Underline = styled.div`
  height: 8px;
  background-color: ${theme.colors.secondary2};
  border-radius: 4px;
`

export const SelectedUnderline = styled.div`
  height: 1px;
  background-color: ${theme.colors.light4};
  position: relative;
  top: 2.5px;
`

export const Header = styled.span<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected ? theme.colors.secondary2 : theme.colors.primary};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
`
