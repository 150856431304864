import _styled from 'styled-components/native'
import styled from 'styled-components'
import theme from '@app/ui/theme'

interface EvalSideBarProps {
  animLeft?: number
  collapsed?: boolean
  inAdjustment: boolean
}

const shadowWidth = 15
const borderRightWidth = 1
const collapsedSize = 130
const collapsedSizeString = '130px'

export const EvalSideBar = styled.div<EvalSideBarProps>`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.colors.light};
  width: ${(props) => (props.collapsed ? collapsedSizeString : '')};
  min-width: ${(props) => (props.collapsed ? collapsedSizeString : '260px')};
  background-color: white;
  transition: all 0.3s;
  animation: sidebar-in 0.3s ease;
  @keyframes sidebar-in {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  box-shadow: 0 0px 12px 0 ${theme.colors.dark1};
  overflow: hidden;
  height: ${({ inAdjustment }) => (inAdjustment ? '100%' : 'auto')};
`

export const EvalSideBarTop = styled.div<EvalSideBarProps>`
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.colors.light};
  background-color: ${theme.colors.white};
  padding: 0px 8px;
  overflow-wrap: never;
  & h2 {
    letter-spacing: 0.6;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    display: block;
    width: 100%;
    font-weight: 460;
  }
  width: ${(props) =>
    props.collapsed
      ? collapsedSize - shadowWidth - borderRightWidth + 'px'
      : ''};
  min-width: ${(props) =>
    (props.collapsed ? collapsedSize : 270) - shadowWidth - borderRightWidth}px;
  position: absolute;
  z-index: 1;
  border-right-color rgb(209, 220, 226);
  border-right-style solid;
  border-right-width 0.714286px;
`

export const EvalSideBarTopCont = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`

export const EvalSideBarMain = styled.div<{ inAdjustment: boolean }>`
  display: flex;
  overflow-y: ${({ inAdjustment }) => (inAdjustment ? 'auto' : 'hidden')};
  flex-direction: column;
  margin-top: 140px;
`

export const EvalSideBarGroupRow = styled.div`
  height: 40px;
  background-color: ${theme.colors.light};

  & a {
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.8);
    display: block;
    line-height: 40px;
    padding-left: 20px;
    padding-right: 10px;
    height: 100%;
    font-size: 14px;
    font-weight: 400;
    text-wrap: no-wrap;
    text-transform: uppercase;
  }

  &.active a {
    background-color: #666;
    color: ${theme.colors.white};
  }
`

export const EvalSideBarMenus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
`

export const HoleButtonContainter = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
`

export const CollapsedSideBarContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const OptionInput = styled.input`
  margin: 0;
  padding: 0;
  width: 45px;
  height: 24px !important;
  border: none;
  border: 1px solid ${theme.colors.dark1};
  border-radius: 5px;
  outline: none;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  font-family: 'Proxima Nova';

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
`

export const SideBarToggleButtonContainer = styled.div`
  min-width: 44px;
`

export const CollapsedInputContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
  padding: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const CourseOptionItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
`

export const ParLengthLabel = styled.span`
  font-family: 'Proxima Nova';
  font-size: 22px;
  color: ${theme.colors.primary};
  font-weight: 600;
  margin-right: 4px;
`

export const ParLengthContainer = styled.div`
  user-select: none;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const ParLengthValue = styled.span`
  font-family: 'Proxima Nova';
  font-size: 23px;
  color: ${theme.colors.primary};
  font-weight: 600;
`

export const CourseOptionItemInput = styled.input`
  -webkit-appearance: none;
  height: 23px !important;
  line-height: 28px !important;
  overflow: hidden;
  font-size: 15px;
  padding: 0px !important;
  &:disabled {
    opacity: 1;
  }
`

export const CollapsedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const LiveCRInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.baseline}px;
`

export const LiveCRInfoDivider = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${({ theme }) => theme.colors.light};
`

export const LiveCRInfoTextContainer = styled.div`
  width: 90%;
  margin: 4px 0;
`

export const LiveCRInfoText = styled.div``

export const LiveCRInfoValue = styled.div`
  display: inline;
  font-weight: 600;
`

export const LiveCRInfoButtonContainer = styled.div`
  margin: 4px 0;
  width: 80%;
`

interface CourseOptionViewProps {
  justifyInnerContent?: string
}

export const CourseOptionView = styled.div<CourseOptionViewProps>`
  user-select: none;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  width: 100%;

  & > div {
    display: flex;
    flex: 1;
    justify-content: ${(props) => props.justifyInnerContent ?? 'space-between'};
    align-items: center;
  }

  & > div > div {
  }

  & span {
    font-size: 15px;
    font-weight: 460;
    color: rgba(0, 0, 0, 0.8);
    float: left;
    line-height: 24px;
  }

  & input {
    width: 45px;
    height: 36px;
    border: none;
    border: 1px solid ${theme.colors.dark1};
    border-radius: 5px;
    outline: none;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    font-weight: bold;
    font-family: 'Proxima Nova';
    align-self: flex-end;
    -webkit-appearance: none;
    appearance: none;
    opacity: 1;
  }
  & select {
    width: 60px;
    height: 36px;
    border: none;
    background-color: ${theme.colors.light2};
    border-radius: 5px;
    outline: none;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    font-weight: bold;
    font-family: 'Proxima Nova';
    align-self: flex-end;
    text-align-last: center;
    padding-left: 9px;
    border-right: 10px solid ${theme.colors.light2};
  }
`
