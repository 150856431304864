import images from '@app/images'
import Overlay from '@app/overlay/Overlay'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import theme from '@app/ui/theme'
import React from 'react'

import {
  EmphasizedText,
  ImageContainer,
  InnerContainer,
  MainContainer,
} from './ResetHoleConfirmationModal.styles'

interface OverlayProps {
  showModal: boolean
  closeModal: () => void
  acceptFunction: () => void
  hole?: number
}

export const ResetHoleConfirmationModal = ({
  showModal,
  closeModal,
  hole,
  acceptFunction,
}: OverlayProps) => {
  if (!showModal) return null

  return (
    <Overlay closeAction={closeModal} zIndex={300}>
      <MainContainer>
        <InnerContainer>
          <ImageContainer>
            <images.svg.Warning height="100%" width="100%" />
          </ImageContainer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <EmphasizedText
              style={{
                fontSize: 24,
                fontWeight: 800,
                textAlign: 'center',
              }}
            >
              {`Reset adjustments`}
              {hole ? ` on Hole ${hole}?` : ` on all unlocked holes`}
            </EmphasizedText>
            <span
              style={{
                textAlign: 'center',
                marginTop: 12,
              }}
            >
              This action will remove all changes made to{' '}
              {hole
                ? 'the current hole in view'
                : 'all holes that are not locked'}
              . Are you sure you wish to continue?
            </span>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <RoundedButton
              style={{ flex: 3, backgroundColor: theme.colors.warning }}
              title="Yes, reset Hole"
              textStyle={{ lineHeight: 0, textTransform: 'none' }}
              onPress={acceptFunction}
            />
            <div style={{ margin: 16, marginBottom: 0 }}></div>
            <RoundedButton
              style={{
                flex: 1,
                backgroundColor: theme.colors.white,
                border: `1px solid ${theme.colors.secondary}`,
              }}
              textStyle={{
                lineHeight: 0,
                textTransform: 'none',
                color: theme.colors.secondary,
              }}
              onPress={closeModal}
              title="Go back"
            />
          </div>
        </InnerContainer>
      </MainContainer>
    </Overlay>
  )
}
