import Spacer from '@app/components/atoms/Spacer'
import { black } from '@app/ui/colors'
import React from 'react'
import * as S from './Tees.styles'

function Tees(props) {
  const { title, slopeRating, courseRating, onClick } = props
  return (
    <S.ContentColorCard onClick={onClick}>
      <S.TitleContainer>
        <S.TeeTitle>{title}</S.TeeTitle>
      </S.TitleContainer>
      <S.Line />
      <S.CourseInfo>
        <S.TeeRating>
          {`Course rating:  `}
          <span>{slopeRating}</span>
        </S.TeeRating>
        <Spacer height={16} />
        <S.TeeRating>
          {`Slope rating:  `}
          <span>{courseRating}</span>
        </S.TeeRating>
      </S.CourseInfo>
    </S.ContentColorCard>
  )
}

export default Tees
