export const SET_EVALUATION = 'SET_EVALUATION'
export const SET_COURSE_SETTINGS = 'SET_COURSE_SETTINGS'
export const SET_MODIFICATIONS = 'SET_MODIFICATIONS'
export const SET_ADJUSTMENTS = 'SET_ADJUSTMENTS'
export const SET_SELECTED_HOLE = 'SET_SELECTED_HOLE'
export const SET_SELECTED_TEE = 'SET_SELECTED_TEE'
export const SET_SELECTED_LZ = 'SET_SELECTED_LZ'
export const SET_SELECTED_GOLFER = 'SET_SELECTED_GOLFER'
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP'
export const SET_COLLAPSED_TEES = 'SET_COLLAPSED_TEES'
export const ADD_COLLAPSED_TEE = 'ADD_COLLAPSED_TEE'
export const SET_COLOR = 'SET_COLOR'
export const RESET = 'RESET'

export default {
  SET_EVALUATION,
  SET_ADJUSTMENTS,
  SET_SELECTED_HOLE,
  SET_SELECTED_TEE,
  SET_SELECTED_LZ,
  SET_SELECTED_GOLFER,
  SET_SELECTED_GROUP,
  SET_COLLAPSED_TEES,
  ADD_COLLAPSED_TEE,
  SET_COLOR,
  RESET,
  SET_MODIFICATIONS,
}
