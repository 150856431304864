import React from 'react'
import { FlexColumn, InputLabel } from '../../styled'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { HoleSelection } from './styled'
import theme from '@app/ui/theme'
import { useRatingTemplate } from '../../hooks/useRatingTemplate'

const RatingHoleCountSelector: React.FC<{}> = () => {
  const { holes, setHoles, holeIndexHandler } = useRatingTemplate()
  const [holeIndex, setHoleIndex] = holeIndexHandler

  const backgroundColor = (holeLength: number) =>
    holes === holeLength ? theme.colors.primary : theme.colors.white

  const textColor = (holeLength: number) =>
    holes === holeLength ? theme.colors.white : theme.colors.primary

  const handleHoleIndex = (index: number) => () => {
    if (holes === 9 && holeIndex === 8 && index === 18) {
      setHoles(index)
      return setHoleIndex((prevIndex) => prevIndex + 1)
    }
    if (holes === 18 && holeIndex === 17 && index === 9) {
      setHoles(index)
      return setHoleIndex(8)
    }
    setHoles(index as 9 | 18)
  }

  return (
    <FlexColumn>
      <InputLabel>Number of holes</InputLabel>
      <HoleSelection>
        <RoundedButton
          title="9 holes"
          onPress={handleHoleIndex(9)}
          style={{
            backgroundColor: backgroundColor(9),
          }}
          width="50%"
          textStyle={{
            color: textColor(9),
          }}
        />

        <RoundedButton
          title="18 holes"
          onPress={handleHoleIndex(18)}
          style={{
            backgroundColor: backgroundColor(18),
          }}
          width="50%"
          textStyle={{
            color: textColor(18),
          }}
        />
      </HoleSelection>
    </FlexColumn>
  )
}

export default RatingHoleCountSelector
