import { Modifications } from '@app/evaluation-core/modifications'
import { GolferShortName } from '@app/evaluation-core/types'
import theme from '@app/ui/theme'
import styled, { css } from 'styled-components'
import _styled from 'styled-components/native'

interface TeeContainerProps {
  collapsed: boolean
  disabled?: boolean
}

const teeContainerWidth = 'auto'

export const TeeContainer = styled.div<TeeContainerProps>`
  width: ${(props: TeeContainerProps) =>
    props.collapsed ? '50px' : `${teeContainerWidth}px`};
  margin-right: 0px;

  &.disabled {
    opacity: 0.6;
  }

  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  padding: 5px;
  padding-top: 5px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : theme.colors.primary};
  min-width: ${(props: TeeContainerProps) =>
    props.collapsed ? '50px' : teeContainerWidth};
  user-select: none;
  border-radius: 5px;
`

export const TeeRow = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1;
    text-align: center;
  }
`

interface TeeRowTeeProps {
  selected: boolean
}

export const TeeRowTee = styled(TeeRow)`
  line-height: 50px;
  font-size: 17px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: ${(props: TeeRowTeeProps) =>
    props.selected ? theme.colors.secondary2 : theme.colors.white};
  border-bottom: 3px solid
    ${(props: TeeRowTeeProps) =>
      props.selected ? theme.colors.primary : theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

export const TeeRowTeeInfo = _styled.TouchableOpacity`
  max-width: 50px !important;
  float: left;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  overflow: hidden;
`

export const TeeRowTeeLabel = _styled.TouchableOpacity`
  text-align: center;
`

export const TeeRowTeeLabelText = _styled.Text`
  line-height: 50px;
  font-size: 19px;
  font-weight: 460;
  color: #191919;
  text-align: center;
  white-space: nowrap;
`
export const TeeRowTeeNameLabelText = _styled.Text`
  line-height: 36px;
  font-size: 19px;
  font-weight: 460;
  color: #191919;
  text-align: center;
  white-space: nowrap;
`

export const TeeRowTeeExpand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  z-index: 100;
  max-width: 50px;
  cursor: pointer;
`

interface TeeRowGenderProps {
  golfer: GolferShortName
  tee: boolean
}

export const TeeRowTeeName = styled.div`
  background-color: white;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: #666;
`

export const TeeRowGender = styled(TeeRow)`
  background-color: white;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: #666;

  /* & > div {
    font-weight: 500;
  }

  & > div:first-child {
    opacity: ${(props: TeeRowGenderProps) =>
    props.tee && props.golfer.includes('M') ? 1 : 0.7};
  }

  & > div:last-child {
    opacity: ${(props: TeeRowGenderProps) =>
    props.tee && props.golfer.includes('W') ? 1 : 0.7};
  } */
`

export const TeeRowScorer = styled(TeeRow)`
  color: #666;
  height: 40px;
  line-height: 40px;
  & > div {
  }
  & > div:last-child {
    border-right: 0;
  }
  background-color: white;

  box-shadow: 0 0px 4px 0 ${theme.colors.dark1};
  border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  & > div:first-child {
    border-bottom-left-radius: 10px;
  }
  & > div:last-child {
    border-bottom-right-radius: 10px;
  }
`

interface TeeRowScorerItemProps {
  selected?: boolean
}

export const TeeRowScorerItem = styled.a<TeeRowScorerItemProps>`
  display: block;
  margin: 0 auto;
  line-height: 40px;
  font-size: 18px;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  background-color: ${(props) =>
    props.selected ? theme.colors.primary : theme.colors.white};
  color: ${(props) => (props.selected ? theme.colors.white : 'rgba(0,0,0,.6)')};
`

export const TeeRowValue = styled(TeeRow)`
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  & > div {
    line-height: 40px;
  }
  & > div:last-child {
    border-right: 0;
  }

  background: repeating-linear-gradient(
    46deg,
    #bfccd1,
    #bfccd1 1px,
    #d1dce2 1px,
    #d1dce2 9px
  );
`

export const TeeRowGroup = styled(TeeRow)`
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 48px;
  cursor: pointer;
  user-select: none;

  & > div {
    border-right: 1px solid ${theme.colors.light};
    line-height: 40px;
  }
  & > div:last-child {
    border-right: 0;
  }

  border-bottom: 1px solid ${theme.colors.light};
`

interface TeeGridProps {
  collapsed?: boolean
}

export const TeeGrid = styled.div<TeeGridProps>`
  display: flex;
  padding-bottom: 16px;
  padding-right: 16px;
  overflow-x: auto;
`

interface ValueItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  onClick?: () => void
  children?: React.ReactNode
  disabled?: boolean
  modification?: Modifications | undefined
  modificationPercentage?: number
  overrideOpacity?: boolean
  last?: boolean
  collapsed?: boolean
}

function modificationColor(type: Modifications | undefined) {
  if (!type) {
    return '#fff'
  }
  switch (type) {
    case Modifications.ADJUSTED:
      return theme.colors.yellow
    case Modifications.NONE:
      return '#FFF'
    case Modifications.UPDATED:
      return theme.colors.updated
    default:
      return theme.colors.saved
  }
}

const getModifcationGradientString = (
  modification: Modifications | undefined,
  percentage = 0
) =>
  `linear-gradient(90deg, ${modificationColor(
    modification
  )} ${percentage}%, #fff ${0}%)`

const getBackgroundColor = (
  disabled?: boolean,
  modification?: Modifications,
  modificationPercentage?: number,
  last?: boolean
) => {
  if (disabled) {
    return 'rgba(223,233,236,.4)'
  }
  if (last) {
    return theme.colors.primary
  }
  return getModifcationGradientString(modification, modificationPercentage)
}

const lastCss = css`
  outline: 3px solid ${theme.colors.primary};
  outline-offset: -3px;
`

export const ValueItem = styled.div<ValueItemProps>`
  ${({ overrideOpacity, disabled, modification, modificationPercentage }) => `
    background: ${getBackgroundColor(
      disabled,
      modification,
      modificationPercentage
    )};
    font-weight: 600;
    display: flex;
    font-size: 17px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${theme.colors.light};
    border-bottom: 1px solid ${theme.colors.light};
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    box-sizing: border-box;
    & > div:nth-child(2) {
      font-size: 14px;
      color: #666;
      margin-top: 11px;
      font-weight: 500;
      width: 12px;
      overflow: hidden;
      text-align: center;
    }
    `}
  ${({ last }) => last && lastCss};
  min-width: ${({ collapsed }) => (collapsed ? '100%' : '50px')};
`

export const DisplayText = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
`
export const ShotText = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  position: absolute;
  right: 0;
  transform: translateX(100%);
  bottom: -7px;
`

export const RevisionText = styled.span`
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 4px;
`

export const TeeColorEllipse = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 999px;
  background-color: ${(props) => props.color};
  margin-right: 12px;
  margin-top: auto;
  margin-bottom: auto;
`

export const TeeLengthContainer = styled.div`
  margin-left: auto;
`
