import React from 'react'

import {
  AdmFactorLabel,
  AdmFactorLabelContainer,
  AdmFactorLabelItem,
  AdmFactorLabelItemText,
} from './AdjustmentFactors.styles'

const PlaceHolderLabel: React.FC = () => (
  <AdmFactorLabel>
    <AdmFactorLabelContainer>
      <AdmFactorLabelItem style={{ margin: 0 }}>
        <AdmFactorLabelItemText></AdmFactorLabelItemText>
      </AdmFactorLabelItem>
    </AdmFactorLabelContainer>
  </AdmFactorLabel>
)

export default PlaceHolderLabel
