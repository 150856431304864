import React, { useState } from 'react'
import { View } from 'react-native'
import {
  MITee,
  WomenMenStatusOptions,
  Eval,
  EvaluationUserView,
} from '@app/evaluation-core/types'
import { CourseOptionView } from '@app/evaluation-settings-menu/HeaderArea.styles'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import theme from '@app/ui/theme'
import Spacer from '../../atoms/Spacer'
import * as S from './styled'

interface FilterTeesProps {
  evaluation: Eval
  view: EvaluationUserView
  filterStatusValue: MITee[]
  womenMenStatusValue: WomenMenStatusOptions
  setFilterStatusValue: React.Dispatch<React.SetStateAction<MITee[]>>
  setWomenMenStatusValue: (name: WomenMenStatusOptions) => void
}

const FilterTees: React.FC<FilterTeesProps> = ({
  filterStatusValue,
  setFilterStatusValue,
  setWomenMenStatusValue,
  womenMenStatusValue = 'all',
  evaluation,
  view,
}) => {
  const allTees = evaluation.holes[view?.hole]?.tees || []
  const upperButtonsSize = 45
  const lowerButtonsSize = 92
  const [selectedGender, setSelectedGender] =
    useState<WomenMenStatusOptions>(womenMenStatusValue)

  const getTextColor = (gender: WomenMenStatusOptions) => {
    return selectedGender === gender
      ? theme.colors.white
      : theme.colors.buttonText
  }

  const getBackgroundColor = (gender: WomenMenStatusOptions) => {
    return selectedGender === gender ? theme.colors.accept : theme.colors.light4
  }

  const genderOptions = [
    { gender: 'all', title: 'All' },
    { gender: 'men', title: 'Men' },
    { gender: 'women', title: 'Women' },
  ] as { gender: WomenMenStatusOptions; title: string }[]

  return (
    <>
      <DropDownMenu
        tablet={false}
        iconColor={theme.colors.white}
        value={'Filter Tees'}
        style={{ fontWeight: 'bold', left: 'none' }}
        width={270}
        backgroundColor={theme.colors.primary}
        color={'white'}
        relative={true}
        yAdj={40}
      >
        <CourseOptionView>
          <S.OuterContainer>
            <S.HeaderText>Gender</S.HeaderText>
            <Spacer height={6} />
            <S.UpperContainer>
              {genderOptions.map(({ gender, title }) => (
                <React.Fragment key={gender}>
                  <RoundedButton
                    textStyle={{
                      color: getTextColor(gender),
                    }}
                    width={upperButtonsSize}
                    style={{
                      backgroundColor: getBackgroundColor(gender),
                    }}
                    title={title}
                    onPress={() => {
                      setWomenMenStatusValue(gender)
                      setSelectedGender(gender)
                    }}
                  />
                  <Spacer width={14} />
                </React.Fragment>
              ))}
            </S.UpperContainer>
            <Spacer height={14} />
            <S.HeaderText>Tees</S.HeaderText>
            <Spacer height={6} />
            <View style={{ flexDirection: 'row' }}>
              <S.OuterLowerContainer>
                {allTees.map((tee) => (
                  <S.InnerLowerContainer key={tee.name}>
                    <RoundedButton
                      textStyle={{
                        color: !filterStatusValue.some(
                          (t) => t.name === tee.name
                        )
                          ? theme.colors.white
                          : theme.colors.buttonText,
                      }}
                      width={lowerButtonsSize}
                      style={{
                        backgroundColor: !filterStatusValue.some(
                          (t) => t.name === tee.name
                        )
                          ? theme.colors.accept
                          : theme.colors.light4,
                      }}
                      title={tee.name}
                      onPress={() => {
                        const newTees = filterStatusValue.filter(
                          (filterTee) => filterTee.name !== tee.name
                        )
                        setFilterStatusValue(
                          filterStatusValue.some((t) => t.name === tee.name)
                            ? newTees
                            : [...filterStatusValue, tee]
                        )
                      }}
                    />
                    <Spacer width={14} />
                    <Spacer height={7} />
                  </S.InnerLowerContainer>
                ))}
              </S.OuterLowerContainer>
            </View>
          </S.OuterContainer>
        </CourseOptionView>
      </DropDownMenu>
    </>
  )
}

export default FilterTees
