import styled, { css } from 'styled-components'
import theme from '@app/ui/theme'

export const Container = styled.div``

export const HoleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  align-content: flex-start;
  white-space: nowrap;
  width: 100%;
  overflow: scroll;

  padding: 8px;
  @media (max-width: 820px) {
    align-content: flex-start;
  }
`

export const HoleSelectorContainer = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  padding: 12px;
  @media (max-width: 820px) {
    position: relative;
    padding: 8px;
  }
`

const defaultTextStyle = css`
  font-family: 'Roboto';
  font-style: normal;
`

export const HoleTitle = styled.span`
  ${defaultTextStyle}
  color: #282828;
  font-weight: 500;
  font-size: 16px;
`
export const CourseName = styled.span`
  ${defaultTextStyle}
  font-size: 18px;
`
export const GlobalHoleNumber = styled.span`
  ${defaultTextStyle}
  color: ${theme.colors.secondary2};
  font-size: 12px;
  font-weight: 600;
`
export const Par = styled.span`
  ${defaultTextStyle}
  font-size: 12px;
`

export const HoleInformationContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Divider = styled.hr`
  max-width: 100%;
  background-color: ${theme.colors.light4};
  height: 2px;
  opacity: 0.5;
  border: none;
  padding: 0px;
  margin: 0px;
`

export const HoleTitleContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  padding-bottom: 0px;
`

export const HoleCard = styled.div<{
  index: number
  totalHoles: number
  isDragging?: boolean
  isOver?: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 208px;
  height: 130px;
  border-radius: 5px;
  background-color: ${({ isDragging, isOver }) =>
    getBackgroundColor(isDragging, isOver)};
  transition: 100ms;
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
`

const getBackgroundColor = (isDragging?: boolean, isOver?: boolean) => {
  if (isDragging) {
    return theme.colors.light
  }
  if (isOver) {
    return theme.colors.primary
  }
  return 'white'
}

export const TrashIconContainer = styled.div`
  background: ${theme.colors.warning};
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`
