import { EvalStatus } from '@app/courses/domain/ratingStatus'
import {
  EvaluationState,
  EvaluationUser,
  EvaluationUserView,
  LockStatus,
} from '@app/evaluation-core/types'

import { State } from '@app/state/types'
import { useSelector } from 'react-redux'

export default () => {
  return useSelector((state: State): LockStatus => {
    if (
      !state.user ||
      !state.user ||
      !state?.evaluation ||
      !state?.evaluation?.evaluation
    ) {
      return LockStatus.UNLOCKED
    }

    if (state?.evaluation?.evaluation.status === EvalStatus.FINALIZED) {
      return LockStatus.READ_ONLY
    }
    const view =
      state?.evaluation?.evaluation?.users[state.user.id] &&
      state?.evaluation?.evaluation?.users[state.user.id].view

    const holes = state?.evaluation?.evaluation?.holes
    if (!holes) {
      return LockStatus.UNLOCKED
    }
    const holeStatus = state?.evaluation?.evaluation?.holes[view?.hole]?.locked

    if (!holeStatus) {
      return LockStatus.UNLOCKED
    }
    return holeStatus
  })
}
