import { CourseOptionView } from '@app/components/molecules/CourseInfo/styled'
import React from 'react'
import * as S from './styled'
import CourseSettings from './tabs/CourseInformation'
import SelectHoles from './tabs/SelectHoles'
import { useRatingTemplate } from './tabs/SelectHoles/hooks/useRatingTemplate'

const CourseNameInputScreen = () => {
  const { setCourseName, courseName } = useRatingTemplate()

  return (
    <CourseOptionView>
      <div>
        <div>
          <span>Composite Course Name</span>
        </div>
        <input
          style={{ width: 300 }}
          onChange={(event) => setCourseName(event.target.value)}
          value={courseName}
        />
      </div>
    </CourseOptionView>
  )
}

const CreateRatingTemplatePage = () => {
  return (
    <>
      <S.ContentCard>
        <CourseNameInputScreen />
      </S.ContentCard>
      <S.ContentCard style={{ overflowY: 'scroll' }}>
        <CourseSettings />
      </S.ContentCard>
    </>
  )
}

const SelectHolesPage = () => {
  return <SelectHoles />
}

export { CreateRatingTemplatePage, SelectHolesPage }
