import styled from 'styled-components'

export const Header = styled.h3`
  font-family: 'Roboto', sans-serif;
`
export const TeeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const GendersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 4px;
`

export const Gender = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > label {
    color: #000;
  }
`

export const TeeHeader = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  padding: 8px;
  border-bottom: 2px solid #000;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`

export const Tee = styled.div`
  border: 1px solid #000;
  border-radius: 4px;
`

export const TeeName = styled.span<{ color?: string }>`
  text-align: center;
  color: ${(props) => props.color || '#000'};
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  font-weight: 600;
`
