import styled from 'styled-components/native'

interface VideoCoverProps {
  color?: string
}

export const VideoCover = styled.View`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${(props: VideoCoverProps) =>
    props.color ? props.color : '#000000'};
  opacity: ${(props: VideoCoverProps) => (props.color ? props.color : 0.7)};
`

export const VideoContainer = styled.View`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
`
