import React from 'react'

type SVGProps = {
  fillColor?: string
  borderColor?: string
  height?: number | string
  width?: number | string
}

const Warning = ({
  fillColor,
  borderColor = 'black',
  height,
  width,
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 176 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M173.733 127.698L101.54 7.9729C98.696 3.2563 93.5078 0.326172 87.9999 0.326172C82.492 0.326172 77.3038 3.2563 74.4592 7.97324L2.26718 127.698C-0.672224 132.573 -0.759193 138.681 2.03997 143.638C4.83981 148.595 10.115 151.674 15.8075 151.674H160.192C165.885 151.674 171.16 148.595 173.96 143.638C176.759 138.681 176.672 132.573 173.733 127.698ZM163.982 138.003C163.212 139.368 161.759 140.216 160.192 140.216H15.8075C14.2404 140.216 12.788 139.368 12.0177 138.004C11.247 136.639 11.271 134.957 12.0799 133.616L84.2726 13.8902C85.0557 12.5919 86.4839 11.7851 88.0002 11.7851C89.5162 11.7851 90.9444 12.5919 91.7275 13.8902L163.92 133.616C164.729 134.958 164.753 136.639 163.982 138.003Z"
      fill="black"
    />
    <path
      d="M88.0535 47.4688C83.6947 47.4688 80.293 49.8076 80.293 53.9539C80.293 66.6046 81.7811 84.7835 81.7811 97.4346C81.7814 100.73 84.6521 102.112 88.0538 102.112C90.6051 102.112 94.2197 100.73 94.2197 97.4346C94.2197 84.7839 95.7078 66.605 95.7078 53.9539C95.7078 49.808 92.1998 47.4688 88.0535 47.4688Z"
      fill="black"
    />
    <path
      d="M88.1587 109.449C83.4813 109.449 79.9727 113.17 79.9727 117.635C79.9727 121.994 83.481 125.821 88.1587 125.821C92.5175 125.821 96.2386 121.994 96.2386 117.635C96.2386 113.17 92.5171 109.449 88.1587 109.449Z"
      fill="black"
    />
  </svg>
)

export default Warning
