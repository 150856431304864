import { AdjustmentTypes, getAdjustmentValue } from '@app/evaluation-core'
import {
  AdmFactorButton,
  AdmFactorButtonWrapper,
  AdmFactorText,
  AdmFactorTextLabel,
} from './AdjustmentFactors.styles'
import { AdmGroupFactor, AdmGroupFactorItem } from '../types'

import { AdjustmentFactorButton } from '../styles'
import { EvaluationUserView, LockStatus } from '@app/evaluation-core/types'
import React from 'react'
import { useEvaluation } from '@common/hooks'
import useSetAdjustment from '@common/hooks/useSetAdjustment'
import useEvaluationLockedState from '@common/hooks/useEvaluationLockedState'

interface FactorButtonProps {
  button: AdmGroupFactorItem
  buttonIndex: number
  adjustments: any
  factor: AdmGroupFactor
  view: EvaluationUserView
  useLz?: boolean
  useShot?: boolean
  showTextOnly?: boolean
  hideText?: boolean
  shotIndex?: number
}

function valueToButtonText(value: number) {
  return (value > 0 ? '+' : '') + value
}

const FactorButton: React.FC<FactorButtonProps> = ({
  button,
  adjustments,
  buttonIndex,
  factor,
  view,
  shotIndex,
  showTextOnly = false,
  hideText,
}: FactorButtonProps) => {
  const { currentId } = useEvaluation()
  const holeLocked = useEvaluationLockedState()
  const [setAdjustment, setExcludedKeys] = useSetAdjustment()
  const buttonShouldNotRender =
    button.shouldRender &&
    typeof button.shouldRender === 'function' &&
    !button.shouldRender(adjustments, view)
  const canEdit = holeLocked === LockStatus.UNLOCKED

  if (buttonShouldNotRender) return null

  const adjustmentValue = getAdjustmentValue(
    factor.format as AdjustmentTypes,
    { ...view, shot: shotIndex !== undefined ? shotIndex : view.shot },
    factor.itemKey as string,
    adjustments
  )

  const parseButtonValue = (value: number | string | undefined) => {
    if (!value) return
    if (Number.isInteger(value)) {
      return value
    } else {
      if ((value as string).includes('%')) {
        return parseInt((value as string).replace('%', ''))
      }
      return value
    }
  }

  const buttonIsSelected = adjustmentValue === parseButtonValue(button.value)
  // ? button.selected
  // : adjustment[factor.itemKey as string].adjusted?.option === buttonIndex + 1;

  const getButtonText = () => {
    // const value =
    //   button.value ?? adjustment[factor.itemKey as string].adjusted?.value;
    // if (Number.isInteger(value)) {
    //   if (factor.itemKey) {
    //     return valueToButtonText(
    //       button.value ?? adjustment[factor.itemKey].adjusted?.value
    //     );
    //   }
    //   return valueToButtonText(button.value as number);
    // }
    if (typeof button.value === 'number') {
      return button.value > 0 ? `+${button.value}` : button.value
    }
    return button.value as string
  }

  const handleClick = () => {
    if (button.excludedKeys) {
      const value = adjustmentValue === button.value ? 0 : button.value
      return setExcludedKeys(
        'shot',
        { ...view, shot: shotIndex !== undefined ? shotIndex : view.shot },
        factor.itemKey as string,
        currentId as string,
        value as number,
        button.excludedKeys
      )
    }
    if (!button.disabled && factor.itemKey) {
      if (adjustmentValue === button.value) {
        return setAdjustment(
          factor.format as AdjustmentTypes,
          { ...view, shot: shotIndex !== undefined ? shotIndex : view.shot },
          factor.itemKey as string,
          currentId as string,
          0
        )
      }

      return setAdjustment(
        factor.format as AdjustmentTypes,
        { ...view, shot: shotIndex !== undefined ? shotIndex : view.shot },
        factor.itemKey as string,
        currentId as string,
        parseButtonValue(button.value) as number
      )
    }
  }
  return (
    <AdmFactorButtonWrapper key={buttonIndex}>
      {/* {factor.mode === "percentage" && factor.itemKey && (
        <AdjustmentFactorInput
          value={
            // adjustments[factor.itemKey].adjusted.value > 0
            //   ? AdjustmentSubheader[factor.itemKey].adjusted.value
            //   : ""
          }
          // defaultValue={adjustments[factor.itemKey].adjusted.value ?? ""}
          onChange={({ target: { value } }) =>
            factor.onInputChange &&
            factor.onInputChange(value, adjustments, view, shotIndex)
          }
          type="number"
          max={100}
          placeholder="%"
        />
      )} */}
      {factor.mode === 'default' ||
        (!factor.mode && factor.itemKey && button.value !== undefined && (
          <AdmFactorButton>
            <AdjustmentFactorButton
              isReadonly={factor.disabled}
              disabled={!canEdit}
              selected={buttonIsSelected}
              onClick={handleClick}
              hideButton={button.hideButton}
            >
              {button.renderButtonText
                ? button.renderButtonText()
                : getButtonText()}
            </AdjustmentFactorButton>
          </AdmFactorButton>
        ))}
      {factor.mode === 'carry' && (
        <AdmFactorButton>
          <AdjustmentFactorButton
            isReadonly={button.disabled}
            disabled={!canEdit}
            selected={buttonIsSelected}
            onClick={handleClick}
          >
            {adjustmentValue}
          </AdjustmentFactorButton>
        </AdmFactorButton>
      )}

      {!factor.factorText && !hideText && !buttonShouldNotRender && (
        <>
          {button.text && (
            <AdmFactorTextLabel style={{ padding: 15 }}>
              {button.text}
            </AdmFactorTextLabel>
          )}
          {button.renderText && button.renderText(adjustments, view)}
          {factor.selectWidget && factor.selectWidget()}
        </>
      )}
    </AdmFactorButtonWrapper>
  )
}

export default FactorButton
