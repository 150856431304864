export const config = {
  databaseURL:
    'https://redmind-mapping-default-rtdb.europe-west1.firebasedatabase.app/',
  apiKey: 'AIzaSyCbJaFy7g526OLdA3jpN6XSgHyTfaYq5EI',
  authDomain: 'redmind-mapping.firebaseapp.com',
  projectId: 'redmind-mapping',
  storageBucket: 'redmind-mapping.appspot.com',
  messagingSenderId: '646855390823',
  appId: '1:646855390823:web:8e652e26b5786cf4b586c2',
  measurementId: 'G-ENBQQ6SBS5',
}
