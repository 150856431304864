import React from 'react'
import * as S from './styled'

interface DrawerItemProps {
  className?: string
  active?: boolean
  collapsed?: boolean
  icon: any
  text: string
  onPress?: () => void
}

const DrawerItem = ({
  text,
  onPress,
  icon,
  className,
  active,
  collapsed,
}: DrawerItemProps) => {
  return (
    <S.LeftTabMenuItem onClick={onPress} className={className} active={active}>
      <S.LeftTabMenuIcon>{icon}</S.LeftTabMenuIcon>
      <S.LeftTabMenuItemText>{text}</S.LeftTabMenuItemText>
      {active && <S.Indicator collapsed={collapsed} />}
    </S.LeftTabMenuItem>
  )
}

export default DrawerItem
